import { ArrowBack, ArrowCircleDownSharp, ArrowCircleRightSharp, ArrowCircleUp, CancelRounded, Sms } from "@mui/icons-material";
import { TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { CurrentPContext, MasterContext, UserContext } from "../ContextApi/context";
import TopBigSlides from "../DTechComp/TopBigSlides";
import { useHistory } from "react-router-dom";
import InputDubleSet from "../DTechComp/InputDubleSet";
import utilsUsers from "../_Utils/utilsUsers";
import utilsLogInP from "../_Utils/utilsLogInP";
import PopUpPhoneConnect from "../DTechComp/PopUpPhoneConnect";
import utilsCodes from "../_Utils/utilsCodes";
import PopUpRegular from "../DTechComp/PopUpRegular";
import PopUpMsg from "../DTechComp/PopUpMsg";
import PopUpClearComp from "../DTechComp/PopUpClearComp";
import UserDetails from "./UserDetails";
import PopUpPhoneCheckCode from "../DTechComp/PopUpPhoneCheckCode";
import ConnUrls from "../Connections/ConnectURL";
import { Helmet } from "react-helmet";
import PopUpsSlides from "../DTechComp/PopUpsSlides";
import PopUpHappy from "../DTechComp/PopUpHappy";


const LoginPage = (props) => {



    const [master, setMaster] = useContext(MasterContext);
    const [user, setUser, UserEffectFunction] = useContext(UserContext);

    const [topSlides, setTopSlides] = useState(<PopUpsSlides key={"logInPop"} TopImages={master.loginTopImages} />);
    const [thePopUp, setThePopUp] = useState("");
    const [withNumber, setWithNumber] = useState(false);



    const history = useHistory();

    let popTiming = -1;

    useEffect(async () => {


        props.scrollToTop();
        props.shoTopStrp(false);

        checkForPopUp();

    }, [])

    useEffect(async () => {
        if (props.withNumber || withNumber) {
            connectBySms();
        }

    }, [props.withNumber, withNumber])



    //בודק שהקישור הוא לא בקשת התחברות
    //אחרת ישירות מקפיץ פופאפ של התחברות ב
    //SMS
    const checkForPopUp = () => {
        let urStr = document.location.toString();

        if (urStr.includes("popLog")) {
            connectBySms();
        }
    }
    const goToBack = async () => {
        setThePopUp();
        try {
            props.closeMeFuc();
        }
        catch { }


        await UserEffectFunction();


    }


    const regisetMe = () => {
        setThePopUp(
            <PopUpClearComp
                popFullComp={"specialPopComp onTopOfAll"}
                cssStyl={"noPadding InbgLGray"}
                btnFStyle={"fatInBtn"}
                closePop={() => setThePopUp()}
                insideCss={"noPadding bgLGray"}
                theInsideComp={
                    <UserDetails theInsideComp={"bgLGray"}
                        backFunc={true}
                        closeMeFuc={() => { setThePopUp(); props.closeMeFuc() }}
                        shoTopStrp={(tf) => { }}
                        scrollToTop={(stUp) => { }}
                        connectWithNumber={() => { setThePopUp(); setWithNumber(true) }}
                    />

                } />)
    }

    const connectByEmail = () => {
        popTiming++;
        setThePopUp(
            <InputDubleSet key={popTiming} Title={"התחברות"}
                ContentText={""} inLabel={"כתובת אימייל"} inLabel2={"סיסמא"}
                okClick={(connDetails) => connectUserByMail(connDetails)}
                cancelClick={() => setThePopUp()}
                inputTxt={""}
                inputPrc={""}
                errorMsg={"יש להזין מייל וסיסמא תקינים על מנת להתחבר"}
            />)
    }

    const connectUserByMail = async (connDetails) => {
        popTiming++;
        let details = {
            email: connDetails.inputTxt,
            password: connDetails.inputPrc,
        };

        let connectMe = await utilsLogInP.connectUsr(details);


        if (connectMe.errMsg != "" && connectMe.errMsg != undefined) {
            setThePopUp(
                <InputDubleSet key={popTiming} Title={"התחברות"}
                    ContentText={""} inLabel={"כתובת אימייל"} inLabel2={"סיסמא"}
                    okClick={(connDet) => connectUserByMail(connDet)}
                    cancelClick={() => setThePopUp()}
                    showError={true}
                    inputTxt={details.email}
                    inputPrc={details.password}
                    errorMsg={connectMe.errMsg}
                />)
        }
        else {
            setUser(connectMe);
            connectedMsg(connectMe);
        }
    }


    const connectBySms = () => {
        popTiming++;

        setThePopUp(
            <PopUpPhoneConnect
                key={popTiming} Title={["התחברות באמצעות קוד"]}
                cssStyl={`specialPopComp onTopOfAll noPadding`}
                // topSlides={<PopUpsSlides TopImages={[{ img: master.connsectBySMSImg, txt: "" }]} />}
                ContentText={["יש להזין מספר טלפון נייד לקבלת קוד בסמס"]}
                inLabel={""}
                okClick={(newPhoneNum) => checkConCode(newPhoneNum)}
                cancelClick={() => setThePopUp()}
                recreateCode={() => connectBySms()}
                errorMsg={"יש להזין מספר טלפון תקין"}
            />)

    }

    const checkConCode = async (phoneNum) => {
        popTiming++;

        let codeReply = await utilsCodes.createCodeSms(phoneNum);
        if (codeReply.errormsg) {

            setThePopUp(
                <PopUpPhoneConnect
                    key={popTiming}
                    Title={["התחברות באמצעות קוד"]}
                    cssStyl={`specialPopComp onTopOfAll noPadding`}
                    // topSlides={<PopUpsSlides TopImages={[{ img: master.connsectBySMSImg, txt: "" }]} />}
                    ContentText={["יש להזין מספר טלפון נייד לקבלת קוד בסמס"]}
                    inLabel={""}
                    okClick={(newPhoneNum) => checkConCode(newPhoneNum)}
                    register={() => { regisetMe(); setThePopUp() }}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => connectBySms()}
                    showError={true}
                    errorMsg={codeReply.errormsg}
                />
            )
        }
        else {
            setThePopUp(
                <PopUpPhoneCheckCode key={popTiming} Title={["קוד התחברות באמצעות sms"]}
                    cssStyl={`onTopOfAll specialContactPop`}
                    // topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    topSlides={null}
                    placeholdTxtF="קוד לבדיקה"
                    ContentText={["נשלח אלייך כעת קוד אימות", "נא להזין את הקוד"]} inLabel={""}
                    okClick={(codeCheck) => checkForSMSConCode(codeCheck, codeReply.nId, phoneNum)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => connectBySms()}
                    errorMsg={""}
                    showError={false}
                />
            )


        }

    }


    const checkForSMSConCode = async (codeCheck, nId, phoneNum) => {
        let connectMe = await utilsCodes.checkConnCode(nId, { codeCheck, phoneNum });

        if (connectMe.errMsg != "" && connectMe.errMsg != undefined) {
            popTiming++;
            setThePopUp(
                <PopUpPhoneCheckCode key={popTiming} Title={["קוד התחברות באמצעות sms"]}
                    cssStyl={`onTopOfAll specialContactPop`}
                    // topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    topSlides={null}
                    placeholdTxtF="קוד לבדיקה"
                    ContentText={["נשלח אלייך כעת קוד אימות", "נא להזין את הקוד"]} inLabel={""}
                    okClick={(codeCheck) => checkForSMSConCode(codeCheck, nId, phoneNum)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => connectBySms()}
                    showError={true}
                    errorMsg={connectMe.errMsg}
                />

            )
        }
        else {

            setUser(connectMe);
            connectedMsg(connectMe);
        }
    }

    const connectedMsg = (usrdet) => {
        popTiming++;

        setThePopUp(
            <PopUpClearComp
                popFullComp={"specialPopComp onTopOfAll"}
                cssStyl={"noPadding"}
                btnFStyle={"fatInBtn"}
                // closePop={() => setThePopUp()}
                insideCss={"noPadding"}
                theInsideComp={
                    <PopUpHappy key={popTiming}
                        Title={[` היי ${usrdet.firstName}`, " שמחים  שאתה כאן!"]}
                        cssStyl={`onTopOfAll`}
                        topSlides={<PopUpsSlides key={"logInPop"} TopImages={[{ img: master.suceccufulyconnectedImg, txt: "" }]} />}
                        ContentText={[]} inLabel={""}
                        okClick={() => goToBack()}
                    />

                } />
        )
        setTimeout(() => { goToBack() }, 5000)
    }



    return (
        <div className={"loginPage"}>
            <div className={"headAndBtnWrap spaceMargTopBig spaceButtomBig"}>
                <div className={`backSdLogin`}>
                    {/* <CancelRounded className={"inBlack popUpCloseBtn_Small pointing "} onClick={() => goToBack()} /> */}
                    <ArrowBack className={`pointing`} onClick={() => goToBack()} />
                </div>
                <div className={"bold veryBig onRight spacePadSmall spaceRightLongi"}>
                    התחברות
                </div>
            </div>
            {topSlides}

            <div className={"firstMastSec"}>
                <div className={"insideFrame"}>
                    <div className={"bold anakMeod arrowing"}>ברוכים הבאים</div>
                    <div className={"big arrowing"}>לאפליקציה המקומית אשדוד בתפריט.</div>
                    <div className={"big arrowing"}>אנחנו דואגים שכל מנה תהיה הכי</div>
                    <div className={"big spaceMargBottomBig arrowing"}>משתלמת בסביבה.</div>

                    <div className={"connectBtn conClear bold big spaceMargBottomBig pointing"} onClick={() => connectBySms()}>
                        <span>משתמש רשום</span>
                    </div>

                    <div className={"connectBtn conClear2 bold big pointing"} onClick={() => regisetMe()}>משתמש חדש</div>
                </div>
            </div>
            {thePopUp}

            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/LoginPage`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/LoginPage`} />
            </Helmet>
        </div>
    )

}

export default LoginPage