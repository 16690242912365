import { useEffect, useState, useContext, useRef } from "react";
import { CurrentPContext, UserContext } from "../../../ContextApi/context";
import ConnUrls from "../../../Connections/ConnectURL";
import { Link } from "@mui/material";


const AppsLinksePage = (props) => {

    const [loading, setLoading] = useState(false);


    useEffect(() => {

    }, [])


    return (
        <>
            {!loading && (
                <div className="appslInkPop">
                    <div className={"landingFullBG"}
                        style={{ backgroundImage: `url(${ConnUrls.servMediasUrl}${props.theSingle.mainImage})` }}>

                    </div>

                    <div className="landingContent">
                        <div className="landingIN">
                            <div className={"mainHd"}>רק אצלנו</div>
                            <div className={"smallTxt"}>מחירי מסעדה באפליקציה</div>
                            <div className={"mainHd"}>רק כאן</div>
                            <div className={"smallTxt"}>תמיד תרגיש שהרווחת</div>

                            <a href={"https://apps.apple.com/il/app/%D7%90%D7%A9%D7%93%D7%95%D7%93-%D7%91%D7%AA%D7%A4%D7%A8%D7%99%D7%98/id6471904204"}>
                                <div className="lnkBtn">
                                    <img className={'smallImgBtn onLeft'} src={"/PermanentImages/appleiconWhite.png"} alt={"AppleIcon"} />
                                    <p className={''}>להמשיך עם אפל</p>
                                </div>
                            </a>

                            <a href={"https://play.google.com/store/apps/details?id=com.asdodmenu.app"}>

                                <div className="lnkBtn">
                                    <img className={'smallImgBtn onLeft'} src={"/PermanentImages/googlePlayIc.png"} alt={"AppleIcon"} />
                                    <p className={''}>להמשיך עם אנדרואיד</p>
                                </div>
                            </a>

                            <a href={"https://www.ashdodmenu.co.il/"}>

                                <div className="lnkBtn">
                                    <img className={'smallImgBtn onLeft'} src={"/PermanentImages/mainIco.png"} alt={"AppleIcon"} />
                                    <p className={''}>להמשיך עם האתר</p>
                                </div>
                            </a>
                            <a href={"https://www.ashdodmenu.co.il/"}>
                                <div className={"ashdodMnuLogoWrap"}>
                                    <img className={"ashdodMnuLogo"} src={`${ConnUrls.servMediasUrl}Uploads/MASTER/mainLogo55.png`} alt={"ashdodMnuLogo"} />
                                </div>
                            </a>

                        </div>
                    </div>

                </div >
            )}
            <style jsx>{`
           
                .landingIN {    
                    position: relative;
                    top: 25vh;
                    z-index: 1;
                    width: 100%;
                    margin: 0 auto;
                }
                .mainHd
                {
                    color: black;
                    text-align: center;
                    font-weight: bold;
                    font-size: 2.3rem;
                    line-height: 1;

                }
                .smallTxt
                {
                    color: black;
                    text-align: center;
                    font-weight: regular;
                    font-size: 1.7rem;
                    line-height: 2rem;
                    margin-bottom: 20px;

                }
                .lnkBtn
                {
                    text-align:center;
                    font-weight:regular;
                    font-size: 1.0rem;
                    padding:10px 5px;
                    margin: 10px auto;
                    background-color:black;
                    position:relative;
                    border-radius:10px;
                    width:70%;
                    

                }
                .smallImgBtn
                {
                    width:20px;                    
                    left:25px;
                    position:absolute;
                }
                .lnkBtn p
                {
                    color:white !important;
                }
                .ashdodMnuLogoWrap
                {
                    margin: 35px auto;
                    text-align: center;
                    width: 55%;
                }
                .ashdodMnuLogo
                {
                    width:100%;
                    height:100%;
                }
            `}

            </style>
        </>
    )
}

export default AppsLinksePage;
