import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelRounded, Close } from '@mui/icons-material';

const PopUpPhoneCheckCode = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState(props.showError ? true : false);
    const [erMsg, setErMsg] = useState(props.errorMsg);

    const saveMe = () => {
        if (inputTxt !== "") {
            props.okClick(inputTxt);
        }
        else {
            setErr(true);
        }
    };


    const handleClose = () => {
        props.cancelClick();
    };

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            saveMe();
        }
    };


    return (
        <Dialog open={open} className={`textSizeDiag popDiagBlock ${props.cssStyl || ""}`}>
            <div className="headAndBtnWrap spaceButtomBig">
                <div className="backS">
                    <Close className="pointing" onClick={handleClose} />
                </div>
                <div className="bold veryBig spacePadBig">
                    {props.topTitle ? props.topTitle : "התחברות"}
                </div>
                <div className="lineDotted"></div>
            </div>
            {(props.topSlides != null) ?
                props.topSlides : ""
            }
            {props.topImg && <div className="imgblok" style={{ backgroundImage: `url(${props.topImg})` }}></div>}
            <div className="firstMastSec">
                <div className="insideFrame">

                    <DialogTitle className="regularFont noPadding normalLine">
                        {props.Title.map((txtItm, index) => (
                            <span className="huge arrowing" key={index}>{txtItm}</span>
                        ))}
                    </DialogTitle>

                    <DialogContent className="noPadding normalLine spaceMargTopBig">
                        <DialogContentText className="spaceButtomBig normalLine">
                            {props.ContentText.map((txtItm, index) => (
                                <span className="big arrowing" key={index}>{txtItm}<br /></span>
                            ))}
                        </DialogContentText>
                        <input
                            id="inputPhonetx"
                            className="spaceTopBig inputPhonetx"
                            placeholder={`${props.placeholdTxtF || "מספר נייד"} `}
                            value={inputTxt}
                            onChange={(e) => {
                                setInputTxt(e.target.value); setErr(false)
                            }}
                            onKeyUp={handleKeyUp}
                            label={props.inLabel}
                            variant="filled"
                            type="number"
                            inputMode="numeric"
                            autoFocus
                        />



                    </DialogContent>
                    <DialogActions>
                        <div className="fullRow">
                            <div className="fullRow">
                                <div className="connectBtn conClear bold big pointing" onClick={saveMe}>אימות</div>
                            </div>
                            {/* <br />
                            {erMsg && erMsg.includes("לא נמצא משתמש עם מספר הטלפון המבוקש") && (
                                <div className="fullRow">
                                    <div className="connectBtn conClear2 bold big pointing" onClick={props.register}>הרשם</div>
                                </div>
                            )} */}
                        </div>
                    </DialogActions>

                    <DialogActions>
                        <div className="fullRow">
                            {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
                            <p onClick={() => props.recreateCode()} className={"spacePadBig  centerText pointing"}>שלח לי שוב</p>
                        </div>
                    </DialogActions>
                </div>


            </div>
        </Dialog >

    );
}

export default PopUpPhoneCheckCode;