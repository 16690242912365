
import React, { useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';



const Crdit_Problem = (props) => {

    return (
        <PopUpMsg Title={["בעיית חיוב"]}
        nosnippet={true}
            ContentText={[{ txt: "התגלתה בעייה במערכת החיוב", css: "" },
            { txt: "ולכן זמנית לא ניתן לבצע חיוב להזמנה.", css: "" },
            { txt: "בתור תחלופה, ניתן להמשיך עם ההזמנה", css: "" },
            { txt: "ולבצע תשלום במזומן" }]}
            inLabel={""}

            BtnTxt={"אשלם במזומן"}
            okClick={() => {
                props.okClick()
            }}

            btnClick2={() => { props.closePop() }}
            cssStyl={"orderOneClose"}
            BtnTxt2={"סגור"}
            errorMsg={""} />
    )
}


export default Crdit_Problem