import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { format, isBefore, isToday, isSameDay, subDays } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import heLocale from 'date-fns/locale/he';
import 'react-day-picker/dist/style.css';

const DinnersNumbSelect = (props) => {
  const [Selected, setSelected] = useState(props.dinnersSelected);
  const [maxDinnersNumber] = useState(props.maxDinnersNumber);

  const [rowsList, setRowsList] = useState([])


  useEffect(() => {
    setLstH();
  }, [Selected]);

  const setLstH = () => {
    const rows = [];
    const maxItemsPerRow = 4;
    let counter = 1;
    let i = 0;

    while (counter <= maxDinnersNumber) {
      const row = (
        <div className="fullRow overflowHid" key={i / maxItemsPerRow}>
          {Array.from({ length: maxItemsPerRow }, (_, index) => {
            const value = counter++;
            if (value <= maxDinnersNumber) {
              return (
                <div
                  key={value}
                  className={`reservationhourSelItm pointing  
                  ${(Selected === value) ? "selectedHou" : ""}`}
                  onClick={() => handleDateChange(value)}
                >
                  {value}
                </div>
              );
            }
          })}
        </div>
      );

      rows.push(row);
      i += maxItemsPerRow;
    }

    setRowsList(rows);
  };






  const handleDateChange = (val) => {
    setSelected(val);
    props.settingFunc(val);
  };

  return (
    <div className={'myDinnersSelection'}>
      <label style={{ color: "black" }} className={"fullRow overflowHid"}>כמות סועדים?</label>
      {/* <label style={{ color: "black" }} className={"fullRow overflowHid"}>אישור מיידי</label> */}
      {
        rowsList.map((itm, indx) => {
          return (
            itm
          )
        }
        )
      }
      {/* <label style={{ color: "black" }} className={"fullRow overflowHid"}>דורש אישור</label> */}
      <div
        key={"above"}
        className={`reservationhourSelItm pointing
        ${(Selected === "מעל " + maxDinnersNumber) ? "selectedHou" : ""}`}
        onClick={() => handleDateChange(`מעל ${maxDinnersNumber}`)}
      >
        מעל {maxDinnersNumber}
      </div>
    </div>

  );
};

export default DinnersNumbSelect;
