import { Circle, Login, Logout } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSwipeable } from 'react-swipeable';
import ConnUrls from '../Connections/ConnectURL';
import { CurrentPContext, MasterContext, MobiOrNotContext, UserContext } from "../ContextApi/context";
import PopUpClearComp from "../DTechComp/PopUpClearComp";
import TopBigSlides from "../DTechComp/TopBigSlides";
import LoginPage from "../UsersLogs/LoginPage";

const FotterMenu = () => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);

  const [user, setUser, UserEffectFunction] = useContext(UserContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [mnuShow, setMnuShow] = useState(false);
  const [showMyDetails, setShowMyDetails] = useState(false);
  const [thePopUp, setThePopUp] = useState("");

  const [topSlides, setTopSlides] = useState();
  const history = useHistory();

  useEffect(() => {
    UserEffectFunction();
    setTopSlides(<TopBigSlides TopImages={master.deskFotterImages} />)
  }, [])

  const loginUsr = () => {
    // history.push("/LoginPage");

    setThePopUp(
      <PopUpClearComp
        popFullComp={"specialPopComp onTopOfAll"}
        cssStyl={"noPadding InbgLGray"}
        btnFStyle={"fatInBtn"}
        closePop={() => setThePopUp()}
        insideCss={"noPadding bgLGray"}
        theInsideComp={
          <LoginPage
            theInsideComp={"bgLGray"}
            backFunc={true}
            closeMeFuc={() => setThePopUp()}
            shoTopStrp={(tf) => { }}
            scrollToTop={(stUp) => { }} />
        } />)

    setMnuShow(!mnuShow);
  }
  const logOutUsr = () => {
    localStorage.removeItem('usrtoken');
    setUser();
    // UserEffectFunction();
    setMnuShow(!mnuShow);
    // if (history.length > 1) {
    //   // Go back to the previous page in history if available
    //   history.goBack();
    // } else {
    //   // Redirect to the main website page if no go-back URL exists
    //   history.push('/');
    // }
  }



  const swipME = useSwipeable({
    // onSwipedLeft: (eventData) => alert("User Swiped Left!", eventData),
    // onSwipedRight: (eventData) => alert("User Swiped Right!", eventData),
    // swipeDuration: 500,
    // preventScrollOnSwipe: true,
    // trackMouse: true
  });


  return (
    <div className={"fotterMenu"}  {...swipME}>

      <div className={`fotterMnu`}>
        <div className={"mobiMnuInWrap fotterSLDS"}>
          {/* <div className={"topSlidesWrap"}>
            {topSlides}
          </div> */}
          <div className={"fatLIne"}>
            {
              (!mobiOrNot) ?
                <div className={"botmMnuLogoWrap"}>
                  <div className={"botmMnuLogo"} onClick={() => history.push("/")}>

                    <img className={"btmLgoImg"} src={`${ConnUrls.servMediasUrl}${master.mobiMenuBotLogo}`} alt={currentPage.altTags || master.mainTitle || ""} />
                  </div>
                </div>
                : ""
            }
            <div className={"userDet"}>
              <div className={"CloseMobiBtn"}>
                <div className={"usrDispOnMenu"}>
                  <span className={"big bold"}>
                    <span> שלום </span>
                    {(user?._id != null && user?.firstName != "null" && user?.firstName != "") ?
                      <span>{user.firstName}</span> :
                      <span>אורח</span>}
                  </span>
                </div>
                <div className={"inOutOnMenu"}>
                  <div className={"inOutLogDiv"}>
                    {(user?._id != null) ?
                      <div className={"logOnDsk"}>
                        <Logout onClick={() => logOutUsr()} />
                        <span className="inOutBott fullWidth">התנתקות</span>
                      </div>
                      :
                      <div className={"logOnDsk"}>
                        <Login onClick={() => loginUsr()} />
                        <span className="inOutBott fullWidth">התחברות</span>
                      </div>
                    }
                  </div>
                </div>
              </div>

              {
                (user?._id != null) ?
                  <div className={"MobiMenuME"}>
                    <div className="mobiMnuLnkItm1"
                      onClick={() => { history.push("/MyOrders"); setMnuShow(false) }}>
                      <Circle sx={{ color: 'var(--mainYellow)' }} className={"onRight circleOnMNU"} />
                      <div className={"onRight mobiMnuTxt spaceRight pointing"}>
                        ההזמנות שלך
                      </div>
                    </div>
                    <div className={"MobiMenuME"}>
                      <div className="mobiMnuLnkItm1"
                        onClick={() => { history.push("/MyDetails"); setMnuShow(false) }}>

                        <Circle sx={{ color: 'var(--mainYellow)' }} className={"onRight circleOnMNU"} />
                        <div className={"onRight mobiMnuTxt spaceRight pointing"}>
                          הפרטים שלך
                        </div>
                      </div>
                    </div>
                  </div>
                  : ""
              }
            </div>

            <div className={"MobiMenuMainMnu spacePadBig"}>
              {
                master.mainMenu.map((item, index) => {

                  return (
                    <div key={index} className="mobiMnuLnkItm2 pointing"
                      onClick={() => history.push(`/${item.lnk}`)}>
                      <div colSpan={"2"}>
                        {item.txt}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          {
            mobiOrNot ?
              <div className={"botmMnuLogoWrap"}>
                <div className={"botmMnuLogo"} onClick={() => history.push("/")}>
                  <img className={"btmLgoImg"} src={`${ConnUrls.servMediasUrl}${master.mobiMenuBotLogo}`} alt={currentPage.altTags || master.mainTitle || ""} />
                </div>
                <div className={"botmMnuLogoTxt"}>כל הזכויות שמורות ל-"אשדוד בתפריט"</div>
              </div>
              : <div className={"botmMnuLogoTxt"}>כל הזכויות שמורות ל-"אשדוד בתפריט"</div>
          }
        </div >
      </div>
      {thePopUp}
    </div >
  )

}

export default FotterMenu;
