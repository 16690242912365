import tblOrder from "../_ApiConn/apiTableOrder";



const orderMyTable = async (obj) => {

    let token = localStorage.getItem("usrtoken");

    let dFromF = await tblOrder.orderMyTable({ obj, token });
    return (dFromF);
}



const getTableOrderBySearch = async (txtToSearch, userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getTableOrderBySearch({ txtToSearch, token, userBuis });
    return (dFromF);
}



const getTableOrder = async (orderID, userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getTableOrder({ orderID, token, userBuis });

    return (dFromF);
}

const getTableSpecials = async (buisId) => {

    let token = localStorage.getItem("token");

    let dFromF = await tblOrder.getTableSpecials({ buisId, token });
    return (dFromF);
}


export default {
    orderMyTable,
    getTableOrderBySearch,
    getTableOrder,
    getTableSpecials
};