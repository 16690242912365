
import React, { useContext, useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import { MasterContext } from '../../ContextApi/context';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';


const No_Deliverys = (props) => {

    const [master] = useContext(MasterContext);

    return (

            <BigPopUpMsg
            class={"oops"}
            imgSrc={`${ConnUrls.servMediasUrl}${master.oopsPopImg}`}
            Title={["ללא משלוחים!"]}
            ContentText={["המסעדה לא מקבלת",
                "הזמנות משלוחים כרגע"]}
            inLabel={""}
            okClick={() => props.okClick()}
            BtnTxt={"חזור"}
            BtnTxt2={"עבור לעמוד המסעדה"}
            btnClick2={() => props.btn2Click()}
            errorMsg={""} />
    )
}


export default No_Deliverys;