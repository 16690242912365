
import React, { useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';


const Minimum_Order = (props) => {
    return (
        <PopUpMsg Title={["מינימום הזמנה"]}
        nosnippet={true}
            ContentText={[{ txt: "על מנת להזמין משלוח", css: "" },
            { txt: "יש לבצע מינימום הזמנה במחיר של", css: "" },
            { txt: `${props.mindelP} ₪`, css: "" }]}
            inLabel={""}

            okClick={() => props.okClick()}

            btnClick2={() => { props.btnClick2() }}
            cssStyl={"orderOneClose"}
            BtnTxt={"חזור לתפריט"}
            BtnTxt2={"סגור"}
            errorMsg={""} />
    )
}


export default Minimum_Order;