import { useEffect, useState } from "react";
import MyDateTime from "../../../DTechComp/MyDateTime";
import { AccessTime, FiberManualRecord } from '@mui/icons-material';

const DayOpeningHours = (props) => {

    const [currntHous, setCurrentHous] = useState({});

    let dtexist = false;
    let HourToDisp = "";

    useEffect(() => {
        setCurrentHous(MyDateTime.checkIfBetweenHours(props.item))

    }, [])

 
    let dispme = (props.typeDisp == "DayName") ?
        (
            <div data-nosnippet className="no-render openHToDayName">

                <div className={`specDot ${currntHous.txtBtn?.includes("סגור") ? "specDotClose" : ""}`}><FiberManualRecord /></div> <span style={{ float: "right" }}>
                    יום {MyDateTime.getDayOfWeekHeb(new Date())}:
                    </span>
                <span>
                    {(currntHous.txtBtn?.includes("סגור")) ? currntHous.timtxt : currntHous.txtBtn}
                </span>

            </div>
        ) :
        (
            <div data-nosnippet className="no-render openHToDay">
                <div className={`specDot ${currntHous.txtBtn?.includes("סגור") ? "specDotClose" : ""}`}><FiberManualRecord />
                </div><span>&nbsp;&nbsp;&nbsp;{(!currntHous.txtBtn?.includes("סגור")) ? currntHous.timtxt : currntHous.txtBtn}</span>
            </div>
        );

    return (
        <>
            {dispme}
        </>
    )
}

export default DayOpeningHours;
