import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getRestsUrls = async () => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/restsUrls");

    return (dFromF.data);

}
const getRestById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/web/" + id);
    return (dFromF.data);
}

const getMainRestsByIds = async (idsList) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/web/byids", idsList);
    return (dFromF.data);
}

const getMainRests = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/web/mainrests", obj);
    return (dFromF.data);
}

const getTableReservsList = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/tableReservs", obj);
    return (dFromF.data);
}

const getRestNowOpen = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/nop", obj);
    return (dFromF.data);
}

const getSerchResault = async (txtSrch) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/txtSrch/" + txtSrch);
    return (dFromF.data);
}


const getallBuisSmallLst = async (txtSrch) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/getallBuisSmallLst/" + txtSrch);
    return (dFromF.data);
}


export default {
    getallBuisSmallLst,
    getMainRests,
    getTableReservsList,
    getMainRestsByIds,
    getRestNowOpen,
    getRestById,
    getSerchResault,
    getRestsUrls

};