import { useContext, useEffect, useState } from "react";
import { CurrentPContext, MobiOrNotContext } from "../../ContextApi/context";
import DayOpeningHours from "./RestDetails/DayOpeningHours";
import parse from 'html-react-parser';
import { ArrowCircleRightSharp, ArrowCircleUp } from '@mui/icons-material';
import MyDateTime from "../../DTechComp/MyDateTime";
import AddressLine from "./RestDetails/addressLine";
import WeekOpeningHours from "./RestDetails/WeekOpeningHours";
import GoogleMapItem from "../../DTechComp/GoogleMapItem";
import { useHistory } from "react-router-dom";
import WazeItem from "../../DTechComp/WazeItem";
import { Helmet } from "react-helmet";

const MoreDetails = (props) => {

    const history = useHistory();
    const [currP, setCurrP] = useState(props.currP);
    const [mobiOrNot, setMobiOrNot] = useState(MobiOrNotContext);
    const [pageDisp, setPageDisp] = useState();
    const [showwMe, setShowwMe] = useState(props.showMe);
    const [showHide, setShowHide] = useState("hideOnLeft");


    useEffect(() => {
        setCurrP(props.currP)
    }, [props.currP])

    useEffect(() => {
        props.showMe ? setShowHide("showOnLeft") : setShowHide("hideOnLeft")
        setShowwMe(props.showMe)

    }, [props.showMe])


    const goToBack = () => {
        props.setShowMe();

    }

    return (

        <div className={`moreDetailsBlock ${showHide}`} style={{ visibility: showwMe ? "visible" : "hidden" }}>
            <div className={`closeSideBtn ${showHide}`}>
                <ArrowCircleRightSharp onClick={() => goToBack()} />
            </div>
            <div className={"headOnFixC SecondFont bold big"}>
                {currP.buissName}
                <div className={`backSd`}>
                    <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>
            </div>
            <div className={"RestGoogleMap"}>
                <GoogleMapItem mapLink={currP.GoogleLink} />
            </div>
            <div className={"restMoreDetails"}>

                <div className={"margeDownDetails"}>
                    <div style={{ float: "right", width: "80%" }}>
                        <div className={"bold veryBig"}>{currP.buissName}</div>
                        {currP.OpeningHours ?
                            <DayOpeningHours item={currP} typeDisp="DayName" /> : ""}
                    </div>
                    {currP.wazeAddress ?
                        <WazeItem TheUrl={currP.wazeAddress} theIframe={currP.wazeLL} /> : ""}
                </div>

                <div className={`restShortText wrapPadding`}>
                    {parse(`${currP.buissAbout}`)}
                </div>


                <div className={"margeDownDetails"}>
                    <div className={"bold veryBig "}>כתובת</div>
                    {currP.buissAddress ? <AddressLine address={currP.buissAddress} typeDisp="NoIcon" /> : ""}
                </div>

                <div className={"bold veryBig"}>שעות פתיחה</div>
                <div className={"restShortText"}>
                    {currP.OpeningHours ?
                        <WeekOpeningHours OpeningHours={currP.OpeningHours} noWork="סגור" /> : ""}
                </div>


                <div className={"bold veryBig"}>שעות משלוחים</div>
                {currP.Delivery?.Doing ? <>
                    <div className={"restShortText"}>
                        {currP.DeliveryHours ?
                            <WeekOpeningHours OpeningHours={currP.DeliveryHours} noWork="אין משלוחים" /> : ""}
                    </div> </> : <div className={"restShortText"}>הזמנה טלפונית</div>}

                <div className={"bold veryBig spaceme15"}>פרטי התקשקשות</div>
                <div className={"contacLine"}>
                    <a href={"#"} onClick={() => window.open('tel:' + currP.buissPhone, '_system')} >
                        <span className={"sPright"}> טלפון: </span>
                        <span className={"sPleft"}>{currP.buissPhone}</span></a>
                </div>

                {currP.buisWebLInk ?
                    <div className={"contacLine"}>
                        <a href={`${currP.buisWebLInk["lnk"]}`}>
                            <span className={"sPright"}>{currP.buisWebLInk.txt ? currP.buisWebLInk.txt : "אתר"}:
                            </span><span className={"sPleft"}> {currP.buisWebLInk.lnk}</span>
                        </a>
                    </div> : ""}

                {(currP.buissFacbook != null && currP.buissFacbook != "") ?
                    <div className={"contacLine"}>
                        <a href={`${currP.buissFacbook.lnk}`}>
                            <span className={"sPright"}>
                                {currP.buissFacbook.txt ? currP.buissFacbook.txt : "פייסבוק"}:</span><span className={"sPleft"}>
                                {currP.buissFacbook.lnk}</span>
                        </a>
                    </div> : ""}

                {currP.buissInstegram ? <div className={"contacLine"}><a href={`${currP.buissInstegram.lnk}`}>
                    <span className={"sPright"}>{currP.buissInstegram.txt ? currP.buissInstegram.txt : "אינסטגרם"}:</span><span className={"sPleft"}>{currP.buissInstegram.lnk}</span></a></div> : ""}

                {currP.buissYoutube ? <div className={"contacLine"}><a href={`${currP.buissYoutube.lnk}`}>
                    <span className={"sPright"}>{currP.buissYoutube.txt ? currP.buissYoutube.txt : "יוטיוב"}:</span><span className={"sPleft"}>{currP.buissYoutube.lnk}</span></a></div> : ""}

            </div>

        </div>
    );
}

export default MoreDetails;
