

import { useContext, useEffect, useReducer, useState } from 'react';
import { CurrentPContext, OrderContext, OrdStageContext } from '../ContextApi/context';
import ShopingCratFloat from './ShopingCratFloat';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import OrderTypeSelect from '../OrderSettings/OrderTypeSelect';
import OrderFunctions from '../OrderSettings/OrderFunctions';
import PopUpShopCart from './PopUpShopCart';
import { StackedLineChartRounded } from '@mui/icons-material';
import TheOrderList from '../OrderSettings/TheOrderList';
import Scroll from 'react-scroll';
import PopUpDInnersCheck from './PopUpDInnersCheck';
import ShopingCart from '../OrderSettings/ShopingCart';
import PopUpTheComp from './PopUpTheComp';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const initialState = {
    stage: 0,
    showOrders: "hideOrds",
    theText: "המשך הזמנה",
    totPrice: 0 + " ₪"
}

const ContinueToPayLine = (props) => {
    let { path, url } = useRouteMatch();

    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [order, setOrder] = useContext(OrderContext);
    const [txtBtn, setTxtBtn] = useState(props.theTxt);
    const [showMe, setShowMe] = useState('hideOrds');
    const [nxUrl, setNxUrl] = useState((props.nxUrl != null) ? props.nxUrl : '/TakeOrDel');
    const history = useHistory();
    const [thePopUp, setThePopUp] = useState("");
    const [siteCart, setSiteCart] = useState({ showme: false });

    const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);


    useEffect(() => {
        setShowMe((order.Orders.length > 0) ? 'showOrds' : 'hideOrds')
        // setNxUrl((nxUrl == '/TakeOrDel') ? '/MyOrder' : '/#');

    }, [order])


    const nextPage = () => {

        if (window.location.pathname.startsWith('/restaurants/')) {
            //בודק אם יש רטבים מוצמדים
            let theCOunt = 0;
            order.Orders.forEach((itm, indx) => {
                if (itm.chkProdSauces) {
                    theCOunt += (parseInt(itm.chkProdValSauces) * itm.mount);
                }
                itm.specials?.forEach(specItem => {
                    specItem.spec?.forEach(spItem => {
                        theCOunt += (parseInt(spItem.withSauces) * parseInt(spItem.itmsMount));
                    });
                });
            })
            if (currentPage.chkForkNife != true &&
                currentPage.chkChopStiks != true &&
                currentPage.chkSauces != true) {

                // props.stickyShow(false)

                setThePopUp(
                    <PopUpTheComp
                        // closePop={() => { props.stickyShow(true); setThePopUp() }}
                        closePop={() => { setThePopUp() }}
                        cssStyl={"OrderTypeSelectPop"}
                        theInsideComp={
                            <OrderTypeSelect
                                shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                                scrollToTop={(stUp) => { }}
                                showfotter={(fot) => { }}
                                setShowbanners={(bol) => { }} />}
                        Title={[""]}
                    />
                )
                // history.push(nxUrl);

            }
            else {
                popTiming++;
                setThePopUp(
                    <PopUpDInnersCheck key={popTiming} Title={["סועדים"]}
                        ContentText={["."
                            , ""
                            , `בערך בעוד ${order.OrderTimes} דקות`
                            , "בתאבון!"]}
                        inLabel={""}
                        okClick={() => {
                            setThePopUp(
                                <PopUpTheComp
                                    // closePop={() => { props.stickyShow(true); setThePopUp() }}
                                    closePop={() => { setThePopUp() }}
                                    cssStyl={"OrderTypeSelectPop"}
                                    theInsideComp={
                                        <OrderTypeSelect
                                            shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                                            scrollToTop={(stUp) => { }}
                                            showfotter={(fot) => { }}
                                            setShowbanners={(bol) => { }} />}
                                    Title={[""]}
                                />
                            )
                        }}
                        cancelClick={() => { setThePopUp(); }}
                        btnClick2={() => { setThePopUp(); }}
                        BtnTxt2={"חזור לתפריט"}
                        errorMsg={""} />
                )
            }
        }


        // history.push(nxUrl);

    }


    const scrolUp = (scInt) => {
        scroll.scrollToTop(scInt);
    }

    let popTiming = -1;

    const showShopCrt = () => {

        setThePopUp(<ShopingCart isPopUp={true}
            shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
            scrollToTop={(stUp) => { scrolUp(stUp) }} />)
    }


    return (
        <>
            <div className={`ContinueToPayWrap  ${showMe}`}>
                <div className="ContinueToPayLine" >
                    <div className={"contPayRight"} onClick={() => showShopCrt()}>
                        <ShopingCratFloat />
                    </div>
                    <div className={"contPayLeft"} onClick={() => nextPage()}>
                        <div className={"shopCrt"}>
                            {(siteCart.theOrdLst) ? siteCart.theOrdLst : ""}
                        </div>
                        <div className={"contToPayText bold big"}>{txtBtn}</div>
                        {(props.withPrice) ? <div className={"priceTxt bold big"}>{OrderFunctions.getOrderPrice(order) + " ₪"}</div> : ""}
                    </div>
                </div>

            </div>

            {thePopUp}
        </>

    )

}
export default ContinueToPayLine;


