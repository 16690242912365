import { useState, useContext } from "react";
import ConnUrls from "../Connections/ConnectURL";
import { MasterContext } from "../ContextApi/context";
import LogoComp from "./Logo";
import { useHistory } from "react-router-dom";
import MobiMenu from "./MobiMenu";
import FotterMenu from "./FotterMenu";

const Fotter = (props) => {

  const [master, setMaster] = useContext(MasterContext);

  const [theFooter, setTheFooter] = useState(master.footer);
  const [RTFooter, setRTFooter] = useState(master.footer.RTFooter);
  const [LTFooter, setLTFooter] = useState(master.footer.LTFooter);
  const [RBFooter, setRBFooter] = useState(master.footer.RBFooter);
  const [LBFooter, setLBFooter] = useState(master.footer.LBFooter);
  const history = useHistory();

  const getLnk = (lst) => {

    let bk = lst.Links.map((item, index) => {
      return (
        <div key={index} className="fotterMainHeadLine smallFont">
          <a className="fotterHeadLine vSmall" href={item.lnk}><p>{item.txt}</p></a>
        </div>
      )
    });

    return (
      <>
        <div className="fotterHeadLine">
          {lst.header}
        </div>
        {bk}
      </>
    )


  }

// const loginUsr=()=>
// {
//   history.push("/LoginPage")
// }

  return (
    <div className={"Fotter"}>
      <FotterMenu />
        </div>
  )

}

export default Fotter;
