
import { ArrowCircleRightSharp } from '@mui/icons-material';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router-dom";
import Scroll from 'react-scroll';
import ConnUrls from '../../Connections/ConnectURL';
import { CurrentPContext, MasterContext, MobiOrNotContext, OrderContext } from '../../ContextApi/context';
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import utilsLandingPage from '../../_Utils/utilsLandingPage';
import myFunctions from '../../myFunctions';
import { Helmet } from 'react-helmet';
import AppsLinksePage from './LandinPages/AppsLinksePage';
import PopUpTheComp from '../../DTechComp/PopUpTheComp';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const LandingPage = (props) => {

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const domain = urlObject.origin;

  const [master, setMaster] = useContext(MasterContext);

  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [waitImgItm, setWaitImgItm] = useState({});
  const [thePopUp, setThePopUp] = useState("");

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  useEffect(() => {

    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(false)
    getLandingPage();
    setLoading(false);

  }, [])


  const getLandingPage = async () => {
    let pName = props.pageName || window.document.href.substring(window.document.href.lastIndexOf('/') + 1)
    let theSingle = await utilsLandingPage.getPageByName(props.pageName);
    setCurrentPage({ ...theSingle, pageTYp: "" });
    setThePopUp(
      <PopUpTheComp
        // closePop={() => { props.stickyShow(true); setThePopUp() }}
        closePop={() => { setThePopUp() }}
        cssStyl={"landingPagepopUp onTopOfAll"}
        BgSet={`url(${ConnUrls.servMediasUrl}${theSingle.mainImage})`}
        theInsideComp={
          <AppsLinksePage theSingle={theSingle}
            closeMe={() => setThePopUp()} />}
        Title={[""]}
      />
    )
  }
  const goToBack = () => {
    sessionStorage.setItem("LandingPage", "");
    history.goBack();

  }


  return (
    <div className="LandingPage">
      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>
      </div>

      {loading ? "" :
        <>
          {(mobiOrNot) ?
            <>
              <div className={"headOnFixC SecondFont bold big"}>
                <div className={`backSd`}>
                  <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>


              </div>
              <div className={`LandingPagePad`}>
              </div>
            </>
            :
            ""
          }
          <div>
            {props.typeOfLandPage}
          </div>
        </>

      }

      <Helmet
        meta={[
          {
            property: "og:url", content: `${ConnUrls.siteDomainNam}/${encodeURIComponent(currentPage.inLinkToPage)}`
          }
        ]}>
        <link rel="canonical" href={`${ConnUrls.siteDomainNam}/${encodeURIComponent(currentPage.inLinkToPage)}`} />
      </Helmet>


      {thePopUp}



    </div >
  );
}

export default LandingPage;
