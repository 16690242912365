import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const getMaster = async () => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "master");    
    return (dFromF);
}

const getSiteMessages = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "master/getSiteMessages");    
    return (dFromF);
}

export default {
    getMaster,
    getSiteMessages
};