import categurys from "../_ApiConn/apiCategurys";



const getAllCategurys = async () => {
    let dFromF = await categurys.getAllCategurys();
    return (dFromF.data);
}


const getCateguryById = async (id) => {
    let dFromF = await categurys.getCateguryById(id);
    return (dFromF.data);
}


const getRestsByCatId = async (id) => {
    let dFromF = await categurys.getRestsByCatId(id);

    return (dFromF.data);
}


export default {
    getAllCategurys,
    getCateguryById,
    getRestsByCatId

};