import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { ContactlessOutlined, SwipeSharp } from '@mui/icons-material';
import SwiperCore, { Pagination } from 'swiper';
import ConnUrls from '../Connections/ConnectURL';
import { CurrentPContext, MasterContext } from '../ContextApi/context';

SwiperCore.use([Pagination]);


const TopSmallSlides = (props) => {
  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);

  const [timgs, setTimgs] = useState(props.TopImages);

  useEffect(() => {

  }, []);




  return (
    <div className={"smallTopImages"}>
      <Swiper
        loop={true}
        freeMode={false}
        className={""}
        spaceBetween={0}
        slidesPerView={1}
        pagination={true}
      >
        {
          timgs.map((item, index) => {
            return (
              <SwiperSlide key={index} className={`${props.slidesCss ? props.slidesCss : "smallTopImagesLst"}`}>

                {/* <Link to={`/cat/${item._id}`}> */}
                <div className={"smallTopImgWrap"}>
                  <img className={"smallTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                  <div className={"smallTopimgTxt bold big pointing"} >
                    {parse(`${item.txt}`)}
                  </div>

                </div>
                {/* </Link> */}
              </SwiperSlide>
            )

          })
        }

      </Swiper>

    </div>
  )

}

export default TopSmallSlides;



