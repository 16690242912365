import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'



const getSpecialPageByName = async (pageName) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "specialP/" + pageName);
    return (dFromF.data);
}


export default {
    getSpecialPageByName,
};