import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Helmet } from 'react-helmet';
import { CancelRounded } from '@mui/icons-material';

const PopUpCardComIframe = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);
    const [theIframe, setTheIframe] = useState();
    useEffect(() => {
        // ex(props.theData.session)
    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
         props.cancelClick();
    };





    return (
        <>
            <Dialog open={open} className={`iframeBlock popDiagBlock ${props.cssStyl ? props.cssStyl : ""}`}>
                <DialogContent className={`iframeBlockDialogContent`}>
                    {/* <DialogContentText> */}

                    {/* {console.log(props.theData)} */}
                    {/* {window.gamapayInit(props.theData.session)} */}
                    <div className={`iframeCloseBtn`}>
                        <CancelRounded className={"popUpCloseBtn pointing"} onClick={() => handleClose()} />
                    </div>
                    <iframe src={props.theData} className={"iframeBlockIframe"}/>

                    {/* {props.ContentText.map((txtItm, index) => {
                            return (
                                <span key={index}>{txtItm}<br /></span>
                            )
                        })} */}
                    {/* </DialogContentText> */}
                </DialogContent>
                {/* <DialogActions>
                    <button
                        type="button"
                        className="centerMe btn btn-success btnPopRegular pointing"
                        onClick={() => { props.okClick(); handleClose(); }}
                    >
                        {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}
                    </button>
                </DialogActions>
                <DialogActions>
                    {props.btnClick2 != null ?
                        <>
                            <span>&nbsp;&nbsp;</span>
                            <button
                                type="button"
                                className="btn-success btnPopRegular btnPopRegularSec pointing"
                                onClick={() => { props.btnClick2(); handleClose(); }}
                            >
                                {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}
                            </button>
                        </>
                        : ""}
                </DialogActions> */}
            </Dialog>
        </>
    );

}

export default PopUpCardComIframe;