import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ConnUrls from '../../Connections/ConnectURL';
import { CurrentPContext, MobiOrNotContext } from "../../ContextApi/context";

SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);


const HomePTopSlides = (props) => {
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [timgs, setTimgs] = useState(props.topImages);
  const [theSlides, setTheSlides] = useState();




  useEffect(() => {
    getslds()
  }, []);



  const getslds = () => {


    if (mobiOrNot) {
      return (
        <div className={"HPTopImagesWraper"}>
          <div className={"HPTopImages"}>
            <Swiper
              modules={[Pagination]}
              className={"HPTopSwiper"}
              spaceBetween={0}
              slidesPerView={1}
              pagination={true}
              loop={true}
              autoplay={{ delay: 3000 }}

              // onSlideChange={() => console.log('slide change')}
              freeMode={false}
            // onSwiper={(swiper) => console.log(swiper)}
            >
              {
                timgs.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className={"HPTopImagesLst"}>
                      {item.lnk ?
                        <Link to={item.lnk}>
                          <div className={"HPTImgWrap"}>
                            <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                            <div className={"HPTopimgTxt bold big pointing"} >
                              {parse(`${item.txt}`)}
                            </div>
                          </div>
                        </Link>
                        :
                        <>
                          <div className={"HPTImgWrap"}>
                            <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                            <div className={"HPTopimgTxt bold big pointing"} >
                              {parse(`${item.txt}`)}
                            </div>
                          </div>
                        </>}
                    </SwiperSlide>

                  )

                })
              }

            </Swiper>

          </div >
        </div >
      )
    }
    else {
      return (
        <div className={"HPTopImages"}>
          <Swiper
            modules={[Pagination]}
            className={"HPTopSwiper"}
            spaceBetween={10}
            slidesPerView={2}
            pagination={true}
            loop={true}
            autoplay={{ delay: 3000 }}

            // onSlideChange={() => console.log('slide change')}
            freeMode={false}
          // onSwiper={(swiper) => console.log(swiper)}
          >
            {
              timgs.map((item, index) => {
                return (
                  <SwiperSlide key={index} className={"HPTopImagesLst"}>
                    {item.lnk ?
                      <Link to={item.lnk}>
                        <div className={"HPTImgWrap"}>
                          <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                          <div className={"HPTopimgTxt bold big pointing"} >
                            {parse(`${item.txt}`)}
                          </div>

                        </div>
                      </Link>
                      :
                      <>
                        <div className={"HPTImgWrap"}>
                          <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                          <div className={"HPTopimgTxt bold big pointing"} >
                            {parse(`${item.txt}`)}
                          </div>

                        </div>
                      </>
                    }
                  </SwiperSlide>
                )

              })
            }

          </Swiper>

        </div >
      )
    }

  }

  return (getslds())
}

export default HomePTopSlides;



