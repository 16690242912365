import { ArrowCircleRightSharp } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { MasterContext, UserContext } from "../../ContextApi/context";
import PopUpOneLine from "../../DTechComp/PopUpOneLine";
import PopUpRegular from "../../DTechComp/PopUpRegular";
import utilsCodes from "../../_Utils/utilsCodes";
import utilsUsers from "../../_Utils/utilsUsers";
import PopUpPhoneCheckCode from "../../DTechComp/PopUpPhoneCheckCode";
import TopBigSlides from "../../DTechComp/TopBigSlides";
import PopUpMsg from "../../DTechComp/PopUpMsg";
import { Helmet } from "react-helmet";
import ConnUrls from "../../Connections/ConnectURL";
import PopUpClearComp from "../../DTechComp/PopUpClearComp";
import PopUpHappy from "../../DTechComp/PopUpHappy";
import PopUpsSlides from "../../DTechComp/PopUpsSlides";


const MyDetails = (props) => {



    const [master, setMaster] = useContext(MasterContext);
    const [user, setUser, UserEffectFunction] = useContext(UserContext);

    const [theUser, setTheUser] = useState();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    const [readIt, setReadIt] = useState(false);
    const [thePopUp, setThePopUp] = useState("");


    const history = useHistory();
    // const [topSlides, setTopSlides] = useState();

    const [theErrors, setTheErrors] = useState("");
    const [errorsTxts, setErrorsTxts] = useState("");


    let jumcodeChek = 0;
    let tryLoop = -1;

    useEffect(() => {
        UserEffectFunction();
    }, [])

    useEffect(() => {

        if (user != null && user._id != null) {
            props.scrollToTop();
            props.shoTopStrp(false)

            uploadTheUser();
        }
        else {
            history.push("./")
        }

    }, [user])


    const goToBack = () => {
        history.goBack();

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }
        // else {
        //      history.push('/'); }
    }

    const uploadTheUser = async () => {

        let theUsr = await utilsUsers.getUserById(user._id);

        setFirstName(theUsr.firstName)
        setLastName(theUsr.lastName)
        setEmail(theUsr.email)
        setPhone(theUsr.phone)
        setPassword(theUsr.password)
        setReadIt(theUsr.readIt)
    }

    const removeMeFromSite = () => {
        history.push("/unsubscribe")
    }

    const updateUser = () => {

        let stopMe = false;
        let thEr = "";
        let errs = "";
        if (firstName?.trim().length <= 1) {
            thEr = "firstName";
            errs = (errs === "") ? "יש להזין שם פרטי תקין" : errs;
        }
        if (lastName?.trim().length <= 1) {
            thEr += ",lastName";
            errs = (errs === "") ? "יש להזין שם משפחה תקין" : errs;
        }
        if (!validPhone()) {
            thEr += ",phone";
            errs = (errs === "") ? "יש להזין מספר טלפון תקין" : errs;

        }
        if (!validEmail()) {
            thEr += ",email";
            errs = (errs === "") ? "יש להזין כתובת מייל חוקית" : errs;
        }
        // if (!validPassword()) {
        //     thEr += ",password";
        //     errs = (errs === "") ? "יש להזין סיסמא חזקה יותר.\n על הסיסמא להכיל מינמום 6 תווים, אות גדולה באנגלית, אות קטנה באנגלית ותו מיוחד ( לדוגמא: ! או $) " : errs;
        // }
        if (!readIt) {
            thEr += ",readIt";
            errs = (errs === "") ? "יש לאשר את קריאת התקנון" : errs;
        }

        if (thEr === "") {
            updateUserApply();
        }

        setTheErrors(thEr)
        setErrorsTxts(errs)

    }

    const updateUserApply = async () => {
        tryLoop++;
        let userToUp = { firstName, lastName, phone, email, password, readIt, tryLoop, _id: user._id }
        let codeReply = await utilsCodes.createCodeUpUser(userToUp);

        if (codeReply.errormsg !== null && codeReply.errormsg !== undefined) {
            setErrorsTxts(codeReply.errormsg);
            setThePopUp();
            if (codeReply.loopUp == true) setPhone("");
        }
        else {
            jumcodeChek++;
            let errMsg = (codeReply.errormsg !== undefined) ? codeReply.errormsg : "";

            setThePopUp(
                <PopUpPhoneCheckCode key={jumcodeChek} Title={["אימות פרטים"]}
                    topTitle={"אימות פרטים"}
                    cssStyl={`onTopOfAll`}
                    // topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}

                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]}
                    placeholdTxtF={"קוד לבדיקה"}
                    inLabel={""}
                    okClick={(codeCheck) => checkForCode(codeCheck, codeReply.nId)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => updateUserApply()}
                    showError={errMsg !== ""}
                    errorMsg={errMsg}
                />

            )
        }
    }

    const checkForCode = async (codeCheck, nId) => {
        let upUser = { firstName, lastName, phone, email, password, readIt, tryLoop, _id: user._id }
        let codeReply = await utilsCodes.checkUpdteCode(nId, { codeCheck, upUser });

        if (codeReply.errMsg !== null) {
            jumcodeChek++;
            setThePopUp(
                <PopUpPhoneCheckCode key={jumcodeChek}
                    topTitle={"אימות פרטים"}
                    Title={["אימות פרטים"]}
                    cssStyl={`onTopOfAll`}
                    topSlides={<TopBigSlides TopImages={[{ img: master.codeSMSImg, txt: "" }]} />}
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]}
                    placeholdTxtF={"קוד לבדיקה"}
                    inLabel={""}
                    okClick={(codeCheck) => checkForCode(codeCheck, nId)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => updateUserApply()}
                    showError={codeReply.errMsg !== ""}
                    errorMsg={codeReply.errMsg}
                />
            )
        }
        else {
            jumcodeChek++;
            setThePopUp(
                <PopUpClearComp
                    popFullComp={"specialPopComp onTopOfAll"}
                    cssStyl={"noPadding"}
                    btnFStyle={"fatInBtn"}
                    // closePop={() => setThePopUp()}
                    insideCss={"noPadding"}
                    theInsideComp={
                        <PopUpHappy key={jumcodeChek}
                            Title={["הפרטים עודכנו בהצלחה"]}
                            cssStyl={"okPOP onTopOfAll"}
                            topSlides={<PopUpsSlides key={"logInPop"} TopImages={[{ img: master.successUpdate, txt: "" }]} />}
                            ContentText={[]}
                            inLabel={""}
                            okClick={() => setThePopUp()}
                        />

                    } />
            );

            UserEffectFunction();

            setTimeout(() => { setThePopUp() }, 5000)


        }
    }

    const validPhone = () => {
        if (phone !== "") {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(phone)) {
                return false
            } else if (phone.length != 10 && phone.length != 9) {
                return false
            }
            else {
                return true;
            }
        }
    }
    const validEmail = () => {
        if (email !== "") {
            var pattern = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
            if (!pattern.test(email)) {
                return false
            }
            else {
                return true;
            }
        }
    }

    const validPassword = () => {
        if (password !== "") {
            let pattern = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,}))');
            if (!pattern.test(password)) {
                return false
            }
            else {
                return true;
            }
        }
    }

    return (
        <div className={"loginPage"}>
            <div className={"headOnFixC SecondFont bold big"}>
                הפרטים שלי
                <div className={`backSd`}>
                    <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>
            </div>
            <br /><br /><br /><br />

            {/* {topSlides} */}

            <div className={"firstMastSec max600"}>
                <div className={"insideFrame"}>
                    <br />
                    {
                        (theErrors !== "") ?
                            <div className={"errorLine"}>
                                {(!theErrors.includes("firstName") &&
                                    !theErrors.includes("lastName") &&
                                    errorsTxts !== "") ?
                                    <p className={"vsmall bold bigLineHeight"}>- {errorsTxts}</p> :
                                    <p className={"big bold bigLineHeight"}>{"כל התאים המסומנים הינם חובה"}</p>}

                            </div>
                            : (errorsTxts !== "") ?
                                <div div className={"errorLine"}>
                                    <p className={"vsmall bold"}>- {errorsTxts}</p> </div> : ""
                    }


                    {/* <div className={"bold regular"}>על מנת להמשיך יש צורך להתחבר</div> */}
                    <div className={`usrDet details ${(theErrors.includes("firstName") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={firstName} placeholder={"שם פרטי"} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("lastName") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={lastName} placeholder={"שם משפחה"} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("phone") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={phone} placeholder={"טלפון נייד (לקבלת SMS)"} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("email") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={email} placeholder={"אימייל"} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("password") ? "errorMe" : "")}`} >
                        <input type={"password"} className={"connInput"} value={password} placeholder={"סיסמא"} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
                    </div>
                    <br />
                    <div className={`usrDetChk  details ${(theErrors.includes("readIt") ? "errorMe" : "")}`} >
                        <Checkbox checked={readIt} onChange={() => setReadIt(readIt ? false : true)} className="chkBx" />
                        <div className={"readItTxt"}><span>קראתי ואני מסכים/ה לתנאי </span><Link className={"readItLink"} to={"/TAkanon"}>התקנון</Link></div>
                    </div>
                    <br />

                    <div className={"detailsB"}>
                        <div className={"bold big ConDetailsBtn pointing"} onClick={() => updateUser()}>עדכן פרטים</div>
                        {/* <input className={"bold big ConDetailsBtn"} type="submit" value="הרשם" /> */}
                    </div>

                    <div className={"detailsB"}>
                        <div className={"bold big unsubscribeBtn pointing"} onClick={() => removeMeFromSite()}>מחק אותי מהאתר</div>
                        {/* <input className={"bold big ConDetailsBtn"} type="submit" value="הרשם" /> */}
                    </div>
                </div>

            </div>
            {thePopUp}
            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/MyDetails`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/MyDetails`} />
            </Helmet>
        </div >
    )

}

export default MyDetails