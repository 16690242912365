
import React, { useContext, useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import { MasterContext } from '../../ContextApi/context';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';
import TopBigSlides from '../TopBigSlides';


const Out_Of_Hours = (props) => {

    const [master] = useContext(MasterContext);

    return (
        <PopUpMsg
            Title={["סגור כעת!"]}
            nosnippet={true}
            cssStyl={"okPOP"}
            topSlides={<TopBigSlides TopImages={[{ img: master.nowCloseImg, txt: "" }]} />}
            ContentText={[{ txt: "עסק זה כרגע סגור", css: "" },
            { txt: "ולא ניתן לבצע הזמנות", css: "" }]}
            inLabel={""}
            BtnTxt={"ביטול"}
            okClick={() => props.okClick()}
            BtnTxt2={"עבור לעמוד המסעדה"}
            btn1Css={"btnRed bold btnPoppadding15"}
            btn2Css={"btnGreen bold btnPoppadding15"}
            btnClick2={() => { props.btn2Click() }}
            errorMsg={""} />

    )
}


export default Out_Of_Hours;