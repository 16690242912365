import { useContext, useEffect, useRef, useState } from "react";
import { OrderContext } from "../ContextApi/context";
import myFunctions from "../myFunctions";

const DinersMoreThenOneMount = (props) => {

    // const [order, setOrder] = useContext(OrderContext);

    const [currentMount, setCurrentMount] = useState(props.Mount != null ? props.Mount : 1);

    const [minCount, setMinCount] = useState(props.minCount != null ? props.minCount : 0);
    const [imEnabled, setImEnabled] = useState(props.imEnabled != null ? props.imEnabled : true);


    useEffect(() => {

    }, [])

    const addToMount = () => {


        let temp = currentMount + 1;
        setCurrentMount(temp);

        props.changeVal(temp);
    }

    const lessToMount = () => {

        if (currentMount - 1 >= minCount) {

            setCurrentMount(currentMount - 1);
            props.changeVal(currentMount - 1);
        }
        else {
            try {
                props.clearchkBx();
            }
            catch { }
        }



    }


    return (
        <div className={`prodPlusMinus ${props.css}`}>
            {/* <div className={"mountLn"}>כמות</div> */}
            <div className={"plusBtn"} onClick={() => addToMount()}>+</div>
            <div className={"mountNum"}>
                {currentMount}
            </div>
            <div className={"minusBtn"} onClick={() => lessToMount()}>-</div>
        </div>
    );
}

export default DinersMoreThenOneMount;
