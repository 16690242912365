import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ArrowCircleRightSharp, FiberManualRecord } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ConnUrls from "../../Connections/ConnectURL";
import { MasterContext, MobiOrNotContext } from "../../ContextApi/context";
import MyDateTime from "../../DTechComp/MyDateTime";
import MySpinner_Wait from "../../DTechComp/MySpinner_Wait";
import ScrollingSaver from "../../DTechComp/ScrollingSaver";
import SearchComp from "../../DTechComp/Search";
import utilsRests from "../../_Utils/utilsRests";
import myFunctions from "../../myFunctions";
import SearchComp2 from "../../DTechComp/Search2";
import { Helmet } from "react-helmet";



const SearchP = (props) => {

    

    const [master, setMaster] = useContext(MasterContext);

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [loading, setLoading] = useState(true);
    const [shufMe, setShufMe] = useState([]);
    const [srchTxt, setSrchTxt] = useState("");
    const history = useHistory();
    const [bgImgPos, setBgImgPos] = useState("");
    const [waitImgItm, setWaitImgItm] = useState({});

    const queryParams = new URLSearchParams(window.location.search);

    let shuffClosed = [];

    useEffect(() => {
        // props.scrollToTop();

        props.shoTopStrp(false)

        setWaitImgItm(myFunctions.setTheImgWait({}, master));

        getSearchresault();
        setLoading(false)
    }, [])

    const goToBack = () => {
        sessionStorage.setItem("searchP", "");

        history.goBack();

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }
        // else {
        //      history.push('/'); }
    }

    const getSearchresault = async () => {
        setLoading(true)
        setSrchTxt(queryParams.get('txSrch'))
        let allOnSearch = await utilsRests.getSerchResault(queryParams.get('txSrch'));
        setShufMe(allOnSearch)

        setWaitImgItm(myFunctions.setTheImgWait(allOnSearch, master));
        setLoading(false)
    }

    const serachBuis = (txtSearch) => {
        history.push("/search/?txSrch=" + txtSearch);
        window.location.reload();
    }

    const setOpenItem = (item, index, opHours) => {
        let stickyShow = (item.buissIsOnline != null && item.buissIsOnline) ? true : false;
        let notGettinOrders = myFunctions.notGettinOrders(master, opHours, item);

        let timing = (item.Delivery?.Time) ? item.Delivery.Time : (item.TakeAway?.Time) ? item.TakeAway.Time : 30;
        timing = myFunctions.checkForDeliverysDelay(master,timing);
        
        // let priceing = (item.Delivery?.Price) ? item.Delivery.Price : (item.TakeAway?.Price) ? item.TakeAway.Price : 20;
        return (
            <div key={index} className={"Hprest"}>
                <Link to={{
                    pathname: `/restaurants/${item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id}`,
                    state: { waitImg: item.waitingImg }
                }}>
                    {stickyShow ? <>
                        <div className={"stiickeyOnlnBlk"} style={{
                            left: "5px",
                            top: "5px",
                            backgroundImage: `url(${ConnUrls.servMediasUrl}Uploads/MASTER/onlne.png)`,
                            backgroundPosition: `center`,
                            backgroundSize: `85%`,
                            backgroundRepeat: 'no-repeat'
                        }}
                        >
                        </div>

                    </>
                        : ""}

                    <div className={"imgWrap"}>
                        {(notGettinOrders != "") ?
                         <div data-nosnippet className={"no-render imgWrapDivClose imgWrapDivClose"}><span>{notGettinOrders}</span></div> : ""}
                        <div className={"imgDiv"} style={{ backgroundImage: `url("${ConnUrls.servMediasUrl}${item.buissMainImg}")` }} >
                            <div className={"botBlock"}>
                                <div className={"rSide"}>
                                    <div className={"pgBigTxt bold regular"}>{item.buissName}</div>
                                    <div className={"pgSmallTxt mediumst"}>
                                        <p>{item.buissKitchType}</p>
                                    </div>
                                </div>
                                <div className={"lSide"}>
                                    <div className={"pgSmallTxt"}>
                                        <p className={"pgOpenCloseBlk mediumst "}>
                                            <span className={`specDot ${!opHours.openOrCLose ? "specDotClose" : ""}`}><FiberManualRecord /></span>
                                            {/* {item.txtBuisKosher} */}
                                            {
                                                opHours.openOrCLose ?
                                                    opHours.timtxt : opHours.txtBtn

                                            }
                                        </p>
                                    </div>
                                    <div className={"pgSmallTxt mediumst"}>
                                        <div className={"theImgwrap"} >
                                            <div className={"theImg"} style={{ backgroundImage: `url(${ConnUrls.servMediasUrl}/PermanentImages/tustus.png")` }}></div>
                                        </div>
                                        <div className={"timeTxt mediumst"}>
                                            {(parseInt(timing) + 10) +
                                                " - " + parseInt(timing)} דק'
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </Link>
                <br />
            </div>
        )
    }


    return (

        // <div className={loading ? "imgWaiting" : `imgWaiting animate__animated animate__fast animate__zoomOut ${master.imgDelay}`}>
        //     <img src={`${ConnUrls.servMediasUrl}${master.waitingImgMaster}`} />
        // </div>
        <div className="CateguryPage">

            <div className={loading ? "imgWaiting" :
                `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
                style={{
                    backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
                    backgroundPosition: `${waitImgItm.imgBackPos}`,
                    backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
                    backgroundRepeat: `${waitImgItm.backgroundRepeat}`
                }}>

            </div>

            {loading ? <MySpinner_Wait /> : <>


                <div className={"headOnFixC SecondFont bold big"}>

                    <div>{`${srchTxt} תוצאות חיפוש`}</div>
                    <div className={`backSd`}>
                        <ArrowCircleRightSharp onClick={() => goToBack()} />
                    </div>
                </div>

                <br /><br /><br /><br /><br /><br />
                <div className={"fullRow overflowHid"}>
                    <div className={"onRight fullRow"}>
                        <div className={"pagHead bold big"}>{`${srchTxt} תוצאות חיפוש`}</div>

                    </div>
                    <div className={"onRight"}>
                        <div className={"srchDskTop"}>
                            <SearchComp2 pHolder={"חפש שם מסעדה, מנה או פריט"} srchFunc={(txtSrch) => serachBuis(txtSrch)} />
                        </div>
                    </div>
                </div>
                <br /><br />

                {shufMe.map((item, index) => {

                    let opHours = MyDateTime.checkIfBetweenHours(item);
                    if (opHours.openOrCLose) {
                        return (setOpenItem(item, index, opHours))
                    }
                    else {
                        shuffClosed.push({ item, index, opHours });
                    }
                })}

                {shuffClosed.map((item) => {

                    return (setOpenItem(item.item, item.index, item.opHours))
                })}

                <ScrollingSaver pagNam={"searchP"} />
            </>


            }
               <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/search`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/search`} />
            </Helmet>

        </div>
    )
}




export default SearchP;