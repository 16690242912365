import React, { useState, useEffect } from "react";

const GoogleMapItem = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    }, options);

    observer.observe(document.getElementById("mapContainer"));

    return () => {
      observer.disconnect();
    };
  }, []);

  const getSrcFromMapLink = (mapLink) => {
    try {
      const doc = document.createElement("div");
      doc.innerHTML = mapLink;
      const iframe = doc.getElementsByTagName("iframe")[0];
      return iframe ? iframe.src : "";
    } catch (error) {
      console.error("Error parsing map link:", error);
      return "";
    }
  };

  const theSrc = isVisible && props.mapLink ? getSrcFromMapLink(props.mapLink) : "";

  return (
    <div id="mapContainer">
      {isVisible && theSrc ? (
        <iframe
          src={theSrc}
          width="100%"
          className="googleMap"
          height={250}
          style={{ border: 0 }}
          title="Google Map"
          aria-hidden="false"
        ></iframe>
      ) : (
        <img
          src="/PermanentImages/map.jpg"
          width="100%"
          height={250}
          style={{ border: 0 }}
          alt="Default map"
        />
      )}
    </div>
  );
};

export default GoogleMapItem;
