import React, { useEffect, useState } from "react";
import utilsSitemap from "../../_Utils/utilsSitemap";
import { Helmet } from "react-helmet";
import ConnUrls from "../../Connections/ConnectURL";

const Sitemap = (props) => {
  const [sitemapContent, setSitemapContent] = useState('');

  useEffect(() => {
    async function fetchSitemap() {
      try {
        const response = await utilsSitemap.getSitemapXMl();
        setSitemapContent(response);


        // downloadSitemap(response);


      } catch (error) {
        console.error(error);
      }
    }

    fetchSitemap();
  }, []);


  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const domain = urlObject.origin;


  // const downloadSitemap = (content) => {
  //     const blob = new Blob([content], { type: 'application/xml' });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'sitemap.xml';

  //     // Trigger a click event on the hidden "a" element
  //     a.click();

  //     // Clean up
  //     window.URL.revokeObjectURL(url);
  //   };

  return (
    <div style={{ direction: "ltr", textAlign: "left" }}>
      {sitemapContent ? (
        <pre>{sitemapContent}</pre>
      ) : (
        <p>loading...</p>
      )}
      <Helmet
        meta={[
          {
            property: "og:url", content: `${ConnUrls.siteDomainNam}/sitemap.xml`
          }
        ]}>
        <link rel="canonical" href={`${ConnUrls.siteDomainNam}/sitemap.xml`} />
      </Helmet>

    </div>
  );
};

export default Sitemap;
