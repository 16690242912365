import users from "../_ApiConn/apiUsers";


const createUser = async (obj) => {
    let dFromF = await users.createUser(obj);
    return (dFromF);
}
const updateUser = async (userId, obj) => {
    let dFromF = await users.updateUser(userId, obj);
    return (dFromF);
}


const getUserById = async (id) => {
    let token = localStorage.getItem("usrtoken")
    let dFromF = await users.getUserById(id, token);
    return (dFromF);
}


const getAdresses = async (id) => {
    let dFromF = await users.getAdresses(id);
    return (dFromF);
}


const addNewAddress = async (id, address) => {
    let dFromF = await users.addNewAddress(id, address);
    return (dFromF);
}

const removeAddress = async (id, address) => {
    let dFromF = await users.removeAddress(id, address);
    return (dFromF);
}



const getUsersOrders = async (id) => {

    let token = localStorage.getItem("usrtoken")
    let dFromF = await users.getUsersOrders(id, token);

    return (dFromF);
}



const chekUserOrders = async (userId, restId) => {
    const token = localStorage.getItem('usrtoken');
    let dFromF = await users.chekUserOrders(userId, { restId, token });
    return (dFromF);
}

//בודק אם יש למשתמש טוקן של כ.אשראי
const checkForUserTokensCC = async (userId) => {
    const token = localStorage.getItem('usrtoken');
    let dFromF = await users.checkForUserTokensCC(userId, { token });
    return (dFromF);
}


//בודק אם יש למשתמש טוקן של כ.אשראי
const removeCCTok = async (userId, ccTok) => {
    const token = localStorage.getItem('usrtoken');
    let dFromF = await users.removeCCTok(userId, { token, ccTok });
    return (dFromF);
}

export default {
    removeAddress,
    addNewAddress,
    getAdresses,
    updateUser,
    createUser,
    getUserById,
    getUsersOrders,
    chekUserOrders,
    checkForUserTokensCC,
    removeCCTok
};