
// import React from "react";
// import DynamicSitemap from "react-dynamic-sitemap";
// import parse from 'html-react-parser';

import Routes from './SiteMap/TheRouts';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { CompressOutlined } from "@mui/icons-material";
import parse from 'html-react-parser';
import ConnUrls from '../Connections/ConnectURL';
import { Helmet } from 'react-helmet';

// var fs = require('fs');
// var builder = require("xmlbuilder");

// const router = require('../web_pages/Routes');



const RedirectTo404 = (props) => {

    useEffect(() => {
        window.location.href = "/err404";
    }, []);

    return null;


}

export default RedirectTo404;