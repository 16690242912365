import React, { useState, useEffect, useRef } from 'react';
import ConnUrls from '../Connections/ConnectURL';
import utilsRests from '../_Utils/utilsRests';
import { Link } from "react-router-dom";
import MySpinner_Wait from './MySpinner_Wait';

const SearchComp2 = (props) => {
  const [txtToSearch, setTxtToSearch] = useState('');
  const [matchingItems, setMatchingItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const typingTimerRef = useRef(null);

  const getByTyping = (searchText) => {
    clearTimeout(typingTimerRef.current); // Clear previous timer
    typingTimerRef.current = setTimeout(async () => {
      setLoading(true)
      if (searchText.trim() !== "") {
        const filteredItems = await utilsRests.getallBuisSmallLst(searchText);
        setMatchingItems(filteredItems);
      } else {
        setMatchingItems([]);
      }
      setLoading(false)
    }, 500);
  };

  const checkIfEnter = (e) => {
    if (e.key === 'Enter') {
      searchMe();
    }
  };

  const searchMe = () => {
    props.srchFunc(txtToSearch);
  };

  return (
    <>
      <div className="SearchDiv">
        <input
          type="text"
          value={txtToSearch}
          onKeyUp={(e) => checkIfEnter(e)}
          onChange={(e) => { setTxtToSearch(e.target.value); getByTyping(e.target.value); }}
          placeholder={props.pHolder}
        />

        <img id="searchBtn" src="/PermanentImages/searchIc.gif" alt="" onClick={() => searchMe()} />
      </div>
      <div className="searchDropdown">
        {loading ? <MySpinner_Wait /> :
          matchingItems.length > 0 && (
            <div className="dropdown-content">
              {matchingItems.map((item, indx) => (
                <Link key={indx} to={{
                  pathname: `/restaurants/${item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id}`,
                  state: { waitImg: item.waitingImg }
                }
                }>

                  <div className="dropdown-item">
                    <img className={'thumbImg'} src={ConnUrls.servMediasUrl + item.buissMainImg} alt={item.buissName} />
                    <span className={''}>{item.buissName}</span>
                  </div>
                </Link>
              ))}
            </div>

          )
        }
      </div>
    </>
  );
};

export default SearchComp2;
