import { useState, createContext, useEffect, useReducer, useContext, useRef } from 'react';
import myFunctions from '../myFunctions';
import masterUtil from "../_Utils/utilsMaster";


import { io } from 'socket.io-client';
import ConnUrls from '../Connections/ConnectURL';
import OrderFunctions from '../OrderSettings/OrderFunctions';

export const UserContext = createContext();

export const UserContextProvider = props => {
    const [user, setUser] = useState();
    const [refUserEffect, setRefUserEffect] = useState(false);

    useEffect(async () => {
        await userChecking();
    }, [])

    useEffect(async () => {

        await userChecking();


    }, [refUserEffect])

    useEffect(() => {

        if (user?.token) {
            localStorage.setItem("usrtoken", user.token);
        }


    }, [user])


    const userChecking = async () => {


        let bk = await myFunctions.connCheck();
        if (bk != null) {
            setUser(bk.user);

        }
        else {

            let user = {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                rest: "",
            }
            setUser(user)
        }
    }

    // Function to trigger the effect refresh
    const UserEffectFunction = async () => {
        setRefUserEffect(true);
    };

    return (
        <UserContext.Provider value={[user, setUser, UserEffectFunction]}>
            {props.children}
        </UserContext.Provider>
    )
}




export const MasterContext = createContext();

export const MasterContextProvider = props => {
    const [master, setMaster] = useState();


    return (
        <MasterContext.Provider value={[master, setMaster]}>
            {props.children}
        </MasterContext.Provider>
    )
}

export const ScreenWidthContext = createContext();

export const ScreenWidthContextProvider = props => {
    const [screenWidth, setScreenWidth] = useState();

    useEffect(() => {
        handleResize()
    }, [])


    const handleResize = () => {
        //  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        setScreenWidth(window.screen.width != null ? window.screen.width : "")
    }


    window.addEventListener('resize', handleResize)



    return (
        <ScreenWidthContext.Provider value={[screenWidth, setScreenWidth]}>
            {props.children}
        </ScreenWidthContext.Provider>
    )
}

export const MobiOrNotContext = createContext();

export const MobiOrNotContextProvider = (props) => {
    const [mobiOrNot, setMobiOrNot] = useState(false);

    useEffect(() => {
        handleResizeMobi();
    }, []);

    const handleResizeMobi = () => {

        const isAndroid = /Android/i.test(navigator.userAgent);
        const isIpad = /iPad|IPad/i.test(navigator.userAgent);
        const isIphone = /iPhone/i.test(navigator.userAgent);

        const isTablet = isIpad || (isAndroid && !/Mobile/i.test(navigator.userAgent));

        if (isIpad) {
            setMobiOrNot(false);
        } else if (isIphone) {
            if ((window.innerWidth > window.innerHeight)) {

                setMobiOrNot(!(window.innerHeight >= 1024));
            }
            else {
                setMobiOrNot(true);
            }
        } else if (isAndroid) {
            if ((window.innerWidth > window.innerHeight)) {
                setMobiOrNot(!(window.innerHeight >= 1024));
            } else if (!isTablet) {
                setMobiOrNot(true);
            } else {
                setMobiOrNot(false);
            }
        } else {
            const isDesktop = !isTablet;

            if (isDesktop) {
                setMobiOrNot(false);
            } else {
                setMobiOrNot(true); // Assume non-recognized devices are mobile by default
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResizeMobi);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResizeMobi);
        };
    }, []); // Make sure to provide an empty dependency array to avoid adding multiple event listeners

    return (
        <MobiOrNotContext.Provider value={[mobiOrNot, setMobiOrNot]}>
            {props.children}
        </MobiOrNotContext.Provider>
    );
};

export const CurrentPContext = createContext();

export const CurrentPContextProvider = props => {
    const [currentPage, setCurrentPage] = useState([]);

    return (
        <CurrentPContext.Provider value={[currentPage, setCurrentPage]}>
            {props.children}
        </CurrentPContext.Provider>
    )
}



export const OrdStageContext = createContext();

export const OrdStageContextProvider = props => {

    const [ordStage, setOrdStage] = useState(0);

    useEffect(() => {
        // alert("stateCHanged: " + ordStage)
    })

    return (
        <OrdStageContext.Provider value={[ordStage, setOrdStage]}>
            {props.children}
        </OrdStageContext.Provider>
    )
}


export const OrderContext = createContext();

export const OrderContextProvider = props => {

    // let localCart = sessionStorage.getItem("am_order");
    const [order, setOrder] = useState({ restId: "", currentP: [], Orders: [] });

    const setOrd = () => {
        let stringOrd = JSON.stringify({ ...order });
        sessionStorage.setItem("am_order", stringOrd)
    }

    useEffect(() => {

        if (sessionStorage.am_order != null) {
            let localCart = JSON.parse(sessionStorage.am_order);
            setOrder(localCart)
        }

    }, [])


    useEffect(() => {
        setOrd();
    }, [order])

    return (
        <OrderContext.Provider value={[order, setOrder]}>
            {props.children}
        </OrderContext.Provider>
    )
}

export const MessagesObjCssContext = createContext();

export const MessagesObjCssContextProvider = (props) => {
    const [messagesObjCss, setMessagesObjCss] = useState();
    const [siteMessages, setSiteMessages] = useState([]);
    const timerRef = useRef(null);  
    const delayingRef = useRef(null);  

    useEffect(() => {
        goSetTheMsgDisp();
    }, []);

    useEffect(() => {
        setTheMsgDisp();
    }, []);

    const goSetTheMsgDisp = async () => {
        await setTheMsgDisp();
    }

    const setTheMsgDisp = async () => {

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        if (delayingRef.current) {
            clearTimeout(delayingRef.current);
        }

        let inMast = await masterUtil.getSiteMessages();
        setSiteMessages(inMast.siteMessages);

        delayingRef.current = setTimeout(() => {
            setMessagesObjCss("showMsgObj");
            if (inMast.siteMessages) {

                timerRef.current = setTimeout(() => {
                    setMessagesObjCss("");
                }, inMast.siteMessages[0]?.timing || 5000);
            }
        }, 1000);

      
    }


    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <MessagesObjCssContext.Provider value={[messagesObjCss, setMessagesObjCss, goSetTheMsgDisp, siteMessages, setSiteMessages]}>
            {props.children}
        </MessagesObjCssContext.Provider>
    );
};