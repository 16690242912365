import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const getContactPageById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "contactP/" + id);
    return (dFromF);
}


const getPageByName = async (name) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "contactP/bn/" + name);
    return (dFromF);
}

const allPgsNames = async () => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "contactP/conp/allPgsNames", { token: null });

    return (dFromF);
}

const sendMsg = async (msg) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "contactP/conp/sendMsg", { msg, token: null });

    return (dFromF);
}


export default {
    getContactPageById,
    getPageByName,
    allPgsNames,
    sendMsg

};