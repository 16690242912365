
import { ArrowCircleRightSharp } from '@mui/icons-material';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router-dom";
import Scroll from 'react-scroll';
import ConnUrls from '../../Connections/ConnectURL';
import { CurrentPContext, MasterContext, MobiOrNotContext, OrderContext } from '../../ContextApi/context';
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import utilsSingleP from '../../_Utils/utilsSinglePage';
import myFunctions from '../../myFunctions';
import { Helmet } from 'react-helmet';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const SinglePage = (props) => {

  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const domain = urlObject.origin;

  const [order, setOrder] = useContext(OrderContext);
  const [master, setMaster] = useContext(MasterContext);

  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [allRestsOfCat, setAllRestsOfCat] = useState([]);
  const [cattxtOn, setCattxtOn] = useState([true]);
  const [waitImgItm, setWaitImgItm] = useState({});

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  useEffect(() => {


    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(false)
    getSinglePage();
    setLoading(false);

  }, [])


  const getSinglePage = async () => {
    let pName = props.pageName || window.document.href.substring(window.document.href.lastIndexOf('/') + 1)
    let theSingle = await utilsSingleP.getPageByName(props.pageName);
    setCurrentPage({ ...theSingle, pageTYp: "" });

  }
  const goToBack = () => {
    sessionStorage.setItem("SinglePage", "");
    history.goBack();

  }


  return (
    <div className="SinglePage">
      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>
      </div>

      {loading ? "" :
        <>
          {(mobiOrNot) ?
            <>
              <div className={"headOnFixC SecondFont bold big"}>
                <div className={`backSd`}>
                  <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>


              </div>
              <div className={`singlePagePad`}>
              </div>
            </>
            :
            ""
          }
          <div className={`${(currentPage.singleLtr) ? "textLtr" : ""}`}>
            <div className={`${(currentPage.singleLtr) ? "spaceLeftLong" : "spaceRightLong"} spacePadBig anakMeod bold SecondFontRegular lineHSmall`}>
              {currentPage.mainTitle ? currentPage.mainTitle : ""}</div>
            {/* ימין */}
            <div className={`${(!currentPage.oneContent) ? "simplePSides" : "simplePSOneBLock MyFont big"}`}>

              {(currentPage.mainImage != null && currentPage.mainImage != "") ?
                <div className={"singlPImg"}>
                  <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                </div>
                : ""}

              <div className={"SinglePageContent MyFont big"}>
                {parse(`${currentPage.mainContent}`)}
              </div>
            </div>
            {/* שמאל */}
            {(!currentPage.oneContent) ?
              <div className={"simplePSides"}>
                {(currentPage.mainImage2 != null && currentPage.mainImage2 != "") ?
                  <div className={"singlPImg"}>
                    <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage2}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                  </div>
                  : ""}

                <div className={"SinglePageContent MyFont big"}>
                  {parse(`${currentPage.mainContent2}`)}
                </div>
              </div>
              : ""}
            {/* <Prompt
            message={(location, action) => {
              sessionStorage.setItem("scrol", document.scrollY)
              return true;
            }}
          /> */}
            <ScrollingSaver pagNam={"SinglePage"} />

          </div>
        </>

      }

      <Helmet
        meta={[
          {
            property: "og:url", content: `${ConnUrls.siteDomainNam}/${encodeURIComponent(currentPage.inLinkToPage)}`
          }
        ]}>
        <link rel="canonical" href={`${ConnUrls.siteDomainNam}/${encodeURIComponent(currentPage.inLinkToPage)}`} />
      </Helmet>






    </div >
  );
}

export default SinglePage;
