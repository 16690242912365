import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUpClearComp = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    useEffect(() => {

    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
        (props.cancelClick) ?
            props.cancelClick() :
            props.closePop();
    };

    return (
        <Dialog open={open} className={`${props.popFullComp ? props.popFullComp : "popFullComp"} popDiagBlock ${props.cssStyl ? props.cssStyl : ""}`}>
            {/* <DialogTitle >
            </DialogTitle> */}
            <DialogContent className={`${props.insideCss ? props.insideCss : ""}`}>
                <DialogContentText >
                    {props.theInsideComp}
                </DialogContentText>

                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}

            </DialogContent>
            {
                props.okClick &&
                <DialogActions >
                    <button type="button"
                        className={`pointing centerMe btn btn-success ${props.btnFStyle || ""}`}
                        onClick={() => { props.okClick(); handleClose(); }}>
                        {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}</button>
                </DialogActions>
            }
            <DialogActions >
                {props.btnClick2 != null ?
                    <>
                        <span>&nbsp;&nbsp;</span>
                        <button type="button"
                            className={`btn-success btnPopRegularSec ${props.btnFStyle || "btnPopRegular"}`}
                            onClick={() => { props.btnClick2(); handleClose(); }}>
                            {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</button>
                    </>
                    : ""}
            </DialogActions>
        </Dialog >

    );
}

export default PopUpClearComp;