import specialP from "../_ApiConn/apiSpecialPages";



const getSpecialPageByName = async (pageName) => {
    let dFromF = await specialP.getSpecialPageByName(pageName);
    dFromF.pageName = dFromF.mainHead
    return (dFromF);
}



export default {
    getSpecialPageByName,

};