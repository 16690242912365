
import React, { useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';



const Crdit_Token_Problem = (props) => {

    return (
        <PopUpMsg Title={["בעיית חיוב כ.קיים"]}
        nosnippet={true}
            ContentText={[{ txt: "התגלתה בעייה בחיוב", css: "" },
            { txt: "בתור תחלופה, ניתן להמשיך עם ההזמנה", css: "" },
            { txt: "ולבצע תשלום בכרטיס אחר או במזומן" }]}
            inLabel={""}

            BtnTxt={"אשלם בצורה אחרת"}
            okClick={() => {
                props.okClick()
            }}

            btnClick2={() => { props.closePop() }}
            cssStyl={"orderOneClose"}
            BtnTxt2={"סגור"}
            errorMsg={""} />
    )
}


export default Crdit_Token_Problem