import LandingP from "../_ApiConn/apiLandingPage";


const getLandingPageById = async (idToGet) => {
    let dFromF = await LandingP.getLandingPageById(idToGet);
    return (dFromF.data);
}

const getPageByName = async (name) => {
    let dFromF = await LandingP.getPageByName(name);
    return (dFromF.data[0]);
}

const allPgsNames = async () => {
    let dFromF = await LandingP.allPgsNames();
    return (dFromF.data);
}

export default {
    getLandingPageById,
    getPageByName,
    allPgsNames
};