import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const getLandingPageById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "landingP/" + id);
    return (dFromF);
}


const getPageByName = async (name) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "landingP/bn/" + name);
    return (dFromF);
}

const allPgsNames = async () => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "landingP/landP/allPgsNamesUser");

 return (dFromF);
}

export default {
    getLandingPageById,
    getPageByName,
    allPgsNames

};