import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { CurrentPContext, MasterContext, MobiOrNotContext } from "../ContextApi/context";

import '../Fonts.css';
import '../Style.css';
import '../MySetStyle.css';

import '../DesktopStyle.scss';
import '../MobileMe.scss';


import masterUtil from "../_Utils/utilsMaster";
import '../animate.css';



const MasterHeadComp = () => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const container = document.getElementById('MainBody');

    if (container) {
      // Remove existing styles
      container.classList.remove('desktop-me-style', 'mobile-me-style');

      // Add the appropriate style based on mobiOrNot
      container.classList.add(mobiOrNot ? 'mobile-me-style' : 'desktop-me-style');
    }
  }, [mobiOrNot]);

  useEffect(() => {
    if (master == null) {
      setThemast();
    }
    setLoading(false)
  }, [])

  useEffect(() => {

  }, [currentPage])


  // const canonicalUrl = document.querySelector("link[rel='canonical']").getAttribute("href");

  const setThemast = async () => {
    let mast = await masterUtil.getMaster();

    setMaster(mast);

  }
  return (
    <>
      {loading ? "" :
        <Helmet
          title={currentPage.pageName || master?.mainTitle || "אשדוד בתפריט"}
          meta={[
            {
              name: "description",
              property: "og:description",
              content: currentPage.sEOdescription || master?.mainTitle || ""
            },
            {
              name: "keywords",
              property: "og:keywords",
              content: currentPage.sEOkeywords || master?.mainTitle || ""
            },
            { property: "og:title", content: currentPage.pageName || master?.mainTitle || "אשדוד בתפריט" },
            // { property: "og:url", content: decodeURI(window.location.href) }
            // { property: "og:image", content: seo.image },
            // { property: "og:image:type", content: "image/png" },

          ]}

        >
        </Helmet>




      }
    </>
  );
}

export default MasterHeadComp;
