import master from "../_ApiConn/apiMaster";


const getMaster = async () => {
    
    let dFromF = await master.getMaster();
    return (dFromF.data[0]);
}

const getSiteMessages = async () => {
    
    let dFromF = await master.getSiteMessages();
    return (dFromF.data);
}

export default {
    getMaster,
    getSiteMessages
};