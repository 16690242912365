import jwt from "jsonwebtoken";
import tokCheck from "./_Utils/utilsCodes"
import MyDateTime from "./DTechComp/MyDateTime";


const checkToken = async (toeknToCheck) => {

    let theUsr = await tokCheck.checkToken(toeknToCheck);
    return theUsr;

}

const connCheck = async () => {
    const usrtoken = localStorage.getItem('usrtoken');

    if (usrtoken) {
        const user = jwt.decode(usrtoken);

        let usr = await checkToken(usrtoken);

        if (usr.user != null) {
            return usr;
        }
        else {
            localStorage.removeItem('usrtoken');
            return null;

            // goLogin();
        }

    }
    else {
        return null;
    }
}

// const goLogin = () => {
//     if (!window.location.href.includes('/LoginPage') && !window.location.href.includes('/resPass')) {
//         return window.location.href = '/LoginPage'
//     }

// }


const randomImgPos = () => {

    let tp = Math.floor(Math.random() * 100);
    let lf = Math.floor(Math.random() * 100);

    return (tp + "% " + tp + "%")
}
const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
        if (a[field] > b[field]) { return 1; }
        if (b[field] > a[field]) { return -1; }
        return 0;
    })
}

const setSHuffledRandom = (rests, lstBack) => {
    // let filt = rests.filter(itm => !itm.random)
    // let noMe = go.filter(itm => itm.random)

    let randomIndex = Math.floor(Math.random() * rests.length);
    lstBack.push(rests[randomIndex]);
    rests.splice(randomIndex, 1);
    if (rests.length > 0) {
        lstBack = setSHuffledRandom(rests, lstBack)
    }

    return lstBack;
}


const setSHuffled = (rests, lstBack) => {
    let randomIndex = Math.floor(Math.random() * rests.length);
    lstBack.push(rests[randomIndex]);
    rests.splice(randomIndex, 1);
    if (rests.length > 0) {
        lstBack = setSHuffled(rests, lstBack)
    }

    return lstBack;
}


const setTheImgWait = (curPage, master) => {

    let bgImgPos = (randomImgPos());

    let imgDelay = (curPage && curPage.imgDelay != null) ? curPage.imgDelay : (master && master.imgDelay);



    let waitImg = "";

    let imgBackPos = "";
    let backgroundSize = "";
    let backgroundRepeat = "";
    let imgWaitingSpecial = "";

    if (curPage.waitingImg != null && curPage.waitingImg != "") {
        waitImg = curPage.waitingImg;
        imgWaitingSpecial = "imgWaitingSpecial"
    }
    else {
        waitImg = master.waitingImgMaster;
    }

    imgBackPos = curPage.imgPosRndom ? bgImgPos : 'center';
    backgroundSize = curPage.imgPosRndom ? 'auto' : 'contain';
    backgroundRepeat = !curPage.imgPosRndom ? 'no-repeat' : '';
    return ({ imgDelay, waitImg, imgBackPos, backgroundSize, backgroundRepeat, imgWaitingSpecial });
}



const searchMe = (txtSearch, searchList) => {
    txtSearch = (txtSearch == null) ? "" : txtSearch.trim();
    let lstBack = [];

    if (txtSearch != "") {
        let theLstBack = searchList.map((itm, index) => {

            if (itm.buissName.includes(txtSearch)) {
                if (lstBack.findIndex(ls => ls._id == itm._id) == -1) {
                    lstBack = [...lstBack, itm];
                }
            }
            else {

                itm.buisMenu.map((mnuItm, mnuItmIndex) => {
                    if (mnuItm.DivName.includes(txtSearch)) {
                        if (lstBack.findIndex(ls => ls._id == itm._id) == -1) {
                            lstBack = [...lstBack, itm];
                        }
                    }
                    else {
                        mnuItm.Products.map((mnuItmProd, mnuItmProdIndex) => {
                            if (mnuItmProd.prodName.includes(txtSearch)) {
                                if (lstBack.findIndex(ls => ls._id == itm._id) == -1) {
                                    lstBack = [...lstBack, itm];
                                }
                            }
                        })
                    }

                })

            }
        })

        return lstBack;
    }
    else {

        return searchList;
    }
}

//מתייחס לרשימות צ'ק בוקסס 
//בודק כמה פריטים נבחור עד כה ברשימה המתקבלת
const getTotalSelected = (theLst) => {
    let counting = 0;

    if (theLst && theLst.length > 0) {
        theLst.forEach((item, index) => {
            counting += item.itmsMount;
        });
    }

    return counting
}

//מתייחס לרשימות צ'ק בוקסס 
//בודק אם הרשימה עברה את המקסימום
const chkIfPasdMax = (maxSelection, selectedCounting) => {
    maxSelection = maxSelection > 0 ? maxSelection : 500

    if (maxSelection > selectedCounting) {

        return false;
    }
    else {

        return true;
    }


}



const validPhone = (phone) => {
    if (phone && phone !== "") {
        var clearPhone = phone.replace('-', '');
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(clearPhone)) {
            return false
        } else if (clearPhone.length != 10 && clearPhone.length != 9) {
            return "<p>יש להזין מספר טלפון תקין</p>";
        }
        else {
            return "";
        }
    }
    else {
        return "<p>יש להזין מספר טלפון תקין</p>";
    }
}

const validEmail = (email) => {
    if (email !== "") {
        var pattern = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
        if (!pattern.test(email)) {
            return "<p>יש להזין כתובת מייל תקינה<p>";
        }
        else {
            return "";
        }
    }
    else {
        return "<p>יש להזין כתובת מייל תקינה<p>";
    }
}


const getOnlyaddress = (props) => {

    let enter = (props.address?.ent != null) ? props.address?.ent + " " : "";

    let mainAddress = props.address?.street + " " + props.address?.num + " " + enter + props.address?.city;

    return (
        { mainAddress }
    );
}


const notGettinOrders = (master, opHours, item) => {

    let notGettinOrders = "";
    //בודק שאין סגירה יזומה
    if (master.blockDeliverys.block &&
        master.blockPickUps.block) {
        notGettinOrders = "סגור כעת";
    }
    //בודק פעילויות
    else {
        if (opHours.openOrCLose) {
            if (item.closeDeliverDelay?.delaydVal !== null &&
                item.closeDeliverDelay?.delaydVal !== undefined &&
                item.closeDeliverDelay?.delaydVal !== "0" &&
                item.closeTakeDelay?.delaydVal !== null &&
                item.closeTakeDelay?.delaydVal !== undefined &&
                item.closeTakeDelay?.delaydVal !== "0") {
                notGettinOrders = "עמוס, סגור להזמנות";
            } else if
                (item.closeDeliverDelay?.delaydVal !== null &&
                item.closeDeliverDelay?.delaydVal !== undefined &&
                item.closeDeliverDelay?.delaydVal !== "0") {
                notGettinOrders = "עמוס, סגור למשלוחים";
            }
            else if (item.buissIsOnline != null && item.buissIsOnline) {
                if (!item.Delivery?.Doing &&
                    !item.TakeAway?.Doing) {
                    notGettinOrders = "עמוס, סגור להזמנות";
                }
                else if (!item.Delivery?.Doing &&
                    item.TakeAway?.Doing) {
                    notGettinOrders = "עמוס, סגור למשלוחים";
                }
            }
        }
        else {
            notGettinOrders = "סגור כעת";
        }
    }
    return notGettinOrders;
}

//בודק אם יש ההגדרת שינוי משלוח לכלל העסקים במאסטר
const checkForDeliverysDelay = (master, buissTiming) => {
    if (master.timingDeliverys && master.timingDeliverys > 0) {
        return (master.timingDeliverys > parseInt(buissTiming)) ?
            master.timingDeliverys : buissTiming;
    }
    else if (master.minTimingDeliverys && master.minTimingDeliverys > 0) {
        return (parseInt(buissTiming) + master.minTimingDeliverys);
    }
    else {
        return buissTiming;
    }

}

const getShufBuissClosAtLast = (lst) => {

    let closed = [];
    let opnes = [];
    let noOrders = [];
    lst.map((item, index) => {
        if (item !== undefined) {
            let opHours = MyDateTime.checkIfBetweenHours(item);
            if (opHours.openOrCLose) {
                if (!item.Delivery?.Doing &&
                    !item.TakeAway?.Doing) {
                    noOrders.push(item)
                }
                else if (!item.Delivery?.Doing &&
                    item.TakeAway?.Doing) {
                    noOrders.push(item)
                }
                else {
                    opnes.push(item)
                }
            }
            else {
                closed.push(item);

            }
        }
    })

    let readylist = [];
    if (opnes.length > 0) {
        let readylistOpens = setSHuffled(opnes, []);
        if (readylistOpens) { readylist = [...readylistOpens] }
    }
    if (noOrders.length > 0) {
        let readylistnoOrders = setSHuffled(noOrders, []);
        if (readylistnoOrders) { readylist = [...readylist, ...readylistnoOrders] }
    }
    if (closed.length > 0) {
        let readylistClosed = setSHuffled(closed, []);
        if (readylistClosed) { readylist = [...readylist, ...readylistClosed] }
    }

    return (readylist)
}

export default {
    getShufBuissClosAtLast,
    setSHuffledRandom,
    setSHuffled,
    searchMe,
    sortAsc,
    randomImgPos,
    setTheImgWait,
    getTotalSelected,
    chkIfPasdMax, connCheck,
    validPhone,
    checkToken,
    validEmail,
    getOnlyaddress,
    notGettinOrders,
    checkForDeliverysDelay

};
