import order from "../_ApiConn/apiOrder";


const orderMyFood = async (obj) => {
    let dFromF = await order.orderMyFood(obj);
    return (dFromF);
}

const tempoOrder = async (obj) => {
    let dFromF = await order.tempoOrder(obj);
    return (dFromF);
}

const getOrder = async (id) => {
    let dFromF = await order.getOrder(id);
    return (dFromF);
}


const getCounter = async () => {
    let dFromF = await order.getCounter();
    return (dFromF);
}

const updateStatus = async (orderId) => {

    let token = localStorage.getItem("usrtoken");
    let dFromF = await order.updateStatus({ orderId, token });
    return (dFromF);
}


const getDuplictedOrder = async (id) => {
    const token = localStorage.getItem('usrtoken');
    let dFromF = await order.getDuplictedOrder(id,token);
    return (dFromF);
}



// const updateOrderPayParms = async (payParams) => {

//     let token = localStorage.getItem("usrtoken");
//     let dFromF = await order.updateOrderPayParms({ payParams, token });
//     return (dFromF);
// }




export default {
    orderMyFood,
    tempoOrder,
    getDuplictedOrder,
    // updateOrderPayParms,
    getOrder,
    getCounter,
    updateStatus
};