

import { ShoppingCartOutlined } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { OrderContext, OrderContextProvider } from '../ContextApi/context';

const ShopingCartFloat = (props) => {
    const [order, setOrder] = useContext(OrderContext);

    const [counter, setCounter] = useState(0);

    const showShopCart = () => {

        
    }

    useEffect(() => {
        let coun = 0;

        order.Orders?.forEach(item => {
            coun = parseInt(coun) + parseInt(item.mount);
        });
        setCounter(coun)


    }, [order])
    return (
        <div className={"shopCartBtnWrap"} onClick={() => showShopCart()} >
            <ShoppingCartOutlined sx={{ fontSize: 30 }} /><span>{counter}</span>
        </div>
    )

}
export default ShopingCartFloat;

