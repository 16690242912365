

import { CastForEducationOutlined } from "@mui/icons-material";
import moment from "moment";
import duration from "moment-duration-format";


class MyDateTime {

    static getYesterday = (timeString,daysToback) => {
        return moment(timeString).subtract(daysToback, 'days').format('dddd');
    }
    
    static getDate = (timeString) => {
        return (
            moment(timeString).format('DD/MM/YYYY')
        )
    }
    static getTime = (timeString) => {
        return (
            moment(timeString).format('HH:mm:ss')
        )
    }

    static getShortTime = (timeString) => {

        return (
            moment(timeString).format('HH:mm')
        )
    }
    static getDateAndTime = (timeString) => {
        return (
            moment(timeString).format('DD/MM/YYYY HH:mm')
        )
    }

    static getDayOfWeek = (timeString) => {

        return (
            moment(timeString).format('dddd')
        )
    }
    static getDayOfWeekHeb = (timeString) => {
        switch (moment(timeString).format('dddd')) {
            case "Sunday": return "ראשון";
            case "Monday": return "שני";
            case "Tuesday": return "שלישי";
            case "Wednesday": return "רביעי";
            case "Thursday": return "חמישי";
            case "Friday": return "שישי";
            case "Saturday": return "שבת";
            default: return "";
        }

    }

    static getDayOfWeekLettersHeb = (DayName) => {
        switch (DayName) {
            case "Sunday": return "א";
            case "Monday": return "ב";
            case "Tuesday": return "ג";
            case "Wednesday": return "ד";
            case "Thursday": return "ה";
            case "Friday": return "ו";
            case "Saturday": return "ש";
            default: return "";
        }

    }

    static getDayOfWeekByDayNum = (dayNum) => {
        switch (dayNum) {
            case 1: return "Sunday";
            case 2: return "Monday";
            case 3: return "Tuesday";
            case 4: return "Wednesday";
            case 5: return "Thursday";
            case 6: return "Friday";
            case 7: return "Saturday";
            default: return "";
        }

    }


    // static getTimeLeftTilThen = (timeString, thenTime, showTxts) => {

    //     var thenTime = moment(timeString).add(parseInt(thenTime), 'minutes')

    //     var seconds = moment().utc(true).local('he').diff(thenTime, 'seconds')
    //     var duration = moment.duration(seconds, 'seconds');
    //     var formatted = duration.format("hh:mm:ss");

    //     var temp = moment().utc(true).local('he').diff(thenTime, 'hours')
    //     var daysOver = parseInt(parseInt(temp) / 24);
    //     var hoursLeft = (parseInt(temp) % 24)


    //     if (formatted.toString().includes("-")) {
    //         return (
    //             {
    //                 theTm: formatted.toString().replace("-", ""),
    //                 theCol: "lightGray",
    //                 theForCol: "black",
    //                 theEnd: false
    //             }
    //         )
    //     }
    //     else {
    //         if (showTxts) {
    //             return (
    //                 {
    //                     theTm: formatted + (daysOver > 0) ? formatted + " - " + daysOver + " ימים " + hoursLeft + " שעות" : formatted,
    //                     theCol: "red",
    //                     theForCol: "white",
    //                     theEnd: true
    //                 })
    //         }
    //         else {
    //             return (
    //                 {
    //                     theTm: formatted,
    //                     theCol: "red",
    //                     theForCol: "white",
    //                     theEnd: true
    //                 }
    //             )
    //         }
    //     }
    // }

    //בודק אם בתוך טווח שעות



    static getTimeLeftTilThen = (timeString, thenTime) => {

        var thenTime = moment(timeString).add(parseInt(thenTime), 'minutes')

        var seconds = moment().utc().locale('he').diff(thenTime, 'seconds')
        var duration = moment.duration(seconds, 'seconds');
        var formatted = duration.format("hh:mm:ss");

        var temp = moment().utc().locale('he').diff(thenTime, 'hours')
        var daysOver = parseInt(parseInt(temp) / 24);
        var hoursLeft = (parseInt(temp) % 24)


        if (formatted.toString().includes("-")) {
            return (
                {
                    theTm: formatted.toString().replace("-", ""),
                    theCol: "lightGray",
                    theForCol: "black",
                    theEnd: false
                }
            )
        }
        else {
            return (
                {
                    theTm: formatted + (daysOver > 0) ? formatted + " - " + daysOver + " ימים " + hoursLeft + " שעות" : formatted,
                    theCol: "red",
                    theForCol: "white",
                    theEnd: true
                }
            )
        }

    }


    static checkIfBetweenHours = (item) => {

        var today = new Date();

       let itt = item.OpeningHours.findIndex(dy =>
            (this.getDayOfWeek(today).toString() == dy.Day.toString()))


        let zeroTime = new Date();
        zeroTime.setHours(0, 0, 0);

        let open = (item.OpeningHours[itt].Open != null && item.OpeningHours[itt].Open != "") ? item.OpeningHours[itt].Open : zeroTime
        let close = (item.OpeningHours[itt].Close != null && item.OpeningHours[itt].Close != "") ? item.OpeningHours[itt].Close : zeroTime

        let chkMe = this.checkIt(today, open, close)

        return chkMe;

    }



    static checkIfBetweenDeliveryHours = (item) => {

        var today = new Date();


        let itt = item.DeliveryHours.findIndex(dy =>
            (this.getDayOfWeek(today).toString() == dy.Day.toString()))


        let zeroTime = new Date();
        zeroTime.setHours(0, 0, 0);

        let open = (item.DeliveryHours[itt].Open != null && item.DeliveryHours[itt].Open != "") ? item.DeliveryHours[itt].Open : zeroTime
        let close = (item.DeliveryHours[itt].Close != null && item.DeliveryHours[itt].Close != "") ? item.DeliveryHours[itt].Close : zeroTime

        let chkMe = this.checkIt(today, open, close)

        return chkMe;

    }

    static checkIt = (tmToCheck, startHu, endHu) => {
        const timeFormat = 'HH:mm:ss';

        let openTxt = "פתוח";
        let closeTxt = "סגור";

        let tm = this.getShortTime(tmToCheck);
        let bef = this.getShortTime(startHu);
        let aft = this.getShortTime(endHu);

        if (bef != "" && aft != "") {
            ///סגור
            if (bef == "00:00" &&
                aft == "00:00") {
                return {
                    openOrCLose: false, txtBtn: closeTxt
                }
            }
            ///שעת סגירה גדול משעת פתיחה 
            //- המקום סוגר לפני 12 בלילה
            if (aft > bef) {
                //השעה הנוכחית גדולה משעת הסגירה
                if (tm > aft) {
                    return {
                        openOrCLose: false,
                        txtBtn: (bef != "00:00") ? closeTxt + " (" + bef + ")" : closeTxt + " היום",
                        timtxt: aft + " - " + bef
                    };
                }
                else {

                    if (tm > bef) {
                        return {
                            openOrCLose: true,
                            txtBtn: openTxt + " ( " + bef + "-" + aft + " )",
                            timtxt: aft + " - " + bef
                        };
                    }
                    else {
                        return {
                            openOrCLose: false,
                            txtBtn: (bef != "00:00") ? closeTxt + " (" + bef + ")" : closeTxt + " היום",
                            timtxt: aft + " - " + bef
                        };
                    }

                }
            }
            ///שעת סגירה קטן משעת פתיחה 
            //- המקום סוגר אחרי 12 בלילה
            else if (tm < bef && tm > aft) {

                return {
                    openOrCLose: false,
                    txtBtn: (bef != "00:00") ? closeTxt + " (" + bef + ")" : closeTxt + " היום",
                    timtxt: aft + " - " + bef
                };
            }
            else {
                return {
                    openOrCLose: true,
                    txtBtn: openTxt + " ( " + bef + "-" + aft + " )",
                    timtxt: aft + " - " + bef
                };

            }

        }
        else {
            return {
                openOrCLose: true,
                txtBtn: openTxt + " (" + bef + ":" + aft + ")",
                timtxt: aft + " - " + bef
            };
        }

    }

}
export default MyDateTime;
