
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ConnUrls from "../Connections/ConnectURL";
import { MasterContext, OrderContext } from "../ContextApi/context";
import BigPopUpMsg from "../DTechComp/BigPopUpMsg";
import OrderFunctions from "../OrderSettings/OrderFunctions";
import PopUpOneLine from "../DTechComp/PopUpOneLine";





const LogoComp = (props) => {

  const [master, setMaster] = useContext(MasterContext);
  const [order, setOrder] = useContext(OrderContext);
  const [thePopUp, setThePopUp] = useState("");

  const history = useHistory();


  const goHome = () => {

    if (order.Orders?.length > 0 &&
      order.Orders[0] != "") {
      setThePopUp(

        <PopUpOneLine
          cssStyl={"specialPopComp onTopOfAll noPadding"}
          key={"popTiming"}
          topTitle={"לתשומת לבך"}
          Title={["יש הזמנה פעילה,", "יציאה תוביל לאובדן הנתונים"]}
          inLabel={""}
          closeFunc={() => setThePopUp()}
          btn1Clck={() => setThePopUp()}
          btn1Txt={"המשך להזמין"}
          btn2Txt={"צא בכל זאת"}
          btn2Clck={() => {
            sessionStorage.setItem("restPage", "");
            setOrder(OrderFunctions.clearOrd())
            history.push("/");
            setThePopUp();
          }}
        />


      )
    }
    else {
      sessionStorage.setItem("restPage", "");
      history.push("/");
    }
  }

  return (
    <div className={"mobiLogoDiv pointing"}>
      <div onClick={() => goHome()} >
        <img src={props.logoUrl} alt="אשדוד בתפריט" />
      </div>
      {thePopUp}
    </div>
  )

}

export default LogoComp;
