import { useEffect, useState } from "react";
import MyDateTime from "../../../DTechComp/MyDateTime";
import { AccessTime, FiberManualRecord } from '@mui/icons-material';

const WeekOpeningHours = (props) => {

    const [currntHous, setCurrentHous] = useState();
    const OpenHour = props.OpeningHours;
    const [tempStr, setTempStr] = useState("א");

    useEffect(() => {

        let curr = {};
        let startD = "";
        let endD = "";
        let hourdD = "";
        let tempString = "";
        let ddays = [];

        for (let i = 0; i < OpenHour.length; i++) {

            curr = (curr.Open) ? curr : OpenHour[i];
            
            startD = (startD == "") ? MyDateTime.getDayOfWeekLettersHeb(OpenHour[i].Day) + "\'" : startD;
            endD = (endD == "") ? MyDateTime.getDayOfWeekLettersHeb(OpenHour[i].Day) + "\'" : endD;
            hourdD = (hourdD == "") ? MyDateTime.getShortTime(OpenHour[i].Open) + "-" +
                MyDateTime.getShortTime(OpenHour[i].Close) :
                hourdD;

            if (MyDateTime.getShortTime(curr.Open) == MyDateTime.getShortTime(OpenHour[i].Open) &&
                MyDateTime.getShortTime(curr.Close) == MyDateTime.getShortTime(OpenHour[i].Close)) {

                endD = MyDateTime.getDayOfWeekLettersHeb(OpenHour[i].Day) + "\'";
                hourdD = MyDateTime.getShortTime(OpenHour[i].Open) + "-" +
                    MyDateTime.getShortTime(OpenHour[i].Close)
            }
            else {
                hourdD = (hourdD != "00:00-00:00") ? hourdD : props.noWork;
                if (startD == endD) {
                    tempString = startD + " : " + hourdD;
                }
                else {
                    tempString = startD + "-" + endD + " : " + hourdD;
                }

                startD = MyDateTime.getDayOfWeekLettersHeb(OpenHour[i].Day) + "\'";
                endD = MyDateTime.getDayOfWeekLettersHeb(OpenHour[i].Day) + "\'";
                hourdD = MyDateTime.getShortTime(OpenHour[i].Open) + "-" +
                    MyDateTime.getShortTime(OpenHour[i].Close)
                curr = OpenHour[i];
                ddays.push(tempString);
            }

        }

        hourdD = (hourdD != "00:00-00:00") ? hourdD : props.noWork;
        if (startD == endD) {
            tempString = startD + " : " + hourdD;
        }
        else {
            tempString = startD + "-" + endD + " : " + hourdD;
        }
        ddays.push(tempString);

        setTempStr(ddays.map((item, index) => {
            return (
                <p className="DDS" key={index}>{item}</p>
            )
        }));


    }, [])

    return (
        <>
            {tempStr}
        </>
    )
}

export default WeekOpeningHours;
