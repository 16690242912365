
import React, { useContext } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import { MasterContext, OrderContext } from '../../ContextApi/context';
import PopUpMsg from '../PopUpMsg';



const Table_Order_Confirmd = (props) => {
    const [master] = useContext(MasterContext);
    return (

        <PopUpMsg Title={["איזה כיף"]}
        nosnippet={true}
            ContentText={[{ txt: "המסעדה קיבלה את ההזמנה", css: "" }
                , { txt: "ותאושר בהקדם בהודעת סמס", css: "bold big" }
                , { txt: `מספר הזמנה: ${props.tblOrderNUM}`, css: "" }
            ]}
            inLabel={""}
            BottomTitle={["בתאבון!"]}
            topImg={`${ConnUrls.servMediasUrl}${master.orderApprovImg}`}
            cssStyl={"approveMsgPopup onTopOfAll"}
            okClick={() => { props.okClick() }}
            errorMsg={""} />
    )
}


export default Table_Order_Confirmd
