import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const goCardCom = async (theOrder) => {

    let dFromF = await axios.post(ConnUrls.ApiUrl + "goCardCom", theOrder);
    return (dFromF.data);
}



const cardChargeToken = async (theOrder) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "goCardCom/chargeToken", theOrder);
    return (dFromF.data);
}



const CardComApprove = async (theOrder) => {

    let dFromF = await axios.post(ConnUrls.ApiUrl + "goCardCom/ApproveCardCom", theOrder);
    return (dFromF.data);
}


export default {
    goCardCom,
    CardComApprove,
    cardChargeToken
};