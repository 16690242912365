import React, { useState } from 'react';


const PopUpHappy = (props) => {

    const [open, setOpen] = useState(true);

    return (
        <div className={"loginPage"}>

            {(props.topSlides != null) ?
                props.topSlides : ""
            }
            <div className="firstMastSec">
                <div className="insideFrame spaceMargBottomVeryBig">
                    {props.Title.map((txtItm, index) => (
                        <span className="huge arrowing fullRow" key={index}>{txtItm}</span>
                    ))}
                </div>
                <div className="fullRow">
                    <div className="fullRow">
                        <div className="connectBtn conClear bold big pointing"
                            onClick={() => props.okClick()}>המשך</div>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default PopUpHappy;