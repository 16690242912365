

import { ArrowCircleRightSharp, LocalDining } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import EditOff from '@mui/icons-material/EditOff';
import Subject from '@mui/icons-material/Subject';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from 'react';
import { CurrentPContext, MasterContext, MobiOrNotContext, OrderContext, UserContext } from '../ContextApi/context';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useHistory } from "react-router-dom";
import GoogleMapItem from '../DTechComp/GoogleMapItem';
import PopUpClearComp from "../DTechComp/PopUpClearComp";
import UserDetails from "../UsersLogs/UserDetails";
import utilsOrders from '../_Utils/utilsOrders';
import utilsRests from '../_Utils/utilsRests';
import OrderFunctions from './OrderFunctions';
import TheMount from './TheMount';

import Scroll from 'react-scroll';
import MyDateTime from '../DTechComp/MyDateTime';
import MySpinner_Wait from '../DTechComp/MySpinner_Wait';
import PopUpIframe from '../DTechComp/PopUpIframe';
import Closed_Buis from '../DTechComp/Pop_Fixed/Closed_Buis';
import Crdit_Problem from '../DTechComp/Pop_Fixed/Crdit_Problem';
import Delivery_Confirmd from '../DTechComp/Pop_Fixed/Delivery_Confirmd';
import Minimum_Order from '../DTechComp/Pop_Fixed/Minimum_Order';
import TAKE_Confirmd from '../DTechComp/Pop_Fixed/TAKE_Confirmd';
import UserAdresses from '../UsersLogs/UserAdresses';
import utilsUsers from "../_Utils/utilsUsers";
import utilsGamma from '../_Utils/utilsGamma';

import { Helmet } from 'react-helmet';
import PopUpCardComIframe from '../DTechComp/PopUpCardComIframe';
import LoginPage from '../UsersLogs/LoginPage';
import utilsCardCom from '../_Utils/utilsCardCom';
import utilsKopons from '../_Utils/utilsKopons';
import ConnUrls from '../Connections/ConnectURL';
import ReactHtmlParser from 'react-html-parser';
import PopUpCCTokensCharge from '../DTechComp/PopUpCCTokensCharge';
import Crdit_Token_Problem from '../DTechComp/Pop_Fixed/Crdit_Token_Problem';

var scroller = Scroll.scroller;

const TheOrderList = (props) => {



    const [master, setMaster] = useContext(MasterContext);
    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [mobiOrNot] = useContext(MobiOrNotContext);
    const [user, UserEffectFunction] = useContext(UserContext);
    const [thePopUp, setThePopUp] = useState("");
    const [yellowLineTxt, setYellowLineTxt] = useState("המשך לתשלום");
    const [payType, setPayType] = useState((order.payt != null) ? order.payt : "1");
    const [showContBtn, setShowContBtn] = useState(true);
    const [kopon, setKopon] = useState("")
    const [koponMsg, setKoponMsg] = useState("")
    const [approvedKoponMsg, setApprovedKoponMsg] = useState("")

    const history = useHistory();

    let popTiming = 0;

    useEffect(() => {

        props.shoTopStrp(false)
        window.scrollTo(0, 0);
        order["payt"] = (order.payt != null) ? order.payt : "1";

        //מנקה פריטים שמוגדרים ככמות 0
        order.Orders = order.Orders.filter(item => item.mount > 0);

        settingTheYellowline();
        setCurrPage();
    }, [])

    const goToBack = () => {
        history.goBack();

    }


    const setCurrPage = async () => {

        if (currentPage.length <= 0) {
            let restPage = await utilsRests.getRestById(order.restId);
            setCurrentPage({ ...restPage, pageTYp: "withConPay" });

            if (!MyDateTime.checkIfBetweenHours(restPage).openOrCLose) {

                popTiming++;

                setThePopUp(
                    <Closed_Buis okClick={() => setThePopUp()}
                        master={master}
                        btnClick2={() => history.push(`/restaurants/${restPage.pageUrlFix != null &&
                            restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id}`)}
                        errorMsg={""}
                    />
                )

            }

        }
    }

    useEffect(() => {

        settingTheYellowline();
        checkKoponPrice();
    }, [order])

    //כאשר מחובר, מגדיר את הטקסט על הכפתור הצבהוב בהתאם לאופי ההזמנה
    const settingTheYellowline = () => {
        //איסוף
        if (order.OrderType == "Take") {
            //מזומן או אשראי?
            //יש היפוך בגלל העברית אנגלית
            //1 == אשראי
            setYellowLineTxt((order.payt == "1") ? "המשך לתשלום" : "בצע הזמנה");
        }
        //משלוח
        else {

            setYellowLineTxt("המשך הזמנה");
        }

    }



    //הסתר או הצג כמות של מנה מסויימת
    const showHideMount = (elemId) => {

        let mountItm = document.getElementById(elemId);

        if (mountItm.classList.contains("slideMeUp")) {
            mountItm.classList.remove("slideMeUp");
        }
        else {
            mountItm.classList.add("slideMeUp");
        }
    }

    //הצג או הסתר הערות על המנה
    const showHideRemarks = (elemId) => {
        let Remarks = document.getElementById(`${elemId}_remarks`);
        let RemarksItm = document.getElementById(`${elemId}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${elemId}_remarkstxLine`);

        if (Remarks.classList.contains("slideMeUp")) {
            Remarks.classList.remove("slideMeUp");
            RemarksItmTxt.classList.add("slideMeUp");
        }
        else {
            Remarks.classList.add("slideMeUp");
            RemarksItmTxt.classList.remove("slideMeUp");
        }
    }



    //הצג או הסתר הערות על ההזמנה
    const showHideOrderRemarks = () => {

        let Remarks = document.getElementById(`orderRemarksDIv`);
        let RemarksItmTxt = document.getElementById(`orderRemarksTxt`);

        if (Remarks.classList.contains("slideMeUp")) {
            Remarks.classList.remove("slideMeUp");
            RemarksItmTxt.classList.add("slideMeUp");
        }
        else {
            Remarks.classList.add("slideMeUp");
            RemarksItmTxt.classList.remove("slideMeUp");
        }
    }

    //הגדר את הטקסט על הערות להזמנה
    const setTheOrderRemarks = () => {

        let RemarksItmTxt = document.getElementById(`orderRemarksTxt`);

        let RemarksItmTxtLine = document.getElementById(`orderRemarksTxtLine`);
        RemarksItmTxtLine.textContent = RemarksItmTxt.value;

        order.orderRemarks = "<p>" + RemarksItmTxt.value + "</p>";


        setOrder({ ...order });

        showHideOrderRemarks();
    }
    //הגדר את הטקסט על הערות המנה
    const setTheTxtRem = (index) => {

        let RemarksItm = document.getElementById(`${index}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${index}_remarkstxLine`);
        RemarksItmTxt.textContent = RemarksItm.value;
        order.Orders[index].Remarks = RemarksItm.value;
        setOrder({ ...order });

        showHideRemarks(`${index}`);
    }

    //המפה המוצגת
    let googleMap =
        <>
            <div className={"RestGoogleMap"}>
                <GoogleMapItem mapLink={order.GoogleLink} />
            </div>
        </>


    let orderOrTake = <>
        {
            (!mobiOrNot) ?
                <div className={"bold anak centerText"}>{currentPage.buissName}</div> : ""
        }
        {
            (order.OrderType == "Take") ?
                OrderFunctions.takeAwayBlock(`ההזמנה מוכנה בעוד
                ${parseInt(order.OrderTimes) + 5} - ${order.OrderTimes} דק'`, order.Address)
                :
                OrderFunctions.deliveryBlock(`ההזמנה תגיע בעוד 
                ${parseInt(order.OrderTimes) + 10} - ${order.OrderTimes} דק'`, order.OrderDelPrice)
        }
    </>


    const connect = () => {

        setThePopUp(
            <PopUpClearComp
                popFullComp={"specialPopComp onTopOfAll"}
                cssStyl={"noPadding InbgLGray"}
                btnFStyle={"fatInBtn"}
                closePop={() => setThePopUp()}
                insideCss={"noPadding bgLGray"}
                theInsideComp={
                    <LoginPage
                        theInsideComp={"bgLGray"}
                        backFunc={true}
                        closeMeFuc={() => setThePopUp()}
                        shoTopStrp={(tf) => { }}
                        scrollToTop={(stUp) => { }} />
                } />)


        // sessionStorage.setItem('bkPage', window.location.pathname);
        // history.push("/LoginPage")
    }

    const register = () => {
        setThePopUp(
            <PopUpClearComp BtnTxt={"סגור"}
                btnFStyle={"fatInBtn"}
                closePop={() => setThePopUp()}
                theInsideComp={
                    <UserDetails theInsideComp={""}
                        backFunc={true}
                        closeMeFuc={() => setThePopUp()}
                        shoTopStrp={(tf) => { }}
                        scrollToTop={(stUp) => { }}
                        connectWithNumber={() => { setThePopUp(); connect() }}
                    />
                } />)


    }

    //מושך מספר הזמנה להזמנה החדשה
    const register_theOrder = async () => {

        order.totalPrice = OrderFunctions.getOrderTotalPrice(order);
        order.orderNUM = order.orderNUM || await utilsOrders.getCounter();

    }


    //יוצר את ההזמנה במערכת וממשיך להמשך לתשלום, איסוף או משלוח?
    const getAddressOrPay = async () => {

        let tempo = await register_theOrder();

        if (order.OrderType == "Take") {
            setToTake();
        }
        else {
            setToDeliver();
        }

    }


    //המשך הזמנה כמשלוח
    const setToDeliver = async () => {

        //בודק אם מוגדר מינימום מחיר למשלוח
        //במידה וכן עובד לפי המחיר המוגדר
        //במידה ולא, עובד לפי המינימום המוגדר במאסטר
        let mindelP = (currentPage.Delivery.MiniDelPrice != null &&
            currentPage.Delivery.MiniDelPrice != "") ?
            currentPage.Delivery.MiniDelPrice : master.MiniDelPrice;

        //רק במידה ומחיר המינימום למשלוח הושג.
        if (parseInt(OrderFunctions.getOrderPrice(order)) >= parseInt(mindelP)) {

            popTiming++;
            setThePopUp(<div key={popTiming} id={"addressBox"} className={"addressBox"}>
                <UserAdresses noHead={true} closeMe={() => setThePopUp()} addId={"SelectAdd"}
                    setTheAddress={(theSele) => setTheAddress(theSele)}
                    deliveryCitys={currentPage.deliveryCitys}
                    GoTOPay={() => payForDelivery()} /></div>)

            setTimeout(function () {
                scroller.scrollTo(`addressBox`, { smooth: true, offset: 0, duration: 1000 });
            }, 20)

        }
        else {

            popTiming++;
            setThePopUp(
                <Minimum_Order
                    okClick={() => {
                        setThePopUp(); backToRestMenu();
                    }}
                    mindelP={mindelP}
                    btnClick2={() => { setThePopUp(); }}
                />
            )
        }

    }


    const backToRestMenu = () => {
        history.push(`/restaurants/${currentPage.pageUrlFix != null && currentPage.pageUrlFix != "" ?
            currentPage.pageUrlFix : currentPage._id}`)
    }

    const payForDelivery = () => {
        getMoney();
    }



    const getMoney = async () => {

        // console.log(order);
        // console.log(orderMyFood.ordr);

        setThePopUp(<div className={"onTopOfScreen"}><MySpinner_Wait /></div>);

        if (order.payt != "2") {

            let orderMyFood = await creatTheorder();

            await creditCardCharge(orderMyFood);

        }
        else {

            let orderMyFood = await creatTheorder();
            popApprovedOrder(orderMyFood)
        }
    }

    let crditProblem = () => {
        return (<Crdit_Problem
            okClick={() => {
                paytypChanched(2);
                order.payt = "2";
                // getMoney();

                setThePopUp();
                // popApprovedOrder();
            }}
            closePop={() => setThePopUp()} />
        )
    }
    let crditTokenProblem = () => {
        return (<Crdit_Token_Problem
            okClick={() => {
                paytypChanched(2);
                order.payt = "2";
                // getMoney();

                setThePopUp();
                // popApprovedOrder();
            }}
            closePop={() => setThePopUp()} />
        )
    }

    const creditCardCharge = async (orderMyFood) => {

        let checkForUserTokensCC = await utilsUsers.checkForUserTokensCC(user._id);
        if (checkForUserTokensCC) {
            setThePopUp(

                <PopUpCCTokensCharge
                    CCtokens={checkForUserTokensCC}
                    removeToken={(tkToremove) => removeToken(tkToremove, orderMyFood)}
                    okClick={(ValueBk) => payByToken(ValueBk, orderMyFood)}
                    btnClick2={() => { newFramCardCom(orderMyFood); setThePopUp() }}
                    handleClose={() => setThePopUp()}
                    cssStyl={"ccTknPopCard centerPop"}
                />)
        }
        else {
            newFramCardCom(orderMyFood);
        }

    }


    const newFramCardCom = async (orderMyFood) => {
        let cardComBk = await utilsCardCom.goCardCom({ order: orderMyFood.ordr });
        if (cardComBk.data != 'ERROR') {
            // if (cardComBk.success) {

            setThePopUp(<PopUpCardComIframe
                cssStyl={""}
                // ContentText={["ביטלת את התשלום?",
                //     "אם לא, משהו כנראה השתבש.",
                //     "ניתן לנסות שוב,",
                //     "ובתור תחלופה, ניתן להמשיך עם ההזמנה",
                //     "ולבצע תשלום במזומן"]}
                ContentText={[]}
                theData={cardComBk.Url}
                BtnTxt={"אשלם במזומן"}
                BtnTxt2={"אנסה שוב"}
                cancelClick={() => setThePopUp()}
                okClick={() => {
                    paytypChanched(2);
                    order.payt = "2";
                    // getMoney();
                    setThePopUp();
                }}
                btnClick2={() => {
                    setThePopUp();

                }}


            />)

        }
        else {
            setThePopUp(crditProblem())
        }
    }


    const payByToken = async (theTokeparams, orderMyFood) => {

        let chargeTokback = await utilsCardCom.cardChargeToken({ order: orderMyFood.ordr, theTokeparams: theTokeparams });
        if (chargeTokback && chargeTokback !== "Token chargeError" && chargeTokback !== "NO PERMISS") {
            popApprovedOrder(chargeTokback)
        }
        else { setThePopUp(crditTokenProblem()) }

    }

    const removeToken = async (tkToremove, orderMyFood) => {

        let bk = await utilsUsers.removeCCTok(user._id, tkToremove);
        await creditCardCharge(orderMyFood)
    }


    //יוצר הזמנה חדשה
    const creatTheorder = async () => {

        order.totalPrice = OrderFunctions.getOrderTotalPrice(order);

        //מנקה פריטים שמוגדרים ככמות 0
        order.Orders = order.Orders.filter(item => item.mount > 0);

        let theNorder = await utilsOrders.orderMyFood({ order, user });

        let temp = { ...order, orderId: theNorder.orderId, orderNUM: theNorder.orderNumber }
        setOrder(temp)

        return theNorder;

    }


    //מאשר הזמנה חדשה
    const popApprovedOrder = async (theOrder) => {

        let tempOrdType = theOrder.ordr ? theOrder.ordr.OrderType : theOrder.OrderType;
        if (tempOrdType == "Take") {
            popTiming++;
            setThePopUp(
                <TAKE_Confirmd order={theOrder} key={popTiming}
                    okClick={() => { OrderFunctions.clearOrd(); setThePopUp(); history.push("/MyOrders") }}
                />
            )

        }
        else {
            popTiming++;
            setThePopUp(
                <Delivery_Confirmd order={theOrder} key={popTiming}
                    okClick={() => { OrderFunctions.clearOrd(); setThePopUp(); history.push("/MyOrders") }}
                />
            )
        }
    }


    const setTheAddress = (theSele) => {


        order.deliveryAddress = theSele;

        //מכניס את הכתובת הרצוייה
        order["deliveryAddress"] = theSele;


    }


    //המשך הזמנה כאיסוף
    const setToTake = async () => {


        if (order.payt == "2") {

            let orderMyFood = await creatTheorder();
            popApprovedOrder(orderMyFood);
        }
        else {

            getMoney();
        }
    }



    ///הערות הזמנה
    let ordRemarks = <>
        <div className={"ordItmLine remarksLine pointing"} onClick={() => showHideOrderRemarks()}>
            <span className={"rSideFirst"}><Subject /></span>
            <span className={"bold rSideMiddle big"}>הערות נוספות להזמנה</span>
            <span className={"bold lSideSmall"}></span>
        </div>
        <div className={"ordItmLine remarksTxtLine"}>
            <span className={"rSideFirst"}></span>
            <span className={"rSideMiddle bold big"} id={`orderRemarksTxtLine`}></span>
            <span className={"lSideSmall"}></span>
        </div>
        <div id={`orderRemarksDIv`} className={"ordItmLine itemRemraks spacePadBig slideMeUp"}>
            <textarea id={`orderRemarksTxt`} maxLength='250' className={"remarksTxt"}
                placeholder={"תרצה לציין עוד משהו? אולי קוד כניסה? או שיש כלב בחצר?"}>
            </textarea>
            <div className={"ButtonMe2"} onClick={() => setTheOrderRemarks()}>שמור</div>
        </div>
    </>

    ///המוצרים בהזמנה
    let ordersItems = order.Orders.map((item, index) => {
        if (item.mount > 0) {
            return (

                <div key={index} className={"ordItem spaceButtomBig "}>

                    <div className={"ordItmLine"}>
                        <Checkbox
                            onChange={() => showHideMount(index)}
                            icon={<Edit sx={{ color: 'black' }} />}
                            checkedIcon={<EditOff sx={{ color: 'black' }} />}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                            className={"chkBxChange rSideFirst"}
                        />
                        <span className={"bold rSideMiddle"} >
                            <table>
                                <tbody>
                                    <tr>
                                        <td className={"tdmin10"}>
                                            <span className={"engFont ordItemMount"}>X&nbsp;</span>
                                            <span className={"ordItemMount mainMount"}>{item.mount}&nbsp;</span>
                                        </td>
                                        <td>
                                            <span className={"ordItemMount"}> {item.productName}</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>


                        </span>
                        <span className={"bold lSideSmall"}>₪ {parseFloat(item.prodPrice) * parseInt(item.mount)}</span>
                    </div>

                    {/* edit mount */}
                    <div id={index} className={"ordItmLine itemMount spacePadBig slideMeUp"}>
                        <span className={"bold itemMountRI"}>כמות:</span>
                        <span className={"bold itemMountLf"}>
                            <TheMount itemId={item.orderItemId} remFromOrder={() => { }} />
                        </span>
                    </div>

                    {OrderFunctions.theItemSpecials(item)}

                    <div className={"ordItmLine remarksLine"} onClick={() => showHideRemarks(index)}>
                        <span className={"rSideFirst"}><Subject /></span>
                        <span className={"bold rSideMiddle"}>הערות למטבח</span>
                        <span className={"bold lSideSmall"}></span>
                    </div>
                    <div className={"ordItmLine remarksTxtLine"}>
                        <span className={"rSideFirst"}></span>
                        <span className={"rSideMiddle"}
                            id={`${index}_remarkstxLine`}></span>
                        <span className={"lSideSmall"}></span>
                    </div>
                    <div id={`${index}_remarks`} className={"ordItmLine itemRemraks spacePadBig slideMeUp"}>
                        <textarea id={`${index}_remarksTxt`} maxLength='250' className={"remarksTxt"}
                            placeholder={"ספר לנו איך אתה אוהב את האוכל, יש בעיה עם אלרגנים?"}>
                        </textarea>
                        <div className={"ButtonMe2"} onClick={() => setTheTxtRem(index)}>שמור</div>
                    </div>


                </div>


            )
        }
    })


    //רטבים מוצמדים מוזמנים
    let stickedSauces =
        order.StickedSauces?.length > 0 ?
            <div className={"spaceButtomBig ordLstSpesBlk "}>
                <div className={"smallIconWrap"}>
                    <img src={"/PermanentImages/suse-icon.png"} className={"smallIcon"} alt="" />
                </div>
                <span className={"bold fullRow "}>רטבים מוצמדים</span>
                <span className={"veryVerySmall"}>
                    &nbsp;{(order.freeSaucess > 0) ? `(${order.freeSaucess} ללא עלות)` : ""}
                </span>
                <span className={"bold lSideSmall"}>
                    {OrderFunctions.getStickedSauces(order) > 0 ? OrderFunctions.getStickedSauces(order) + "  ₪" : ""}
                </span>

                {
                    order.StickedSauces?.map((sauce, indx) => {
                        return (
                            <div key={indx} className={"sucItem"}>

                                <span className={"rSideMiddle"}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={"tdmin10"}>
                                                    <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>
                                                        {sauce.mount}&nbsp;</span>
                                                </td>
                                                <td>
                                                    <span className={"ordItemMount"}> {sauce.servType}</span>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        )
                    })
                }
            </div>
            : ""



    //מספר סועדים
    let Dinners =
        // (currentPage.chkForkNife || currentPage.chkChopStiks) ?
        (order.Dinners && order.Dinners > 0) ?
            <div className={"sucItem spacePadSmall"} >
                <span className={"rSideMiddle"}>
                    <span className={"bold"}>מספר סועדים: </span>
                    <span>{order.Dinners}</span>
                </span>
            </div>
            : "";

    // : "";

    //סכו"ם
    let ANife =
        (currentPage.chkForkNife) ?
            <div className={"sucItem spacePadSmall"}>
                <span className={"rSideMiddle"}><span className={"bold"}>סכו"ם חד פעמי: </span><span>{order.getfANife ? "כן" : "אין צורך "}</span></span>
            </div>
            : "";

    //צופסטיקס
    let chupstiks =
        (currentPage.chkChopStiks) ?
            <div className={"sucItem spacePadSmall"}>
                <span className={"rSideMiddle"}><span className={"bold"}>צ'ופסטיקס: </span>
                    <span>{order.getChupstiks ? "כן" : "אין צורך "}</span></span>
            </div>
            : "";


    //אופן תשלום
    let creditCard =
        <div className={`${(!mobiOrNot) ? "onRight" : ""} col-md-6 mb-2 startNBlock`}>
            <FormControl component="fieldset">
                <div className={"veryBig bold spaceRightVeryLong"}>אופן תשלום</div>
                <RadioGroup row
                    aria-label="אופן תשלום"
                    value={payType}
                    name="payt"
                    onChange={(e) => paytypChanched(e.target.value)}
                >
                    {(!currentPage.paymentOptions || currentPage.paymentOptions === "creditAndCash") && (
                        <div>
                            <FormControlLabel
                                value="1"
                                className="blackRadiolbl"
                                control={<Radio className="blackRadio" />}
                                label="אשראי"
                            />

                            <FormControlLabel
                                value="2"
                                className="blackRadiolbl"
                                control={<Radio className="blackRadio" />}
                                label="מזומן"
                            />
                        </div>
                    )}

                    {currentPage.paymentOptions === "onlyCredit" && (
                        <FormControlLabel
                            value="1"
                            className="blackRadiolbl"
                            control={<Radio className="blackRadio" />}
                            label="אשראי"
                        />
                    )}

                    {currentPage.paymentOptions === "onlyCash" && (
                        <FormControlLabel
                            value="2"
                            className="blackRadiolbl"
                            control={<Radio className="blackRadio" />}
                            label="מזומן"
                        />
                    )}

                </RadioGroup>
            </FormControl>

        </div>

    //הפעלת קופון
    const IhaveKopon =
        <div className={`${(!mobiOrNot) ? "onLeft" : ""} col-md-6 mb-2 startNBlock spaceButtom`}>
            <div className={"veryBig bold spaceRightVeryLong"}>יש לי קופון
                <span className={"spaceRightLongi vSmall specDotClose"}>
                    {order.orderKopon && order.orderKopon != "" ?
                        `הקופון הופעל, ${order.orderKopon.KoponDiscount}${order.orderKopon.KoponDiscType === '1' ? "₪" : "%"} הנחה`
                        : koponMsg}
                </span>
            </div>
            {(order.orderKopon && order.orderKopon != "") ? "" :
                <>
                    <input className={"roundedInputTxt spaceMargRightVeryLong "}
                        type="text" id={"kopoInput"} placeholder={"הזן קופון"} value={kopon}
                        onChange={(e) => { setKopon(e.target.value) }}
                        onKeyUp={(e) => checkIfEnterOnKop(e)} />
                    <div className={"goKoponBtn centerText pointing"}
                        onClick={() => koponCheck()} >
                        <span>הפעל</span>
                    </div>
                </>
            }
        </div>

    //בודק תקינות קופון
    const koponCheck = async () => {

        if (kopon == "") {
            setKoponMsg("לא הוזן קופון")
        } else if (kopon.length < 6) {
            setKoponMsg("קופון לא תקין")
        }
        else {

            let koponResault = await utilsKopons.chekckKopon(kopon, order);
            if (koponResault.errMsg !== null) {
                if (koponResault.errMsg === 'הקופון הופעל') {

                    let tempoOrd = koponResault.order;
                    setOrder(tempoOrd);


                }
                else { setKoponMsg(koponResault.errMsg) }

            }
        }
    }

    //בודק אם יש קופון פעיל בהזמנה, במידה ויש,
    // בודק מחיר המנימום שלו לא גבוה יותר ממחיר הסה"כ
    const checkKoponPrice = () => {

        if (order.orderKopon && order.orderKopon !== "") {

            let kop = order.orderKopon;
            let theTot = OrderFunctions.getOrderTotalPrice(order) + (parseInt(kop.prodPrice) * -1);
            if (theTot < kop.MinmumForOrder) {
                // let tempOrd = [...order];               
                setKoponMsg("הקופון דורש מינימום הזמנה של " + kop.MinmumForOrder + " ₪")
                setOrder({ ...order })
            }
        }
    }

    const checkIfEnterOnKop = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            koponCheck();
        }
    }

    //אופן התשלום השתנה ופה מעדכנים את ההזמנה
    const paytypChanched = (ptyp) => {
        setPayType(ptyp);
        let ordTemp = { ...order };
        ordTemp.payt = ptyp;
        setOrder({ ...ordTemp });

    }

    //סיכום מחירים
    let TotPrices =
        <>
            <div className={"veryBig bold spacePad startNBlock centerText"}>כל המחירים בשקלים וכוללים מע"מ</div>
            <div className={"ordItmLine totalTxt"}>
                <div className={"bold totalTxtR"}>סכום ההזמנה</div>
                <div className={"bold totalTxtL"}>{OrderFunctions.getOrderPrice(order)} ₪ </div>
            </div>

            {(order.OrderType == "Deliver") ?
                <div className={"ordItmLine totalTxt"}>
                    <div className={"bold totalTxtR"}>משלוח</div>
                    <div className={"bold totalTxtL"}>{order.OrderDelPrice} ₪ </div>
                </div> : ""}

            {(order.orderKopon && order.orderKopon !== "") &&
                <div className={"ordItmLine totalTxt"}>
                    <div className={"bold totalTxtR"}>קופון {order.orderKopon.Name}</div>
                    <div className={"bold totalTxtL"}>{order.orderKopon.KoponDiscount}{order.orderKopon.KoponDiscType === '1' ? " ₪" : " %"}</div>
                </div>}

            <div className={"ordItmLine totalTxt"}>
                <div className={"bold totalTxtR"}>סה"כ</div>
                <div className={"bold totalTxtL"}>{OrderFunctions.getOrderTotalPrice(order)} ₪ </div>
            </div>
        </>

    // הרשמה/התחברות/ המשך לתשלום
    let QuickReg =
        <>
            {(user?._id == null) ?
                <div className={"startNBlock"}>

                    <div className="ContinueToPayLine" onClick={() => connect()}>
                        <div className={"getToKnowBtn bold big centerText"}>התחבר</div>
                    </div>

                    <div className={"getToKnowTxt"}>משתמש רשום? התחבר בSMS</div>
                    <br />

                    <div className={"ContinueToPayLine"} onClick={() => register()}>
                        <div className={"getToKnowBtn bold big centerText"}>הרשם</div>
                    </div>
                    <br />

                    <div className={"getToKnowTxt"}>בוא נכיר אותך קצת - הנתונים ישמרו בקניה הבאה</div>

                </div>
                :
                <div className={`ContinueToPayLine ${(showContBtn) ? "" : "hideMe"} `} onClick={() => getAddressOrPay()}>
                    <div className={"getToKnowBtn bold big centerText"}>{yellowLineTxt}</div>
                </div>
            }

        </>


    let totalDinners =
        (Dinners != "" || ANife != "" || chupstiks != "") ?
            <div className={"ordItmLine spaceAndLine"}>
                <span className={"rSideFirst"}><LocalDining /></span>
                <span className={"rSideMiddle"}>
                    {Dinners}
                    {ANife}
                    {chupstiks}
                </span>
                <span className={"bold lSideSmall"}></span>
            </div>
            : ""

    return (
        <>

            {order.Orders.length <= 0 ? <div className={"noItmes huge centerText"}>אין פריטים להזמנה.</div> :

                <div className={"TheOrderList"}>
                    {(mobiOrNot) ?
                        <div className={"headOnFixC SecondFont bold big"}>
                            {currentPage.buissName}
                            <div className={`backSd`}>
                                <ArrowCircleRightSharp onClick={() => goToBack()} />
                            </div>
                        </div>
                        : ""}

                    {googleMap}
                    <div className={"TheOrderListIn ordLstWrap"}>
                        {
                            <div className={"backToRestMenu centerText pointing"}
                                onClick={() => backToRestMenu()} >
                                <span>חזור לתפריט</span>
                            </div>
                        }
                        {/* <div className={"veryBig bold spaceRightVeryLong"}>סוג ההזמנה</div> */}
                        {orderOrTake}
                        <div className={"veryBig bold spaceRightVeryLong"}>ההזמנה שלך</div>
                        {ordersItems}
                        {stickedSauces}
                        {totalDinners}
                        {ordRemarks}

                        <div className={"col-md-12 mb-2 fullRow overflowHid"}>
                            {creditCard}
                            {IhaveKopon}
                        </div>
                        {TotPrices}
                        <br />
                        {QuickReg}
                    </div>

                    {/* <ContinueToPay theTxt={"המשך לתשלום"} nxUrl="" withPrice={true} /> */}
                    {/* <ContinueToPay theTxt={"המשך לתשלום"} withPrice={true} /> */}
                    {thePopUp}
                </div>
            }


            <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/MyOrder`
                    }
                ]}>
                <link rel="canonical" href={`${ConnUrls.siteDomainNam}/MyOrder`} />
            </Helmet>


        </>

    )


}
export default TheOrderList;

