import homeP from "../_ApiConn/apiHomePage";

const getHomePage = async () => {
    let dFromF = await homeP.getHomePage();
    return (dFromF.data[0]);
}

export default {
    getHomePage

};