import React, { useState, useEffect } from 'react';
import { format, isBefore, subDays, startOfMonth, isSameDay } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import heLocale from 'date-fns/locale/he';
import 'react-day-picker/dist/style.css';
import MyDateTime from '../../../DTechComp/MyDateTime';

const DatePick2 = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);
  const [displayedMonth, setDisplayedMonth] = useState(startOfMonth(selectedDate));
  const unavailabledays = props.noDatesAvailable; // Replace with your actual array

  useEffect(() => {
    setSelectedDate(props.selectedDate);
    setDisplayedMonth(startOfMonth(props.selectedDate));
  }, [props.selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.settingFunc(date);
  };

  const handleMonthChange = (date) => {
    setDisplayedMonth(startOfMonth(date));
  };

  const isDayDisabled = (day) => {
    // Disable days before yesterday
    if (isBefore(day, subDays(new Date(), 1))) {
      return true;
    }

    // Disable days in the unavailabledays array
    if (unavailabledays.includes(MyDateTime.getDayOfWeekByDayNum(day.getDay() + 1))) {
      return true;
    }

    return false;
  };


  return (
    <div className='inPanelRes'>
      <label style={{ color: "black" }}>בחרו תאריך</label>
      <div className={'mydtPicker'}>
        <DayPicker
          mode="single"
          dir="rtl"
          locale={heLocale}
          selected={selectedDate}
          modifiers={{ disabled: isDayDisabled }}
          onDayClick={(day, { disabled }) => !disabled && handleDateChange(day)}
          month={displayedMonth}
          onMonthChange={(date) => handleMonthChange(date)}
        />
      </div>
    </div>
  );
};

export default DatePick2;
