


const WazeItem = (props) => {


    const getAttrs = (iframeTag) => {

        var doc = document.createElement('div');
        doc.innerHTML = iframeTag;

        const iframe = doc.getElementsByTagName('iframe')[0];
        return [].slice
            .call(iframe.attributes)
            .reduce((attrs, element) => {
                attrs[element.name] = element.value;
                return attrs;
            }, {});

    }

    let theSrc = props.theIframe ? getAttrs(props.theIframe)["src"] : "";
    let lat = "";
    let lon = "";

    if (theSrc != "") {
        let splt = theSrc.split('&');
        splt.forEach(element => {
            if (element.startsWith("lat")) {
                lat = element.split('=')[1];
            }
            else if (element.startsWith("lon")) {
                lon = element.split('=')[1];
            }
        });
    }

    let ll = lat + "," + lon

    let spl = props.TheUrl.split(' ');
    return (
        <>
            <a href={`https://waze.com/ul?q=${spl}&ll=${ll}&navigate=yes`} >
                <img className={"wazeImg showOnLeft"} src="/PermanentImages/waze.png" alt=""/></a>
        </>
    )

}
export default WazeItem;

