import React, { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MasterContext, MessagesObjCssContext, MobiOrNotContext } from '../ContextApi/context';
import { ArrowDropDown, Close } from '@mui/icons-material';

const SiteMessagesItms = (props) => {

    const [master] = useContext(MasterContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [messagesObjCss, setMessagesObjCss, goSetTheMsgDisp, siteMessages, setSiteMessages] = useContext(MessagesObjCssContext);
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        goSetTheMsgDisp();

    }, [])

    const handleClose = () => {
        setMessagesObjCss("")
    };

    return (
        (siteMessages) &&
        <>
            {
                (siteMessages[0]?.active) &&
                <div className={`MessagesObj onTopOfAll ${messagesObjCss}`}>
                    <div className={"closeItMsg"} onClick={() => handleClose()}>
                        {!mobiOrNot ?
                            <Close className={"closeItMsgRot"} sx={{ fontSize: "20px" }} />
                            :
                            <ArrowDropDown className={"closeItMsgRot"} sx={{ fontSize: "30px" }} />
                        }

                    </div>
                    <div className={"MessagesObjTxt"}>
                        {parse(siteMessages[0].content)}
                    </div>
                </div>
            }

        </>
    )
}

export default SiteMessagesItms;