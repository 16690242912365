import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CurrentPContext, MasterContext, MobiOrNotContext } from '../ContextApi/context';
import utilsCategurys from '../_Utils/utilsCategurys';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ConnUrls from '../Connections/ConnectURL';
import MyFunctions from '../myFunctions';

const CatsLnksComp = () => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);


  const [categurs, setCategurs] = useState([]);
  const [swips, setSwips] = useState();

  useEffect(() => {

    loadCategurys();

  }, []);

  const loadCategurys = async () => {

    let cats = await utilsCategurys.getAllCategurys();

    cats = MyFunctions.sortAsc(cats, "catPosition");

    setCategurs(cats);

  }



  return (
    <div className={"FourMainLnksDiv"}>

      <Swiper
        spaceBetween={mobiOrNot ? 4 : 8}
        slidesPerView={mobiOrNot ? 4 : 8}
        loop={false}
        // onSlideChange={() => console.log('slide change')}
        freeMode={true}
        height={mobiOrNot ? 500 : 1000}
        className={"catsSwiperDesktop"}
      // onSwiper={(swiper) => console.log(swiper)}
      >

        {
          categurs.map((item, index) => {
            return (
              <SwiperSlide key={index} className={mobiOrNot ? "catMasterBtn" : "NotcatMasterBtn"}>
                <Link to={`/${item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id}`}>
                  <div className={"catBtnMasterWrap animate__animated pulseME"}>
                    <img className={"prodImage"} src={`${ConnUrls.servMediasUrl}${item.catImage}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                  </div>
                  <div className={"fourMastBtn pointing"} >
                    {item.catHead}
                  </div>
                </Link>
              </SwiperSlide>
            )

          })
        }
        {
          <SwiperSlide key={categurs.length + 1} className={mobiOrNot ? "catMasterBtn spacerMastBtn noBorder" : "NotcatMasterBtn noBorder"}>
            <Link to={""}>
              <div className={"catBtnMasterWrap "}>
              </div>
              <div className={"fourMastBtn pointing"} >
                { }
              </div>
            </Link>
          </SwiperSlide>
        }
        {
          <SwiperSlide key={categurs.length + 2} className={mobiOrNot ? "catMasterBtn spacerMastBtn noBorder" : "NotcatMasterBtn noBorder"}>
            <Link to={""}>
              <div className={"catBtnMasterWrap"}>
              </div>
              <div className={"fourMastBtn pointing"} >
                { }
              </div>
            </Link>
          </SwiperSlide>
        }
      </Swiper>


    </div>

  )

}

export default CatsLnksComp;



