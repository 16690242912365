

import { WindowSharp } from '@mui/icons-material';
import Scroll from 'react-scroll';
var scroller = Scroll.scroller;


const ScrollMe = (idto) => {
  scroller.scrollTo(`${idto}`, { smooth: true, offset: -50, duration: 500 });
};
const ScrollMePlus = (idto, plusPx) => {
  scroller.scrollTo(`${idto}`, { smooth: true, offset: -50 + plusPx, duration: 500 });
};


export default {
  ScrollMe,
  ScrollMePlus
};
