import { useContext, useEffect, useRef, useState } from "react";
import { OrderContext } from "../ContextApi/context";

const TheDInners = (props) => {

    const [order, setOrder] = useContext(OrderContext);

    const [currentMount, setCurrentMount] = useState(1);

    useEffect(() => {
        if (isNaN(order.Dinners)) {
            order.Dinners = 1;
            setCurrentMount(1);

        }
        else {
            setCurrentMount(order.Dinners);
        }

    }, [order])


    const addToMount = () => {
        order.Dinners = parseInt(order.Dinners) + 1;
        setOrder({ ...order });
    }
    const lessToMount = () => {

        order.Dinners = parseInt(order.Dinners) - 1;
        if (order.Dinners > 0)
            setOrder({ ...order });
           }


    return (
        <div className={"prodPlusMinus theDinersPlusMinus"}>
            {/* <div className={"mountLn"}>כמות</div> */}
            <div className={"plusBtn"} onClick={() => addToMount()}>+</div>
            <div className={"mountNum"}>
                {currentMount}
            </div>
            <div className={"minusBtn"} onClick={() => lessToMount()}>-</div>
        </div>
    );
}

export default TheDInners;
