
import { useContext, useEffect, useState } from 'react';
import { CurrentPContext, OrderContext } from '../../ContextApi/context';
import utilsCategurys from '../../_Utils/utilsCategurys';
import CategurysList from './CategurysList';
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { ArrowCircleDownSharp, ArrowBackIosNew, ArrowCircleUp, ArrowCircleRightSharp, FiberManualRecord, DirectionsBike } from '@mui/icons-material';
import OrderFunctions from '../../OrderSettings/OrderFunctions';
import Scroll from 'react-scroll';
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import MyDateTime from '../../DTechComp/MyDateTime';
import ConnUrls from '../../Connections/ConnectURL';
import utilsSpecialPages from '../../_Utils/utilsSpecialPages';
import { Helmet } from 'react-helmet';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const AllCategurysPage = (props) => {
 

  const [order, setOrder] = useContext(OrderContext);

  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [allCats, setAllCats] = useState([]);

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  useEffect(() => {

    getCurpage();
    //מנקה את ההזמנה 
    setOrder(OrderFunctions.clearOrd());

    props.shoTopStrp(false)
    getCatPage();
    setLoading(false);
  }, [])


  const getCurpage = async () => {
    setCurrentPage(await utilsSpecialPages.getSpecialPageByName("קטגוריות"))
  }


  const getCatPage = async () => {
    let allCatsTemp = await utilsCategurys.getAllCategurys();
    setAllCats(allCatsTemp)

  }
  const goToBack = () => {
    history.goBack();

    // if (document.referrer.indexOf(window.location.host) === -1) {
    // }
    // else {
    //      history.push('/'); }
  }


  return (
    <div className="CatsPage">
      {loading ? "" :
        <>
          <div className={"headOnFixC SecondFont bold big"}>
            {"קטגוריות"}
            <div className={`backSd`}>
              <ArrowCircleRightSharp onClick={() => goToBack()} />
            </div>
          </div>
          <div className={"catsPagelist"}>
            {
              allCats.map((item, index) => {

                // catHead: textBack, catImage: "", catText: ""

                if (item != null) {
                  return (
                    <div key={index} className={"favBtn catsListItem catBtnMasterWrap animate__animated pulseME"}>
                      <Link to={`/${item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id}`}>
                        <div className={"imgWrap"}>
                          <div className={"imgDiv"} style={{ backgroundImage: `url("${ConnUrls.servMediasUrl}${item.catImage}")` }} >

                          </div>
                          <div className={"botBlock"}>
                            <div className={"pgBigTxt bold regular"}>
                              {/* <span className={`specDot ${!opHours.openOrCLose ? "specDotClose" : ""}`}><FiberManualRecord /></span> */}
                              {item.catHead}
                            </div>
                            {/* <div className={"pgSmallTxt mediumst"}>
                              {item.buissKitchType}
                            </div>

                            <div className={"pgSmallTxt mediumst"}>
                              <div className={"theImgwrap"} >
                                <div className={"theImg"} style={{ backgroundImage: `url(${ConnUrls.servMediasUrl}PermanentImages/tustus.png")` }}></div>
                              </div>



                              <div className={"timeTxt"}>
                                <div className={"theImg fullWidth"} >
                                  <div className={"onRight"}>
                                    <DirectionsBike />
                                    &nbsp;&nbsp;{priceing} ₪  &nbsp;&nbsp;
                                  </div>

                                  <div className={"onLeft"}>

                                    {(parseInt(timing) + 10) +
                                      " - " + parseInt(timing)} דק'
                                  </div>
                                </div>
                              </div> 
                            </div>
                            */}


                          </div>
                        </div>


                      </Link>
                    </div>
                  )
                }
              })
            }
          </div>
        </>

      }
       <Helmet
                meta={[
                    {
                        property: "og:url", content: `${ConnUrls.siteDomainNam}/mbcats`
                    }
                ]}>
        <link rel="canonical" href={`${ConnUrls.siteDomainNam}/mbcats`} />
      </Helmet>
    </div>
  );
}

export default AllCategurysPage;
