import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TheOrderList from '../OrderSettings/TheOrderList';
import Scroll from 'react-scroll';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const PopUpShopCart = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);



    // const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    // const saveMe = () => {

    //     if (inputTxt != "") {
    //         props.okClick(inputTxt);
    //     }
    //     else {
    //         setErr(true);
    //     }
    // };


    const handleClose = () => {
        props.cancelClick();
    };


    const scrolUp = (scInt) => {
        scroll.scrollToTop(scInt);
    }

    return (
        <Dialog open={open} onClose={handleClose} className={"textSizeDiag popDiagBlock"}>
            <DialogContent>
                <TheOrderList isPopUp={true} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </DialogContent>
            <DialogActions >
                {/* <button type="button" className="centerMe btn btn-success btnPopRegular" onClick={() => saveMe()}>אימות</button> */}
            </DialogActions>
        </Dialog>

    );
}

export default PopUpShopCart;