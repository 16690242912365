import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUpPhoneConnect = (props) => {
    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState(props.showError ? true : false);
    const [erMsg, setErMsg] = useState(props.errorMsg);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        setInputTxt(numericValue);
    };

    const isPhoneNumber = (phoneNum) => {
        const phoneRegex = /^\d{9,10}$/;
        return phoneRegex.test(phoneNum);
    };

    const saveMe = () => {
        const clearPhone = inputTxt.replace('-', '');
        if (!isPhoneNumber(clearPhone)) {
            setErr(true);
            setErMsg("יש להזין מספר טלפון תקין");
        } else {
            props.okClick(clearPhone);
            handleClose();
        }
    };

    const handleClose = () => {
        props.cancelClick();
    };

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            saveMe();
        }
    };

    return (
        <Dialog open={open} className={`textSizeDiag popDiagBlock ${props.cssStyl || ""}`}>
            <div className="headAndBtnWrap spaceButtomBig">
                <div className="backS">
                    <Close className="pointing" onClick={() => handleClose()} />
                </div>
                <div className="bold veryBig spacePadBig">
                    {props.topTitle ? props.topTitle : "משתמש רשום"}
                </div>
                <div className="lineDotted"></div>
            </div>
            {props.topImg && <div className="imgblok" style={{ backgroundImage: `url(${props.topImg})` }}></div>}
            <div className="firstMastSec">
                <div className="insideFrame">
                    <DialogTitle className="regularFont noPadding normalLine">
                        {props.Title.map((txtItm, index) => (
                            <span className="big arrowing" key={index}>{txtItm}</span>
                        ))}
                    </DialogTitle>
                    <DialogContent className="noPadding normalLine">
                        <DialogContentText className="spaceButtomBig">
                            {props.ContentText.map((txtItm, index) => (
                                <span className="big arrowing" key={index}>{txtItm}<br /></span>
                            ))}
                        </DialogContentText>
                        <input
                            id="inputPhonetx"
                            className="spaceTopBig inputPhonetx"
                            placeholder="מספר נייד"
                            value={inputTxt}
                            onChange={(e) => {
                                handleInputChange(e);
                                setErr(false);
                                setErMsg("");
                            }}
                            onKeyUp={handleKeyUp} // Trigger saveMe on Enter key
                            label="נייד"
                            variant="filled"
                            type="tel"
                            inputMode="numeric"
                            autoFocus
                        />
                        {err ? (
                            <div style={{ color: 'red', fontWeight: 'bold', fontSize: '1.3rem', overflow: "hidden" }}>{erMsg}</div>
                        ) : (
                            <div style={{ minHeight: "1.3rem" }}></div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <div className="fullRow">
                            <div className="fullRow">
                                <div className="connectBtn conClear bold big pointing" onClick={() => saveMe()}>שלח לי קוד אימות</div>
                            </div>
                            <br />
                            {erMsg && erMsg.includes("לא נמצא משתמש עם מספר הטלפון המבוקש") && (
                                <div className="fullRow">
                                    <div className="connectBtn conClear2 bold big pointing" onClick={() => props.register()}>הרשם</div>
                                </div>
                            )}
                        </div>
                    </DialogActions>
                </div>
            </div>
        </Dialog>
    );
};

export default PopUpPhoneConnect;
