import cardComApi from "../_ApiConn/cardComApi";



const goCardCom = async (theOrder) => {
    let dFromF = await cardComApi.goCardCom(theOrder);
    return (dFromF);
}


const cardChargeToken = async (theOrder) => {
    const usrtoken = localStorage.getItem('usrtoken');

    let dFromF = await cardComApi.cardChargeToken({ ...theOrder, usrtoken });
    return (dFromF);
}


const CardComApprove = async (theOrder) => {
    let dFromF = await cardComApi.CardComApprove(theOrder);
    return (dFromF);
}

export default {
    goCardCom,
    CardComApprove,
    cardChargeToken
};