import { useContext, useEffect, useRef, useState } from "react";
import ShowMyOrder from "./showMyOrder";
import PopUpMsg from "../../../DTechComp/PopUpMsg";
import TopBigSlides from "../../../DTechComp/TopBigSlides";
import ConnUrls from "../../../Connections/ConnectURL";
import { Button, IconButton } from "@mui/material";
import { CheckCircleOutline, ControlPointDuplicate, DeliveryDining, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, PanoramaFishEye, LocalMallOutlined,TakeoutDining, Visibility, VisibilityOff } from "@mui/icons-material";
import OrderFunctions from "../../../OrderSettings/OrderFunctions";
import MyDateTime from "../../../DTechComp/MyDateTime";
import { MobiOrNotContext } from "../../../ContextApi/context";



const MyClosedOrderDisp = (props) => {
    // const [order, setOrder] = useContext(OrderContext);

    const [theOrder, setTheOrder] = useState(props.theOrder)
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext)

    const [intervalID, setIntervalID] = useState();
    const [shown, setShown] = useState(false);



    useEffect(() => {

    }, [])

    const showThisOrder = (orderDivId) => {
        setShown(!shown)
        props.showThisOrder(orderDivId);
    }


    const orderConfirmd = (ord) => {
        props.orderConfirmd(ord);

    }

    return (
        <div key={props.ordrIndex} className={"overflowHid pointing offOrdItm"}>
            <div className={"headsline overflowHid"} onClick={() => showThisOrder(`ordDetailsDivCls_${props.ordrIndex}`)}>


                {!mobiOrNot ?
                    <>

                        {theOrder.ord.OrderType == "Take" ?
                            <LocalMallOutlined sx={{ color: 'var(--btnBlue)' }} />
                            :
                            <DeliveryDining sx={{ color: 'var(--btnBlue)' }} />
                        }
                        <span className={""}>
                            {(MyDateTime.getDate(theOrder.ord.dateCreated))}
                        </span>
                        <span className={""}>
                            {theOrder.buiss.buissName}, {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}
                        </span>


                        {!shown ?
                            <KeyboardDoubleArrowDown className={"onLeft"} sx={{ color: 'var(--btnBlue)' }} />
                            :
                            <KeyboardDoubleArrowUp className={"onLeft"} sx={{ color: 'var(--btnBlue)' }} />
                        }
                    </>
                    :
                    <>

                        <div className={"col-12"}>
                            <div className={"col-2 onRight"}>
                                {theOrder.ord.OrderType == "Take" ?
                                    <LocalMallOutlined sx={{ color: 'var(--btnBlue)' }} />
                                    :
                                    <DeliveryDining sx={{ color: 'var(--btnBlue)' }} />
                                }
                            </div>
                            <div className={"col-8 onRight"}>
                                <span className={"fullRow"}>
                                    {(MyDateTime.getDate(theOrder.ord.dateCreated))}
                                </span>
                                <span className={"fullRow"}>
                                    {theOrder.buiss.buissName}
                                </span>
                                <span className={"fullRow"}>
                                    {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}
                                </span>
                            </div>
                            <div className={"col-2 onRight"}>
                                {!shown ?
                                    <KeyboardDoubleArrowDown className={"onLeft"} sx={{ color: 'var(--btnBlue)' }} />
                                    :
                                    <KeyboardDoubleArrowUp className={"onLeft"} sx={{ color: 'var(--btnBlue)' }} />
                                }
                            </div>
                        </div>

                    </>

                }

            </div>
            <div className={"ordDetailsDiv hideMe margTopBig"} id={`ordDetailsDivCls_${props.ordrIndex}`}>

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 overflowHid">
                            <div className={`${!mobiOrNot ? "col-3" : ""} onLeft pointing onTopOfSome`}>
                                <Button className={"myBtnWithIcon"} variant="outlined"
                                    startIcon={<ControlPointDuplicate />}
                                    onClick={() => props.duplicThisOrder(theOrder.ord._id)}
                                >
                                    <span className={"btnWithIconTxt"}>שכפל הזמנה</span>
                                </Button>

                            </div>
                            {/* <div className={"col-9 pointing"}>
                                {(MyDateTime.getDate(theOrder.ord.dateCreated))} - {theOrder.buiss.buissName}, {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}
                            </div> */}
                        </div>

                        <div className={"col-12 pointing"}>
                            <div className={"MbigFont bold SecondFont onRight "}>{theOrder.buiss.buissName}</div>
                        </div>

                        <div className="col-12">

                            <div className={"openOrdBx orderLINE "} >
                                <div className={"small"}>מספר הזמנה: {theOrder.ord.orderNUM}</div>
                            </div>
                        </div>
                        <div className={"countTxtOrder"}>
                            <div className={"big"}>סטטוס: {theOrder.ord.orderStatus}</div>
                        </div>

                        <ShowMyOrder theOrder={theOrder.ord} />
                    </div>
                </div>
            </div>

        </div >

    )

}

export default MyClosedOrderDisp