

import { useContext, useEffect, useReducer, useState } from 'react';
import { CurrentPContext, MasterContext, OrderContext, OrdStageContext } from '../ContextApi/context';
import ShopingCratFloat from './ShopingCratFloat';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";

import OrderFunctions from '../OrderSettings/OrderFunctions';
import ConnUrls from '../Connections/ConnectURL';
import MyDateTime from './MyDateTime';
import { DirectionsBike, FiberManualRecord } from '@mui/icons-material';
import myFunctions from '../myFunctions';


const RestSmallBlock = (props) => {

    const [theItem, setItem] = useState(props.item);
    const [master] = useContext(MasterContext);

    const [timing, setTiming] = useState();
    const [priceing, setPriceing] = useState();
    const [opHours, setOpHours] = useState();
    let shuffClosed = [];


    useEffect(() => {

    }, [])




    const setOpenItem = (item, index, opHours) => {


        let stickyShow = (item.buissIsOnline != null && item.buissIsOnline) ? true : false;


        let notGettinOrders = myFunctions.notGettinOrders(master, opHours, item);
        let timing = (item.Delivery?.Time) ? item.Delivery.Time : (item.TakeAway?.Time) ? item.TakeAway.Time : 30;
        timing = myFunctions.checkForDeliverysDelay(master,timing);

        return (
            <div key={index} className={"Hprest animate__animated pulseME"}>
                <Link to={{
                    pathname: `/restaurants/${item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id}`,
                    state: { waitImg: item.waitingImg }
                }
                }>

                    {stickyShow ? <>
                        <div className={"stiickeyOnlnBlk"} style={{
                            left: "5px",
                            top: "5px",
                            backgroundImage: `url(${ConnUrls.servMediasUrl}Uploads/MASTER/onlne.png)`,
                            backgroundPosition: `center`,
                            backgroundSize: `85%`,
                            backgroundRepeat: 'no-repeat'
                        }}
                        >
                        </div>

                    </>
                        : ""}



                    <div className={"imgWrap"}>

                        <div className={"imgDiv"} style={{ backgroundImage: `url("${ConnUrls.servMediasUrl}${item.buissMainImg}")` }} >
                            {/* {(!opHours.openOrCLose) ? <div className={"imgWrapDivClose"}><span>סגור כעת</span></div> : ""} */}
                            {(notGettinOrders != "") ? 
                            <div data-nosnippet className={"imgWrapDivClose no-render"}>
                                <span data-nosnippet className={"no-render"}>{notGettinOrders}</span>
                            </div> : ""}

                            <div className={"botBlock"}>
                                <div className={"rSide"}>
                                    <div className={"pgBigTxt bold regular"}>{item.buissName}</div>
                                    <div className={"pgSmallTxt mediumst"}>
                                        <p>{item.buissKitchType}</p>
                                    </div>
                                </div>
                                <div className={"lSide"}>
                                    <div className={"pgSmallTxt"}>
                                        <p className={"pgOpenCloseBlk mediumst "}>
                                            {/* <span className={`specDot ${!opHours.openOrCLose ? "specDotClose" : ""}`}><FiberManualRecord /></span> */}
                                            <span className={`specDot ${!opHours.openOrCLose ? "specDotClose" : ""}`}><FiberManualRecord /></span>
                                            {/* {item.txtBuisKosher} */}
                                            {
                                                opHours.openOrCLose ?
                                                    opHours.timtxt : opHours.txtBtn

                                            }
                                        </p>
                                    </div>
                                    <div className={"pgSmallTxt mediumst"}>
                                        <div className={"theImgwrap"} >
                                            <div className={"theImg"} >
                                                <DirectionsBike />
                                            </div>
                                        </div>
                                        <div className={"timeTxt mediumst"}>
                                            {(parseInt(timing) + 10) +
                                                " - " + parseInt(timing)} דק'
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                </Link>
                <br />
            </div>
        )
    }

    let theLst = props.mainRests || [];

    if (props.fromitem != null) {
        let filt = props.mainRests.filter(
            (it, indx) =>
                (indx >= props.fromitem) &&
                (props.toitem >= indx)
        )
        theLst = filt;
    }

    return (
        <>
            {


                theLst.map((item, index) => {
                    let opHours = MyDateTime.checkIfBetweenHours(item);
                    if (opHours.openOrCLose) {
                        return (setOpenItem(item, index, opHours))
                    }
                    else {
                        shuffClosed.push({ item, index, opHours });

                    }
                })
            }

            {
                shuffClosed.map((item) => {
                    return (setOpenItem(item.item, item.index, item.opHours))
                })
            }

        </>
    )
}

export default RestSmallBlock;


