import { useContext, useEffect, useRef, useState } from "react";
import ShowMyOrder from "./showMyOrder";
import PopUpMsg from "../../../DTechComp/PopUpMsg";
import TopBigSlides from "../../../DTechComp/TopBigSlides";
import ConnUrls from "../../../Connections/ConnectURL";
import { Button, IconButton } from "@mui/material";
import { CheckCircleOutline, DeliveryDining, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, PanoramaFishEye, TakeoutDining, Visibility, VisibilityOff } from "@mui/icons-material";
import OrderFunctions from "../../../OrderSettings/OrderFunctions";
import { MobiOrNotContext } from "../../../ContextApi/context";



const MyOpenOrderDisp = (props) => {
    // const [order, setOrder] = useContext(OrderContext);



    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext)

    const [theOrder, setTheOrder] = useState(props.theOrder)
    const [intervalID, setIntervalID] = useState();
    const [shown, setShown] = useState(false);



    useEffect(() => {
        setTheOrder(props.theOrder)
    }, [props.theOrder])

    const showThisOrder = (orderDivId) => {
        setShown(!shown)
        props.showThisOrder(orderDivId);
    }


    const orderConfirmd = (ord) => {
        props.orderConfirmd(ord);

    }

    return (
        <>
            <div key={props.ordrIndex} className="onOrdItm overflowHid">
                <div className="col-12">

                    {!mobiOrNot ?
                        <>
                            <div className="row overflowHid">
                                <div className="col-9 onRight">
                                    <div className="col-9 onRight pointing">
                                        <div className="col-12">
                                            <div className={"MbigFont bold SecondFont onRight "} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>{theOrder.buiss.buissName}</div>
                                        </div>
                                        <div className="col-12 overflowHid">
                                            <div className={"openOrdBx fulllRow overflowHid "} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>
                                                <div className={"small"}>מספר הזמנה: {theOrder.ord.orderNUM}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 overflowHid">
                                            <div className={"openOrdBx fulllRow overflowHid "} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>
                                                <div className={"small"}>{OrderFunctions.payingTYpe(theOrder.ord.payt)}: {OrderFunctions.getOrderTotalPrice(theOrder.ord)} ₪ </div>
                                            </div>
                                        </div>
                                        <div className="col-12 overflowHid">
                                            <div key={props.ordrIndex} className={"openOrdBx orderLINE"} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>
                                                <div className={"countTxtOrder"}>
                                                    <div className={"big"}> {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}, {theOrder.ord.orderStatus}</div>
                                                </div>
                                                <div className={"countTimOrder huge onLeft"} id={`${theOrder.ord.orderNUM}`}></div>
                                                <div className={"veryVerySmall onLeft spaceTopBig"} id={`${theOrder.ord.orderNUM}_GR`}>
                                                    {theOrder.ord.OrderType == "Take" ? "נהיה מוכנים בעוד" : "נגיע בעוד"}
                                                </div>

                                                <div id={`${theOrder.ord.orderNUM}_Ended`} className={"hideMe huge onLeft"}>
                                                    {theOrder.ord.OrderType == "Take" ? "ממתין לאיסוף" : "תיכף מגיע"}</div>

                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-3 onRight">
                                        <div className={"onLeft pointing"}>
                                            <Button className={"myBtnWithIcon"} variant="outlined" startIcon={!shown ? <Visibility /> : <VisibilityOff />}
                                                onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}
                                            >
                                                <span className={"btnWithIconTxt"}>{!shown ? "הצג הזמנה" : "מזער הזמנה"}</span>
                                            </Button>

                                        </div>

                                        <div id={`Conf_${theOrder.ord.orderNUM}`}
                                            className={"hideMe onLeft pointing"}
                                        >
                                            <Button className={"myBtnWithIcon"} variant="outlined" startIcon={<CheckCircleOutline />}
                                                onClick={() => orderConfirmd(theOrder.ord)}
                                            >
                                                <span className={"btnWithIconTxt"}>אישור קבלה</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 onRight">
                                    <div className={"smallDIspImageWrap"}>
                                        <img src={`${ConnUrls.servMediasUrl}${theOrder.buiss.buissMainImg}`}
                                            className={"smallDIspImage"} />
                                    </div>
                                </div>
                            </div>
                            <div className="row overflowHid">


                                <div className={"ordDetailsDiv hideMe"} id={`ordDetailsDiv_${theOrder.ord.orderNUM}`}>
                                    <div className="col-12">
                                        <div className="col-12 centerText">
                                            {!shown ?
                                                <KeyboardDoubleArrowDown className={"onTopOfAll pointing"} sx={{ color: 'var(--btnBlue)' }} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)} />
                                                :
                                                <KeyboardDoubleArrowUp className={"onTopOfAll pointing"} sx={{ color: 'var(--btnBlue)' }} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)} />
                                            }
                                        </div>
                                        <ShowMyOrder theOrder={theOrder.ord} />

                                    </div>
                                </div>
                            </div>

                        </>
                        :
                        <>
                            <div className="row overflowHid">
                                <div className="col-12 onRight">
                                    <div className="col-8 onRight pointing">
                                        <div className="col-12">
                                            <div className="col-1 onRight">
                                                {theOrder.ord.OrderType == "Take" ?
                                                    <TakeoutDining sx={{ color: 'var(--btnBlue)' }} />
                                                    :
                                                    <DeliveryDining sx={{ color: 'var(--btnBlue)' }} />
                                                }
                                            </div>

                                            <div className={"MbigFont bold spaceRightLong col-10 onRight "} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>{theOrder.buiss.buissName}</div>
                                        </div>
                                        <div className="col-12 overflowHid">
                                            <div className={"openOrdBx fulllRow overflowHid "} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>
                                                <div className={"regular"}>מספר הזמנה: {theOrder.ord.orderNUM}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 overflowHid">
                                            <div className={"openOrdBx fulllRow overflowHid "} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>
                                                <div className={"regular"}>{OrderFunctions.payingTYpe(theOrder.ord.payt)}: {OrderFunctions.getOrderTotalPrice(theOrder.ord)} ₪ </div>
                                            </div>
                                        </div>



                                    </div>

                                    <div className="col-4 onRight">
                                        <div className={"onLeft smallDIspImageWrap"}>
                                            <img src={`${ConnUrls.servMediasUrl}${theOrder.buiss.buissMainImg}`}
                                                className={"smallDIspImage"} />
                                        </div>

                                    </div>


                                </div>
                                <div className="col-12 overflowHid">
                                    <div key={props.ordrIndex} className={"openOrdBx orderLINE"} onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}>
                                        <div className={"countTxtOrder"}>
                                            <div className={"big"}> {theOrder.ord.OrderType == "Take" ? "איסוף" : "משלוח"}, {theOrder.ord.orderStatus}</div>
                                        </div>
                                        <div className={"countTimOrder veryBig spaceTop onLeft"} id={`${theOrder.ord.orderNUM}`}></div>
                                        <div className={"veryVerySmall onLeft spaceTopBig"} id={`${theOrder.ord.orderNUM}_GR`}>{theOrder.ord.OrderType == "Take" ? "נהיה מוכנים בעוד" : "נגיע בעוד"}</div>

                                        <div id={`${theOrder.ord.orderNUM}_Ended`} className={"hideMe veryBig spaceTop onLeft"}>
                                            {theOrder.ord.OrderType == "Take" ? "מוכן לאיסוף" : "תיכף מגיע"}</div>

                                    </div>
                                </div>
                                <div className="col-12 overflowHid">

                                    <div className={"onLeft pointing"}>
                                        <Button className={"myBtnWithIcon"} variant="outlined" startIcon={!shown ? <Visibility /> : <VisibilityOff />}
                                            onClick={() => showThisOrder(`ordDetailsDiv_${theOrder.ord.orderNUM}`)}
                                        >
                                            <span className={"btnWithIconTxt"}>{!shown ? "הצג הזמנה" : "מזער הזמנה"}</span>
                                        </Button>

                                    </div>

                                    <div id={`Conf_${theOrder.ord.orderNUM}`}
                                        className={"hideMe onRight pointing"}
                                    >
                                        <Button className={"myBtnWithIcon"} variant="outlined" startIcon={<CheckCircleOutline />}
                                            onClick={() => orderConfirmd(theOrder.ord)}
                                        >
                                            <span className={"btnWithIconTxt"}>אישור קבלה</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row overflowHid">


                                <div className={"ordDetailsDiv hideMe"} id={`ordDetailsDiv_${theOrder.ord.orderNUM}`}>
                                    <div className="col-12">

                                        <ShowMyOrder theOrder={theOrder.ord} />

                                    </div>
                                </div>
                            </div>

                        </>
                    }
                </div>

            </div>
        </>

    )

}

export default MyOpenOrderDisp