
import React, { useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';



const J5ClientApprove = (props) => {


    return (
        <PopUpMsg Title={["תלוי במלאי"]}
        nosnippet={true}
            ContentText={[{ txt: "לעסק זה מלאי מוגבל ולכן", css: "" }
                , { txt: "אישור העסקה יהיה תלוי באישור העסק", css: "" }
                , { txt: "רק לאחר קבלת אישור המוצר במלאי", css: "" }
                , { txt: "החיוב וההזמנה יבוצעו", css: "" }]}
            inLabel={""}
            BtnTxt={"מקובל"}
            okClick={() => {
                props.okClick()
            }}
            BtnTxt2={"אוותר הפעם"}
            btnClick2={() => {
                props.btnClick2()
            }}
            errorMsg={""} />
    )
}


export default J5ClientApprove;