import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConnUrls from "../../Connections/ConnectURL";
import { MasterContext, MobiOrNotContext } from "../../ContextApi/context";
import RestSmallBlock from "../../DTechComp/RestSmallBlock";
import utilsCategurys from "../../_Utils/utilsCategurys";
import MyFunctions from "../../myFunctions";
import myFunctions from "../../myFunctions";


const CategurysList = (props) => {
    const [master] = useContext(MasterContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [shufMe, setShufMe] = useState();
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [bgImgPos, setBgImgPos] = useState("");
    const [waitImgItm, setWaitImgItm] = useState({});



    useEffect(() => {
        setAll();
        setWaitImgItm(MyFunctions.setTheImgWait({}, master));

    }, [])

    useEffect(() => {
    }, [loading])

    const setAll = async () => {
        let allRests = await utilsCategurys.getRestsByCatId(props.pageId);

        let temp = [...allRests];
        let readylist = myFunctions.getShufBuissClosAtLast(temp)
        // let readylist = MyFunctions.setSHuffled(temp, []);
        // setShufMe(readylist);
        setShufMe(readylist);
        setLoading(false);


        setWaitImgItm(MyFunctions.setTheImgWait(readylist, master));

    }


    return (
        <div>
            {
                <>
                    <div className={loading ? "imgWaiting" :
                        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
                        style={{
                            backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
                            backgroundPosition: `${waitImgItm.imgBackPos}`,
                            backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
                            backgroundRepeat: `${waitImgItm.backgroundRepeat}`
                        }}>

                    </div>

                    {loading ? "" :
                        <RestSmallBlock mainRests={shufMe} />
                    }
                </>

            }
        </div >
    )
}

export default CategurysList;