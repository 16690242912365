import koponsLst from "../_ApiConn/apiKopons";


const chekckKopon = async (kopon, order) => {
    const token = localStorage.getItem('usrtoken');
    let dFromF = await koponsLst.chekckKopon({ token, kopon, order });
    return (dFromF);
}


export default {
    chekckKopon

};