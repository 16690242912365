import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const chekckKopon = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "kopons/checkKoponValid", { params: obj });
    return (dFromF.data);
}


export default {
    chekckKopon
};