
import React, { useContext, useEffect, useState } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';
import TopBigSlides from '../TopBigSlides';
import { MasterContext } from '../../ContextApi/context';


const Closed_Buis = (props) => {
    const [master] = useContext(MasterContext);

    return (

        <PopUpMsg
            Title={["סגור כעת!"]}
            nosnippet={true}
            cssStyl={"okPOP"}
            topSlides={<TopBigSlides TopImages={[{ img: master.nowCloseImg, txt: "" }]} />}
            ContentText={[{ txt: "עסק זה כרגע סגור", css: "" }
                , { txt: "ולא ניתן לבצע הזמנות", css: "" }]}
            inLabel={""}
            BtnTxt={"חזור"}
            okClick={() => props.okClick()}
            BtnTxt2={"חזור לעמוד המסעדה"}
            btnClick2={() => { props.btnClick2() }}
            errorMsg={""} />

        // <BigPopUpMsg
        //     class={"oops"}
        //     imgSrc={`${ConnUrls.servMediasUrl}${props.master.nowCloseImg}`}
        //     Title={["סגור כעת!"]}
        //     ContentText={["עסק זה כרגע סגור",
        //         "ולא ניתן לבצע הזמנות"]}
        //     inLabel={""}
        //     BtnTxt={"חזור"}
        //     okClick={() => props.okClick()}
        //     BtnTxt2={"חזור לעמוד המסעדה"}
        //     btnClick2={() => { props.btnClick2() }}
        //     errorMsg={""} />
    )
}

export default Closed_Buis;