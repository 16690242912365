import { useContext, useEffect, useState } from "react"
import { ThumbUpAltOutlined, CallOutlined, DirectionsBike } from '@mui/icons-material';
import { Link } from "react-router-dom";
import { CurrentPContext } from "../../ContextApi/context";
import utilsRests from "../../_Utils/utilsRests";
import ConnUrls from "../../Connections/ConnectURL";
import MyDateTime from "../../DTechComp/MyDateTime";
import RestSmallBlock from "../../DTechComp/RestSmallBlock";



const MainRest = (props) => {

    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    useEffect(() => {
    
    }, [])

    return (
        <div>
            <RestSmallBlock mainRests={props.mainRests}
                fromitem={props.fromitem}
                toitem={props.toitem}
            />
        </div>
    )
}

export default MainRest;