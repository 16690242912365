import React, { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TheOrderList from '../OrderSettings/TheOrderList';
import Scroll from 'react-scroll';
import TheDInners from '../OrderSettings/TheDInners';
import { CancelRounded, CheckCircleOutline, Done, RemoveCircleOutline, PanoramaFishEye } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { CurrentPContext, OrderContext } from '../ContextApi/context';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import TheMoreThenOneMount from '../OrderSettings/TheMoreThenOneMount';
import DinersMoreThenOneMount from '../OrderSettings/DinersMoreThenOneMount ';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const PopUpCCTokensCharge = (props) => {
    const [open, setOpen] = useState(true);
    const [cCOptions, setCCOptions] = useState("");

    const [theValue, setTheValue] = useState(0);
    const [showWait, setShowWait] = useState(false);

    useEffect(() => {
        getOpts()
    }, [props.CCtokens])

    const removeToken = (val) => {
        props.removeToken(val)
    }

    const updateRdbSelection = (event, value, index) => {
        setTheValue(value);
    };
    const getOpts = () => {

        let opts;
        let rads = props.CCtokens.map((item, index) => {
            return (
                <div key={index} className={"selBlk"} style={{ minWidth: "250px" }}>
                    <div className={"onRight"} style={{ width: "80%" }}>
                        <FormControlLabel value={index} style={{ width: "100%" }}
                            control={
                                <Radio
                                    icon={<PanoramaFishEye style={{ visibility: "hidden" }} />}
                                    checkedIcon={<Done style={{ color: "black" }} />}
                                    sx={{}}
                                />
                            }
                            label={`${item.Last4CardDigits}-XXXXXXXX`} />
                    </div>
                    <div className={"onLeft"} style={{ width: "18%", lineHeight: "30px", paddingTop: "5px" }}>
                        <span className={"pointing btnRemoveCard"} onClick={() => removeToken(index)}>הסר</span>

                        {/* <RemoveCircleOutline onClick={() => removeToken(index)} className={"InRed pointing"} /> */}

                    </div>
                </div >
            )
        });
        opts = <div className={"rdbsLst"}>
            <FormControl component="fieldset">
                <RadioGroup
                    onChange={updateRdbSelection}
                    aria-label={"כרטיסים קיימים"}
                    defaultValue={theValue}
                    name="radio-buttons-group"
                >
                    {rads}
                </RadioGroup>
            </FormControl>
        </div>


        setCCOptions(opts);
    }


    const handleClose = () => {
        props.handleClose();
    }

    return (

        <Dialog open={open} className={`popDiagBlock ${props.cssStyl ? props.cssStyl : ""}`}>
            <div className={"closePopWinBtn"} onClick={() => handleClose()} >
                <CancelRounded className={"inBlack popUpCloseBtn_Small pointing "} />
            </div>
            <DialogTitle className={"theTitlePop_1 headOnFixPop"}>
                תשלום בכרטיס קיים<br />
            </DialogTitle>
            <DialogContent>
                {cCOptions}
            </DialogContent>
            <DialogActions >
                {!showWait ?
                    <button type="button" className="centerMe btn btnPopRegular blueBtn whiteText pointing"
                        onClick={() => { props.okClick(props.CCtokens[theValue]); setShowWait(true) }}>
                        {(props.BtnTxt != null) ? props.BtnTxt : "שלם"}</button>
                    : <p className={"inRed centerMe"}>נסיון חיוב...</p>
                }
            </DialogActions>
            <DialogActions >
                {props.btnClick2 != null ?
                    <>
                        <span>&nbsp;&nbsp;</span>
                        <button type="button"
                            className="btn-success btnPopRegular TokenChargeBtn 
                            btnPopRegularSec spaceTopBig pointing"
                            onClick={() => { props.btnClick2() }}>
                            {(props.BtnTxt2 != null) ? props.BtnTxt2 : "תשלום בכרטיס אחר"}</button>
                    </>
                    : ""}

            </DialogActions>
        </Dialog >


    );
}

export default PopUpCCTokensCharge;