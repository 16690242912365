
import { ArrowCircleRightSharp } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router-dom";
import Scroll from 'react-scroll';
import ConnUrls from '../../Connections/ConnectURL';
import { CurrentPContext, MasterContext, MobiOrNotContext, OrderContext } from '../../ContextApi/context';
import Payment_Error from '../../DTechComp/Pop_Fixed/Payment_Error';
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import utilsSingleP from '../../_Utils/utilsSinglePage';
import myFunctions from '../../myFunctions';
import { Helmet } from 'react-helmet';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const ErrorPayPage = (props) => {
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const domain = urlObject.origin;


  const [order, setOrder] = useContext(OrderContext);
  const [master, setMaster] = useContext(MasterContext);

  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [waitImgItm, setWaitImgItm] = useState({});
  const [bkparams, setBkparams] = useState();
  const [thePopUp, setThePopUp] = useState("");

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  useEffect(() => {


    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(false)
    getSinglePage();
    getParmeters();
    setPopUp()
    setLoading(false);


  }, [])


  const goOutsidOfframe = () => {
    if (window.parent !== window) {

      // Access the parent window's history object and push the new state
      window.parent.setThePopUp();
    } else {

      // If not inside an iframe, use the regular history.push
      history.goBack();
    }

  }
  const setPopUp = () => {
    setThePopUp(
      <Payment_Error order={order}
        okClick={() => { goOutsidOfframe() }}
      />
    )
  }
  const getParmeters = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams) {
      let prms = {
        orderNumber: urlParams.get('orderNumber'),
        transactionId: urlParams.get('transactionId'),
      }

      order.orderNUM = urlParams.get('orderNumber');
      order.creditCrdApprov = urlParams.get('transactionId');
      setBkparams(prms);

    }
    else {
      history.push("/404")
    }
  }

  const getSinglePage = async () => {
    let pName = props.pageName || window.document.href.substring(window.document.href.lastIndexOf('/') + 1)
    let theSingle = await utilsSingleP.getPageByName(props.pageName);
    setCurrentPage({ ...theSingle, pageTYp: "" });

  }
  const goToBack = () => {
    sessionStorage.setItem("SinglePage", "");
    history.goBack();

  }


  const setBackground = () => {

    if (window.parent !== window) {
      const elements = document.querySelectorAll('.popDiagBlock > div');

      elements.forEach((element) => {
        element.style.backgroundColor = 'white';
      });

      const inelements = document.querySelectorAll('.popDiagBlock .MuiPaper-root');

      inelements.forEach((element) => {
        element.style.boxShadow = 'unset';
      });
    }
  }

  return (
    <div className="SinglePage">
      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(!mobiOrNot) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>
      </div>

      {loading ? "" :
        <>
          {(mobiOrNot) ?
            <>
              <div className={"headOnFixC SecondFont bold big"}>
                <div className={`backSd`}>
                  <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>


              </div>
              <div className={`singlePagePad`}>
              </div>
            </>
            :
            ""

          }


          <ScrollingSaver pagNam={"SinglePage"} />

          {thePopUp}
          {setBackground()}
        </>

      }
      <Helmet
        meta={[
          {
            property: "og:url", content: `${ConnUrls.siteDomainNam}/${encodeURIComponent(currentPage.inLinkToPage)}`
          }
        ]}>
        <link rel="canonical" href={`${ConnUrls.siteDomainNam}/${encodeURIComponent(currentPage.inLinkToPage)}`} />
      </Helmet>
    </div>
  );
}

export default ErrorPayPage;
