import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { format, isBefore, isToday, isSameDay, subDays } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import heLocale from 'date-fns/locale/he';
import 'react-day-picker/dist/style.css';

const SpecialRequests = (props) => {


  const [tabelsLocations, setTabelsLocations] = useState(props.tabelsLocations)
  const [specialsList, setSpecialsList] = useState(props.specialsList)

  const [tabelsLocationsReq, setTabelsLocationsReq] = useState(props.tabelsLocationsReq)
  const [specialsListReq, setSpecialsListReq] = useState(props.specialsListReq)

  const [rowsList, setRowsList] = useState([]);




  useEffect(() => {

  }, []);


  useEffect(() => {
    setLocsandspec();
  }, [tabelsLocationsReq, specialsListReq]);


  const setLocsandspec = () => {
    const rows = [];
    const maxItemsPerRow = 4;
    if (tabelsLocations) {

      let locs = tabelsLocations.split(",")
      for (let i = 0; i < locs.length; i += maxItemsPerRow) {
        const rowItems = locs.slice(i, i + maxItemsPerRow);


        const items =
          rowItems.map((itm, indx) => {
            if (itm.trim() !== "") {
              return (
                <div key={"spc_" + indx}
                  className={`reservationSpecSelItm pointing ${tabelsLocationsReq.includes(itm) ? "selectedHou" : ""}`}
                  onClick={() => handleLocationsChange(itm)}>
                  {itm}
                </div>
              );
            }
          })


        rows.push(items);
      }
    }

    if (specialsList) {
      let locs = specialsList.split(",")
      for (let i = 0; i < locs.length; i += maxItemsPerRow) {
        const rowItems = locs.slice(i, i + maxItemsPerRow);

        const items =
          rowItems.map((itm, indx) => {
            if (itm.trim() !== "") {
              return (
                <div key={"spc_" + indx}
                  className={`reservationSpecSelItm pointing ${specialsListReq.includes(itm) ? "selectedHou" : ""}`}
                  onClick={() => handleSpecialsChange(itm)}>
                  {itm}
                </div>
              );
            }
          })

        rows.push(items);
      }

    }
    setRowsList(rows)
  }




  const handleSpecialsChange = (val) => {

    let tempo = [...specialsListReq];
    if (tempo.includes(val)) {
      tempo = tempo.filter(it => it !== val)
    }
    else {
      tempo.push(val)
    }
    setSpecialsListReq(tempo);

    props.settingFunc({ specialsListReq: tempo, tabelsLocationsReq });

  };

  const handleLocationsChange = (val) => {

    let tempo = [...tabelsLocationsReq];
    if (tempo.includes(val)) {
      tempo = tempo.filter(it => it !== val)
    }
    else {
      tempo.push(val)
    }
    setTabelsLocationsReq(tempo);

    props.settingFunc({ specialsListReq, tabelsLocationsReq: tempo });
  };

  return (
    <div className={'myDinnersSelection'}>

      <label style={{ color: "black" }} >בחרו העדפות</label>
      <div className={"fullRow overflowHid"}>
        {
          rowsList.map((itm, indx) => {
            return (
              itm
            )
          }
          )
        }
      </div>
    </div>
  );
};

export default SpecialRequests;
