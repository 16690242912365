import myFunctions from "../myFunctions";
import rests from "../_ApiConn/apiRests";



const getRestsUrls = async () => {
  
    let dFromF = await rests.getRestsUrls();
    let lstBack = dFromF.filter(item => item.restStatus != "לא מפורסם");

   

    return (lstBack);
}

//מושך מסעדה לפי איידי, גם אם היא לא פעילה
const getRestByIdWitUnPublished = async (id) => {
    let dFromF = await rests.getRestById(id);
    let lstBack = (dFromF.restStatus != "לא מפורסם") ? dFromF : null;
    return (lstBack);
}


const getMainRestsByIds = async (idsList) => {
    let lstBack = await rests.getMainRestsByIds({ idsList });

    return (lstBack);
}


const getMainRests = async () => {
    let lstBack = await rests.getMainRests("");

    return (lstBack);
}

const getTableReservsList = async () => {
    let lstBack = await rests.getTableReservsList("");

    return (lstBack);
}

const getRestById = async (id) => {
    let dFromF = await rests.getRestById(id);
    let lstBack = (dFromF.restStatus != "לא מפורסם" && dFromF.restStatus != "לא פעיל") ? dFromF : null;
    return (lstBack);
}
const getRestNowOpen = async (obj) => {
    let dFromF = await rests.getRestNowOpen(obj);
    let lstBack = dFromF;
    return (lstBack);
}

const getSerchResault = async (txtSrch) => {
    let dFromF = await rests.getSerchResault(txtSrch);
    let lstBack = dFromF.filter(item => item.restStatus != "לא פעיל" && item.restStatus != "לא מפורסם");
    return (lstBack);
}



const getallBuisSmallLst = async (txtToSearch) => {
    let dFromF = await rests.getallBuisSmallLst(txtToSearch);
    
    return (dFromF);
}

export default {
    getallBuisSmallLst,
    getMainRestsByIds,
    getMainRests,
    getRestNowOpen,
    getRestById,
    getSerchResault,
    getRestByIdWitUnPublished,
    getRestsUrls,
    getTableReservsList
};