import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const checkCode = async (nId, codeCheck) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "codes/Nuser/" + nId, codeCheck);
    return (dFromF.data);
}

const checkUnregCode = async (nId, codeCheck) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "codes/RemUser/" + nId, codeCheck);
    return (dFromF.data);
}

const createCodeNuser = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "codes/Nuser", obj);
    return (dFromF.data);
}

const createCodeSms = async (phoneNum) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "codes/conSms", {phoneNum});
    return (dFromF.data);
}

const checkConnCode = async (nId, codeCheck) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "codes/conSms/" + nId, codeCheck);
    return (dFromF.data);
}

const checkToken = async (token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/checkUsrToken", { token })

    return (dFromF.data);
}

const createCodeUpUser = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "codes/UPuser", obj);
    return (dFromF.data);
}

const checkUpdteCode = async (nId, codeCheck) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "codes/updtSms/" + nId, codeCheck);
    return (dFromF.data);
}



export default {
    checkConnCode,
    createCodeUpUser,
    checkUpdteCode,
    createCodeSms,
    createCodeNuser,
    checkCode,
    checkUnregCode,
    checkToken
};
