import loginP from "../_ApiConn/apiLoginPage";

const getLoginPage = async () => {
    let dFromF = await loginP.getLoginPage();
    return (dFromF.data[0]);
}

const connectUsr = async (conDetails) => {
    let dFromF = await loginP.connectUsr(conDetails);
    return (dFromF);
}

export default {
    connectUsr,
    getLoginPage
};