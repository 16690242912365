import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getSitemap = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "sitemap");
    return (dFromF.data);
}

const getSitemapXMl = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "sitemap/sitemap.xml");
    return (dFromF.data);
}


export default {
    getSitemap,
    getSitemapXMl
};