import apiSitemap from "../_ApiConn/apiSitemap";



const getSitemap = async () => {
    let dFromF = await apiSitemap.getSitemap();
    return (dFromF);
}

const getSitemapXMl = async () => {
    let dFromF = await apiSitemap.getSitemapXMl();
    return (dFromF);
}


export default {
    getSitemap,
    getSitemapXMl
};