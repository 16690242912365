
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import { MasterContext, OrderContext } from '../../ContextApi/context';
import OrderFunctions from '../../OrderSettings/OrderFunctions';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';



const Delivery_Confirmd = (props) => {
    const [master] = useContext(MasterContext);
    const [order, setOrder] = useContext(OrderContext);



    if (props.order.ordr) {
        return (
            <PopUpMsg Title={["ההזמנה בוצעה"]}
                nosnippet={true}
                ContentText={[{ txt: "המסעדה מתחילה להכין את ההזמנה.", css: "" }
                    , { txt: "שליח ידפוק אצלך בדלת", css: "" }
                    , { txt: `בערך בעוד ${props.order.ordr.OrderTimes} דקות`, css: "" }
                    , { txt: `מספר הזמנה: ${props.order.ordr.orderNUM}`, css: "" }
                    , {
                    txt: props.order.ordr.transactionId != "" && props.order.ordr.transactionId != undefined ?
                        `מספר אישור עסקה: ${props.order.ordr.transactionId}` : "חיוב לא בוצע", css: ""
                }
                ]}
                inLabel={""}
                BottomTitle={["בתאבון!"]}
                topImg={`${ConnUrls.servMediasUrl}${master.orderApprovImg}`}
                cssStyl={"approveMsgPopup"}
                okClick={() => { setOrder(OrderFunctions.clearOrd()); props.okClick() }}
                errorMsg={""} />
        )
    }
    else {
        return (

            <PopUpMsg Title={["ההזמנה בוצעה"]}
                nosnippet={true}
                ContentText={[{ txt: "המסעדה מתחילה להכין את ההזמנה.", css: "" }
                    , { txt: "שליח ידפוק אצלך בדלת", css: "" }
                    , { txt: `בערך בעוד ${props.order.OrderTimes} דקות`, css: "" }
                    , { txt: `מספר הזמנה: ${props.order.orderNUM}`, css: "" }
                    , {
                    txt: props.order.transactionId != "" && props.order.transactionId != undefined ?
                        `מספר אישור עסקה: ${props.order.transactionId}` : "חיוב לא בוצע", css: ""
                }
                ]}
                inLabel={""}
                BottomTitle={["בתאבון!"]}
                topImg={`${ConnUrls.servMediasUrl}${master.orderApprovImg}`}
                cssStyl={"approveMsgPopup"}
                okClick={() => { setOrder(OrderFunctions.clearOrd()); props.okClick() }}
                errorMsg={""} />
        )
    }
}


export default Delivery_Confirmd
