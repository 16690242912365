import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const updateUser = async (userId, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/" + userId, obj);
    return (dFromF.data);
}


const createUser = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "user", obj);
    return (dFromF.data);
}


const getUserById = async (id,token) => {
   

    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/" + id, {
        params: {
            token: token,
        },
    });
    return (dFromF.data._doc);
}

const getAdresses = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/Address/" + id);
    return (dFromF.data);
}

const addNewAddress = async (id, address) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/Address/" + id, address);
    return (dFromF.data);
}


const removeAddress = async (id, address) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/removeAdd/" + id, address);
    return (dFromF.data);
}


const getUsersOrders = async (id, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/usrOrders/" + id, { token });
    return (dFromF.data);
}



const chekUserOrders = async (id, obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/chekUserOrders/" + id, { params:obj });
    return (dFromF.data);
    
}


const checkForUserTokensCC = async (id, obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/checkForUserTokensCC/" + id, { params:obj });
    return (dFromF.data);
    
}



const removeCCTok = async (userId,obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/removeCCTok/" + userId, { params:obj });
    return (dFromF.data);
    
}


export default {
    removeAddress,
    addNewAddress,
    getAdresses,
    updateUser,
    createUser,
    getUserById,
    getUsersOrders,
    chekUserOrders,
    checkForUserTokensCC,
    removeCCTok
};