import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from "react-router-dom";
import { lineHeight, maxWidth } from '@mui/system';

const BigPopUpMsg = (props) => {

    const history = useHistory();

    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    useEffect(() => {

    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
        // props.cancelClick();
    };

    return (
        <>
            <Dialog
                open={open}
                style={{ width: "100%", maxWidth: "450px" }}
                className={`${props.class}  textSizeDiag popDiagBlock popBig`}>
                {/* <div className={'imgOnPopUp'} style={{ backgroundImage: `url('${props.imgSrc}')` }}>
                <img src={props.imgSrc} />
            </div> */}
                <div className='oopsTxtWrap'>
                    <DialogTitle className={"theTitlePop"}>
                        {props.Title.map((txtItm, index) => {
                            return (
                                <span key={index} >{txtItm}<br /></span>
                            )
                        })}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {props.ContentText.map((txtItm, index) => {
                                return (
                                    <span key={index} className="big oopsTxt">{txtItm}<br /></span>
                                )
                            })}
                        </DialogContentText>

                        {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}

                    </DialogContent>
                    <DialogActions >
                        <button type="button"
                            style={{
                                font: "var(--myFont) !important",
                                fontWeight: "200 !important"
                            }}
                            className="centerMe btn btn-success btnPopRegular btnYellow pointing" onClick={() => { props.okClick(); handleClose(); }}>
                            {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}</button>

                    </DialogActions>
                    <DialogActions >

                        {props.btnClick2 != null ?
                            <>
                                <span>&nbsp;&nbsp;</span>
                                <div className="btn-success lnkPop pointing" onClick={() => { props.btnClick2(); handleClose(); }}>
                                    {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</div>
                            </>
                            : ""}
                    </DialogActions>
                </div>

            </Dialog>

        </>
    );
}

export default BigPopUpMsg;