import { useEffect } from "react";
import MyDateTime from "../../../DTechComp/MyDateTime";
import { HomeOutlined } from '@mui/icons-material';

const AddressLine = (props) => {



    let enter = (props.address?.ent != null) ? props.address?.ent + " " : "";


    let mainAddress = props.address?.street + " " + props.address?.num + " " + enter + props.address?.city;


    return (
        <div className="addressLine">
            <div style={{ display: (props.typeDisp == "NoIcon" ? "none" : "") }} ><HomeOutlined /></div>
            <span>{mainAddress}</span>
        </div>
    );
}


export default AddressLine;
