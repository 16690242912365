import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUpMsg = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    useEffect(() => {
    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
        // props.cancelClick();
    };

    return (
        <Dialog open={open} className={`textSizeDiag popDiagBlock ${props.cssStyl ? props.cssStyl : ""}`}>
            {(props.topSlides != null) ?
                props.topSlides : ""
            }
            {
                (props.topImg != null) ?
                    <div className={"imgblok"} style={{ backgroundImage: `url(${props.topImg})` }}></div> : ""
            }
            <DialogTitle className={"theTitlePopSmall"}>
                {props.Title.map((txtItm, index) => {
                    return (
                        <span key={index} className={props.nosnippet?"no-render":""}>{txtItm}</span>
                    )
                })}</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    {props.ContentText.map((txtItm, index) => {
                        return (
                            <span className={`${txtItm.css !== "" ? txtItm.css : ""}`} key={index}>{txtItm.txt}<br /></span>
                        )
                    })}
                </DialogContentText>
                <DialogTitle className={"theBottomPop"}>
                    {props.BottomTitle?.map((txtItm, index) => {
                        return (
                            <span key={index} className={"bold"}>{txtItm}</span>
                        )
                    })}
                </DialogTitle>
                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
            </DialogContent>
            <DialogActions >

                <button type="button" className={`centerMe btn btn-success btnPopRegular pointing ${props.btn1Css ? props.btn1Css : ""}`} onClick={() => { props.okClick(); handleClose(); }}>
                    {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}</button>
            </DialogActions>
            <DialogActions >
                {props.btnClick2 != null ?
                    <>
                        <span>&nbsp;&nbsp;</span>
                        <button type="button" className={`centerMe btn btn-success btnPopRegular btnPopRegularSec pointing ${props.btn2Css ? props.btn2Css : ""}`} onClick={() => { props.btnClick2(); handleClose(); }}>
                            {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</button>
                    </>
                    : ""}
            </DialogActions>
        </Dialog >

    );
}

export default PopUpMsg;