import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import { MasterContext, OrderContext } from '../../ContextApi/context';
import OrderFunctions from '../../OrderSettings/OrderFunctions';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';



const Payment_Error = (props) => {
    const [master] = useContext(MasterContext);
    const [order, setOrder] = useContext(OrderContext);

    useEffect(() => {

    }, [])

    return (
        <PopUpMsg Title={["משהו השתבש!"]}
        nosnippet={true}
            ContentText={[{ txt: "משהו השתבש בחיוב", css: "" },
            { txt: "והחיוב לא בוצע", css: "" },
            { txt: "אנא נסו שוב או חזרו מאוחר יותר", css: "" }
            ]}
            inLabel={""}
            BottomTitle={[""]}
            BtnTxt={"חזרה"}
            topImg={`${ConnUrls.servMediasUrl}${master.orderUnApprovImg}`}
            cssStyl={"approveMsgPopup UnapproveMsgPopup"}
            okClick={() => { props.okClick() }}
            errorMsg={""} />
    )
}




export default Payment_Error