// import { Route, Switch } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  CurrentPContextProvider,
  MasterContextProvider,
  MessagesObjCssContextProvider,
  MobiOrNotContextProvider,
  OrdStageContextProvider,
  OrderContextProvider,
  UserContextProvider
} from './ContextApi/context';
import MasterHead from "./Master//MasterHeads";
import Master from './Master/Master';
import Sitemap from './web_pages/SiteMap/SiteMap';
function App() {
  return (
    <MobiOrNotContextProvider>
      <MasterContextProvider>
        <UserContextProvider>
          <OrderContextProvider>
            <MessagesObjCssContextProvider>
              <CurrentPContextProvider>

                <OrdStageContextProvider>
                  <Router>
                    <Switch>
                      {/* Route for /sitemap */}
                      <Route path="/sitemap" component={Sitemap} />
                      
                      {/* Default Route */}
                      <Route path="/">
                        <div className="webMain">
                          <MasterHead />
                          <Master />
                        </div>
                      </Route>
                    </Switch>
                  </Router>

                </OrdStageContextProvider>

              </CurrentPContextProvider>
            </MessagesObjCssContextProvider>
          </OrderContextProvider>
        </UserContextProvider>
      </MasterContextProvider >
    </MobiOrNotContextProvider>
  );
}

export default App;
