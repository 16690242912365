import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const orderMyTable = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "ordTbl/orderTableByClient", obj);
    return (dFromF.data);
}

const getTableOrderBySearch = async (obj) => {
    
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/bySearch", { params: obj });
    return (dFromF.data);
}


const getTableOrder = async (obj) => {
    
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/getTableOrder", { params: obj });
    return (dFromF.data);
}

const getTableSpecials = async (obj) => {
    
    let dFromF = await axios.get(ConnUrls.ApiUrl + "buissTblsSet/getTableSpecials", { params: obj });
    return (dFromF.data);
}

export default {
    orderMyTable,
    getTableOrderBySearch,
    getTableOrder,
    getTableSpecials
};