import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUpOneLine = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    useEffect(() => {

    }, [])

    const handleClose = () => {
        // props.cancelClick();
    };

    return (
        <Dialog open={open} className={`textSizeDiag popDiagBlock ${props.cssStyl || ""}`}>
            <div className="headAndBtnWrap spaceButtomBig">
                {/* <div className="backS">
                    <Close className="pointing" onClick={() => handleClose()} />
                </div> */}
                <div className="bold veryBig spacePadBig">
                    {props.topTitle ? props.topTitle : ""}
                </div>
                <div className="lineDotted"></div>
            </div>
            {props.topImg && <div className="imgblok" style={{ backgroundImage: `url(${props.topImg})` }}></div>}
            <div className="firstMastSec">
                <div className="insideFrame">
                    <DialogTitle className="regularFont noPadding normalLine">
                        {props.Title.map((txtItm, index) => (
                            <span key={index} className="big arrowing">{txtItm}  <br /></span>
                        ))}
                    </DialogTitle>
                    <DialogActions>
                        <div className="fullRow">
                            <div className="fullRow">
                                <div className="connectBtn conClear bold big pointing" onClick={() => props.btn1Clck()}>{props.btn1Txt}</div>
                            </div>
                            <br />
                            <div className="fullRow">
                                <div className="connectBtn conClear2 bold big pointing" onClick={() => props.btn2Clck()}>{props.btn2Txt}</div>
                            </div>
                        </div>
                    </DialogActions>
                </div>
            </div>
        </Dialog>

    );
}

export default PopUpOneLine;