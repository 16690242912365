import { useEffect, useState } from "react";
import MyDateTime from "../../../DTechComp/MyDateTime";
import { SentimentVerySatisfiedOutlined, 
    EmojiEmotionsOutlined,
    SentimentDissatisfied,
    SentimentVeryDissatisfied,
    SentimentVeryDissatisfiedOutlined} from '@mui/icons-material';

const BuisRating = (props) => {

    const [value] = useState(props.rating);
    const [icoSel, setIcoSel] = useState();
   
    useEffect(() => {
if(value>=8)
{
    setIcoSel(<SentimentVerySatisfiedOutlined/>);
}
else if(value>=6)
{
    setIcoSel(<EmojiEmotionsOutlined/>);
}
else if(value>=4)
{
    setIcoSel(<SentimentDissatisfied/>);
}
else if(value>=2)
{
    setIcoSel(<SentimentVeryDissatisfied/>);
}
else 
{
    setIcoSel(<SentimentVeryDissatisfiedOutlined/>);
}

    }, [])


    return (
    <div className="rating">

        {/* <div>{icoSel}</div><span>{value} (דרג)</span> */}

    </div>
);
}

export default BuisRating;
