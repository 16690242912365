import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const orderMyFood = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "order/nOrder", obj);
    return (dFromF.data);
}

const tempoOrder = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "order/tempoOrder", obj);
    return (dFromF.data);
}

const getOrder = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "order/" + id);
    return (dFromF.data);
}

const getDuplictedOrder = async (id, token) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "order/getDuplictedOrder/" + id,  { params: { token } });
    return (dFromF.data);
}


const getCounter = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "order/counter", { CounterName: "OrdersCounter" });
    return (dFromF.data);
}



const updateStatus = async (obj) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/usrupstat", obj);
    return (dFromF.data);
}





export default {
    // updateOrderPayParms,
    getDuplictedOrder,
    orderMyFood,
    getOrder,
    getCounter,
    updateStatus,
    tempoOrder
};



