import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { format, isBefore, isToday, isSameDay, subDays } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import heLocale from 'date-fns/locale/he';
import 'react-day-picker/dist/style.css';

const TimesListSelections = (props) => {
  const [hoursList] = useState(props.hoursList);
  const [Selected, setSelected] = useState(props.timeSelected);

  const [rowsList, setRowsList] = useState([])


  useEffect(() => {
    setLstH();
  }, [Selected]);

  const setLstH = () => {
    const rows = [];
    const maxItemsPerRow = 4;

    for (let i = 0; i < hoursList.length; i += maxItemsPerRow) {
      const rowItems = hoursList.slice(i, i + maxItemsPerRow);

      const row = (
        <div className="fullRow overflowHid" key={i / maxItemsPerRow}>
          {rowItems.map((itm, indx) => (
            <div key={indx}
              className={`reservationhourSelItm pointing ${Selected === itm && "selectedHou"}`}
              onClick={() => handleDateChange(itm)}
            >
              {itm}
            </div>
          ))}
        </div>
      );

      rows.push(row);
    }
    setRowsList(rows)
  }


  const handleDateChange = (val) => {
    setSelected(val);
    props.settingFunc(val);
  };

  return (
    <div className={'myTimesListSelection'}>
        <label style={{color:"black"}}>בחרו שעה</label>
      {
        rowsList.map((itm, indx) => {
          return (
            itm
          )
        }
        )
      }
    </div>
  );
};

export default TimesListSelections;

