import React, { useState, useEffect } from 'react';
import { format, isToday } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import heLocale from 'date-fns/locale/he';
import 'react-day-picker/dist/style.css';
import MyDateTime from '../../../DTechComp/MyDateTime';

const ApprovedOrdr = (props) => {

  const [tblOrdrItem, setTblOrdrItem] = useState(props.tblOrdrItem);
  const [remarks, setRemarks] = useState("");
  const [specials, setSpecials] = useState("");

  useEffect(() => {

    let all = tblOrdrItem.tabelsLocationsReq.concat(tblOrdrItem.specialsListReq);

    setSpecials(all.join(", "))
  }, []);

  const cancelClick = () => {
    props.closeMeFuc();
  }

  const okClick = () => {
    let tempo = { ...tblOrdrItem };
    tempo.remarks = remarks;
    props.okClick(tempo);

  }

  return (

    <div className={'appTblOrdPop onTopOfAll'}>
      <h3 className={"centerText"}>השולחן שלך תיכף מוכן.</h3>
      <h4 className={"noMargin "}>פרטי המזמין:</h4>
      <div className={"rowTb"}>
        <div className={"col onRight"}>שם מלא: </div>
        <div className={"col onRight bold"}>{tblOrdrItem.clientName}</div>
      </div>
      <div className={"rowTb"}>
        <div className={"col onRight"}>טלפון: </div>
        <div className={"col onRight bold"}>{tblOrdrItem.clientPhone}</div>
      </div>
      <h4 className={"noMargin"}>פרטי ההזמנה:</h4>
      <div className={"rowTb"}>
        <div className={"col onRight"}>שם המסעדה: </div>
        <div className={"col onRight bold"}>{tblOrdrItem.buissName}
        </div>
      </div>

      <div className={"rowTb"}>
        <div className={"col onRight"}>מספר סועדים: </div>
        <div className={"col onRight bold"}>{tblOrdrItem.dinners}</div>
      </div>
      <div className={"rowTb"}>
        <div className={"col onRight"}>לתאריך: </div>
        <div className={"col onRight bold"}>{format(tblOrdrItem.dateToCome, 'dd.MM.yyyy')}</div>
      </div>
      <div className={"rowTb"}>
        <div className={"col onRight"}>לשעה: </div>
        <div className={"col onRight bold"}>{tblOrdrItem.timeToCome}</div>
      </div>
      <div className={"rowTb"}>
        <div className={"col onRight"}>העדפות שולחן: </div>
        <div className={"col onRight bold"}>{specials}</div>
      </div>
      <div className={"rowTb"}>
        <textarea className={"remarksTxt noMargin"}
          placeholder={"יש משהו שהייתם רוצים להוסיף? זה המקום"}
          onChange={(e) => setRemarks(e.target.value)}></textarea>
      </div>

      <br />
      <div onClick={() => okClick()} className="resrvationOrdMeBtn pointing">
        הזמן
      </div>
    </div>

  );
};

export default ApprovedOrdr;
